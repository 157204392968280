import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { colors } from '@theme/colors';

import { AutoColumn } from '@components/layouts/Column';
import { NewPosition } from '@components/Deposit/NewDeposit';
import { PositionTable } from '@components/Deposit/DepositTable';
import { OffRamperIntentTable } from '@components/Deposit/OffRamperIntentTable';
import { DEPOSIT_REFETCH_INTERVAL } from '@helpers/constants';

import useDeposits from '@hooks/contexts/useDeposits';

export const Deposit: React.FC = () => {
  
  Deposit.displayName = 'Deposit';

  /*
   * Contexts
   */

  const {
    intentViews,
    refetchDepositViews,
    shouldFetchDepositViews,
    shouldFetchIntentViews,
    refetchIntentViews,
  } = useDeposits();

  /*
   * State
   */

  const [isAddPosition, setIsAddPosition] = useState<boolean>(false);

  /*
   * Hooks
   */

  useEffect(() => {
    if (shouldFetchDepositViews) {
      const intervalId = setInterval(() => {
        refetchDepositViews?.();
      }, DEPOSIT_REFETCH_INTERVAL);
  
      return () => clearInterval(intervalId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchDepositViews]);

  useEffect(() => {
    if (shouldFetchIntentViews) {
      const intervalId = setInterval(() => {
        refetchIntentViews?.();
      }, DEPOSIT_REFETCH_INTERVAL);
  
      return () => clearInterval(intervalId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchIntentViews]);

  /*
   * Handlers
   */

  const handleUpdateClick = () => {
    setIsAddPosition(true);
  }

  const handleBackClickOnNewDeposit = () => {
    setIsAddPosition(false);
  }

  /*
   * Component
   */

  function renderContent() {
    if (isAddPosition) {
      return (
        <NewPositionContainer>
          <NewPosition
            handleBackClick={handleBackClickOnNewDeposit}
          />
        </NewPositionContainer>
      )
    }
  
    return (
      <DepositAndIntentContainer>
        <PositionTable
          handleNewPositionClick={handleUpdateClick}
        />

        {intentViews && intentViews.length > 0 ? (
          <>
            <VerticalDivider />
            <OffRamperIntentTable/>
          </>
        ) : null}
      </DepositAndIntentContainer>
    );
  }

  return (
    <Wrapper>
      <Content>
        {renderContent()}
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled(AutoColumn)`
  max-width: 920px;
  width: 100%;
`;

const DepositAndIntentContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;

  @media (max-width: 600px) {
    max-width: 98%;
    margin: 0 auto;
  }
`;

const Content = styled.main`
  gap: 1rem;
`;

const NewPositionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    max-width: 98%;
    margin: 0 auto;
  }
`;

const VerticalDivider = styled.div`
  height: 24px;
  border-left: 1px solid ${colors.defaultBorderColor};
  margin: 0 auto;
`;
