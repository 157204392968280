import { createContext } from 'react'
import { BigNumber } from 'ethers'

interface BalancesValues {
  shouldFetchEthBalance: boolean | null
  usdcBalance: BigNumber | null
  refetchUsdcBalance: (() => void) | null
  shouldFetchUsdcBalance: boolean | null
  usdcApprovalToEscrow: BigNumber | null
  refetchUsdcApprovalToEscrow: (() => void) | null

  ethBalance: BigNumber | null
  refetchEthBalance: (() => void) | null

  cbbtcBalance: BigNumber | null
  refetchCbbtcBalance: (() => void) | null
}

const defaultValues: BalancesValues = {
  ethBalance: null,
  refetchEthBalance: null,
  shouldFetchEthBalance: null,
  usdcBalance: null,
  refetchUsdcBalance: null,
  shouldFetchUsdcBalance: null,
  usdcApprovalToEscrow: null,
  refetchUsdcApprovalToEscrow: null,

  cbbtcBalance: null,
  refetchCbbtcBalance: null,
};

const BalancesContext = createContext<BalancesValues>(defaultValues)

export default BalancesContext
