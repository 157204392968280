import {} from 'axios';
import { axios } from '../utils/axios.js';
import {} from 'viem';
import prepareCallTransaction from '../utils/prepareCallTransaction.js';
import { isSimulateContractRequest, APIError } from '../utils/index.js';
import { getClient } from '../client.js';
import { getDeadAddress } from '../constants/address.js';
/**
 * Method to get the price
 * @param data - {@link GetPriceParameters}
 */
export async function getPrice(parameters) {
    const { destinationChainId, destinationCurrency, originChainId, originCurrency, tradeType, amount = '0', user, recipient, options, txs } = parameters;
    const client = getClient();
    if (!client.baseApiUrl || !client.baseApiUrl.length) {
        throw new ReferenceError('RelayClient missing api url configuration');
    }
    let preparedTransactions;
    if (txs && txs.length > 0) {
        preparedTransactions = txs.map((tx) => {
            if (isSimulateContractRequest(tx)) {
                return prepareCallTransaction(tx);
            }
            return tx;
        });
    }
    const originChain = client.chains.find((chain) => chain.id === originChainId);
    const deadAddress = getDeadAddress(originChain?.vmType, originChain?.id);
    const query = {
        user: user ?? deadAddress,
        destinationCurrency,
        destinationChainId,
        originCurrency,
        originChainId,
        amount,
        recipient: recipient ? recipient : user ?? deadAddress,
        tradeType,
        referrer: client.source || undefined,
        txs: preparedTransactions,
        ...options
    };
    const request = {
        url: `${client.baseApiUrl}/price`,
        method: 'post',
        data: query
    };
    const res = await axios.request(request);
    if (res.status !== 200) {
        throw new APIError(res?.data?.message, res.status, res.data);
    }
    return { ...res.data, request };
}
