import React, { useEffect, useState, ReactNode } from 'react';
import { useContractRead } from 'wagmi';
import { BigNumber } from 'ethers';
import {
  EscrowCurrency,
  EscrowDeposit,
  EscrowDepositView,
  EscrowIntent,
  EscrowIntentView,
  EscrowVerifierDataView,
} from '@helpers/types/escrow';
import { esl, ZERO_ADDRESS } from '@helpers/constants';
import {
  parseEscrowDepositView,
  parseEscrowIntentView,
} from '@helpers/parseEscrowState';
import useAccount from '@hooks/contexts/useAccount';
import useSmartContracts from '@hooks/contexts/useSmartContracts';

import DepositsContext from './DepositsContext';


interface ProvidersProps {
  children: ReactNode;
}

const DepositsProvider = ({ children }: ProvidersProps) => {
  /*
   * Contexts
   */

  const { isLoggedIn, loggedInEthereumAddress } = useAccount();
  const { escrowAddress, escrowAbi, usdcAddress } = useSmartContracts();

  /*
   * State
   */

  const [depositViews, setDepositViews] = useState<EscrowDepositView[] | null>(null);
  const [intentViews, setIntentViews] = useState<EscrowIntentView[] | null>(null);
  const [shouldFetchDepositViews, setShouldFetchDepositViews] = useState<boolean>(false);
  const [uniqueIntentHashes, setUniqueIntentHashes] = useState<string[]>([]);
  const [shouldFetchIntentViews, setShouldFetchIntentViews] = useState<boolean>(false);

  /*
   * Contract Reads
   */

  // function getAccountDeposits(address _account)
  const {
    data: depositViewsRaw,
    refetch: refetchDepositViews,
  } = useContractRead({
    address: escrowAddress,
    abi: escrowAbi,
    functionName: 'getAccountDeposits',
    args: [
      loggedInEthereumAddress
    ],
    enabled: shouldFetchDepositViews,
  })
      
  // getIntents(bytes32[] calldata _intentHashes)
  const {
    data: intentViewsRaw,
    refetch: refetchIntentViews,
  } = useContractRead({
    address: escrowAddress,
    abi: escrowAbi,
    functionName: 'getIntents',
    args: [
      uniqueIntentHashes
    ],
    enabled: shouldFetchIntentViews,
  });

  /*
   * Hooks
   */

  useEffect(() => {
    esl && console.log('escrow_useDepositsProvider_shouldFetchDepositViews_1');
    esl && console.log('checking isLoggedIn: ', isLoggedIn);
    esl && console.log('checking loggedInEthereumAddress: ', loggedInEthereumAddress);
    esl && console.log('checking escrowAddress: ', escrowAddress);

    if (isLoggedIn && loggedInEthereumAddress && escrowAddress) {
      esl && console.log('escrow_useDepositsProvider_shouldFetchDepositViews_2');

      setShouldFetchDepositViews(true);
    } else {
      esl && console.log('escrow_useDepositsProvider_shouldFetchDepositViews_3');

      setShouldFetchDepositViews(false);

      setDepositViews(null);
      setIntentViews(null);
    }
  }, [isLoggedIn, loggedInEthereumAddress, escrowAddress]);

  useEffect(() => {
    esl && console.log('escrow_useDepositsProvider_shouldFetchIntentViews_1');
    esl && console.log('checking uniqueIntentHashes: ', uniqueIntentHashes);

    if (uniqueIntentHashes.length > 0) {
      esl && console.log('escrow_useDepositsProvider_shouldFetchIntentViews_2');

      setShouldFetchIntentViews(true);
    } else {
      esl && console.log('escrow_useDepositsProvider_shouldFetchIntentViews_3');

      setShouldFetchIntentViews(false);

      setIntentViews(null);
    }
  }, [uniqueIntentHashes]);

  useEffect(() => {
    esl && console.log('escrow_useDepositsProvider_depositViewssRaw_1');
    esl && console.log('checking depositViewsRaw: ', depositViewsRaw);

    if (depositViewsRaw) {
      esl && console.log('escrow_useDepositsProvider_depositViewssRaw_2');

      const depositsArrayRaw = depositViewsRaw as any[];

      const sanitizedDeposits: EscrowDepositView[] = [];
      const depositIntentHashes: string[][] = [];

      for (let i = depositsArrayRaw.length - 1; i >= 0; i--) {
        const escrowDepositView = parseEscrowDepositView(depositsArrayRaw[i]);

        // skip deposits that are not usdc
        if (
          escrowDepositView.deposit.depositor === ZERO_ADDRESS ||
          escrowDepositView.deposit.token !== usdcAddress
        ) {
          continue;
        }

        sanitizedDeposits.push(escrowDepositView);
        depositIntentHashes.push(escrowDepositView.deposit.intentHashes);
      }
          
      setDepositViews(sanitizedDeposits);
      
      const flattenedDepositIntentHashes = depositIntentHashes.flat();
      setUniqueIntentHashes(flattenedDepositIntentHashes);
    } else {
      esl && console.log('escrow_useDepositsProvider_depositViewssRaw_3');

      setDepositViews(null);
      setUniqueIntentHashes([]);
    }
  }, [depositViewsRaw]);

  useEffect(() => {
    esl && console.log('escrow_useDepositsProvider_intentViewsRaw_1');
    esl && console.log('checking intentViewsRaw: ', intentViewsRaw);

    if (intentViewsRaw && intentViewsRaw.length > 0) {
      esl && console.log('escrow_useDepositsProvider_intentViewsRaw_2');

      const depositIntentsArray = intentViewsRaw as any[];

      const sanitizedIntents: EscrowIntentView[] = [];
      for (let i = depositIntentsArray.length - 1; i >= 0; i--) {
        const escrowIntentView = parseEscrowIntentView(depositIntentsArray[i]);

        // skip deposits that are not usdc
        if (
          escrowIntentView.deposit.deposit.depositor === ZERO_ADDRESS ||
          escrowIntentView.deposit.deposit.token !== usdcAddress
        ) {
          continue;
        }

        sanitizedIntents.push(escrowIntentView);
      }

      setIntentViews(sanitizedIntents);
    } else {
      esl && console.log('escrow_useDepositsProvider_intentViewsRaw_3');
      
      setIntentViews([]);
    }
  }, [intentViewsRaw]);

  return (
    <DepositsContext.Provider
      value={{
        depositViews,
        intentViews,
        refetchDepositViews,
        shouldFetchDepositViews,
        refetchIntentViews,
        shouldFetchIntentViews,
      }}
    >
      {children}
    </DepositsContext.Provider>
  );
};

export default DepositsProvider;
