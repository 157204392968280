import { createContext } from 'react';

import { PaymentPlatformType } from '@helpers/types';

interface BackendValues {
  rawPayeeDetails: string;
  depositorTgUsername: string;
  initiatePayeeDetailsFetch: (hashedOnchainId: string, platform: PaymentPlatformType) => void;
  isFetchingRawPayeeDetails: boolean;
};

const defaultValues: BackendValues = {
  rawPayeeDetails: '',
  depositorTgUsername: '',
  initiatePayeeDetailsFetch: () => { },
  isFetchingRawPayeeDetails: false,
};

const BackendContext = createContext<BackendValues>(defaultValues);

export default BackendContext;
