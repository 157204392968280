type Contracts = {
  [network: string]: {
    [contract: string]: string;
  };
};

export const contractAddresses: Contracts = {
  'base_production': {
    // external contracts
    'usdc': '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
    'usdt': '',
    'relayBridge': '',

    // escrow + verifiers
    'escrow': '0xCA38607D85E8F6294Dc10728669605E6664C2D70',
    'venmoReclaimVerifier': '0x9a733B55a875D0DB4915c6B36350b24F8AB99dF5',
    'revolutReclaimVerifier': '0xAA5A1B62B01781E789C900d616300717CD9A41aB',
    'cashappReclaimVerifier': '0x76D33A33068D86016B806dF02376dDBb23Dd3703',
    'wiseReclaimVerifier': '0xFF0149799631D7A5bdE2e7eA9b306c42b3d9a9ca',

    // offchain services
    'gatingService': '0x396D31055Db28C0C6f36e8b36f18FE7227248a97',
    'zkp2pWitnessSigner': '0x0636c417755E3ae25C6c166D181c0607F4C572A3',
    'reclaimWitnessSigner': '0x244897572368eadf65bfbc5aec98d8e5443a9072',
  },
  'base_staging': {
    // external contracts
    'usdc': '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
    'usdt': '',
    'relayBridge': '',

    // escrow + verifiers
    'escrow': '0xC8cd114C6274Ef1066840337E7678BC9731BEa68',
    'venmoReclaimVerifier': '0xCE6454f272127ba69e8C8128B92F2388Ca343257',
    'revolutReclaimVerifier': '0xb941e69B6C1A23A88cf9DA7D243bAE1D2Cb8eb6b',
    'cashappReclaimVerifier': '0xdDB9d452180398F456Fe89A43Df9C65B19756CEa',
    'wiseReclaimVerifier': '0x79F35E2f65ff917BE35686d34932C8Ef5a30631f',

    // offchain services
    'gatingService': '0x396D31055Db28C0C6f36e8b36f18FE7227248a97',
    'zkp2pWitnessSigner': '0x0636c417755E3ae25C6c166D181c0607F4C572A3',
    'reclaimWitnessSigner': '0x244897572368eadf65bfbc5aec98d8e5443a9072',
  },
  'sepolia_staging': {
    // external contracts
    'usdc': '0x7a1C5Ee7461ab2790Aa9A93017fFCf789B785D12',
    'usdt': '0x0c73FC78793F6E1B0F65c46e9053b82773D62178',
    'relayBridge': '',

    // escrow + verifiers
    'escrow': '0xFF0149799631D7A5bdE2e7eA9b306c42b3d9a9ca',
    'venmoReclaimVerifier': '0x3Fa6C4135696fBD99F7D55B552B860f5df770710',
    'revolutReclaimVerifier': '0x79820f039942501F412910C083aDA6dCc419B67c',
    'cashappReclaimVerifier': '0x3997dd7B691E11D45E8898601F5bc7B016b0d38B',
    'wiseReclaimVerifier': '0x7cF01c990F5E93Eb4eaaC2146dFeC525a9C87878',

    // offchain services
    'gatingService': '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266', // Hardhat 0
    'zkp2pWitnessSigner': '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
    'reclaimWitnessSigner': '0x244897572368eadf65bfbc5aec98d8e5443a9072', // Reclaim's key
  },
  'localhardhat': {
    // external contracts
    'usdc': '0x5FbDB2315678afecb367f032d93F642f64180aa3',
    'usdt': '0x9A676e781A523b5d0C0e43731313A708CB607508',
    'relayBridge': '',

    // escrow + verifiers
    'escrow': '0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512',
    'venmoReclaimVerifier': '0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9',
    'revolutReclaimVerifier': '0xa513E6E4b8f2a923D98304ec87F64353C4D5C853',
    'cashappReclaimVerifier': '0x610178dA211FEF7D417bC0e6FeD39F05609AD788',
    'wiseReclaimVerifier': '0x0DCd1Bf9A1b36cE34237eEaFef220932846BCD82',

    // offchain services
    'gatingService': '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266', // Hardhat 0
    'zkp2pWitnessSigner': '0x0636c417755E3ae25C6c166D181c0607F4C572A3',
    'reclaimWitnessSigner': '0x244897572368eadf65bfbc5aec98d8e5443a9072', // Reclaim's key
  },
};

export const blockExplorerUrls: { [network: string]: string } = {
  'hardhat': 'https://etherscan.io',
  'sepolia': 'https://sepolia.etherscan.io/',
  'base': 'https://basescan.org',
  'solana': 'https://solscan.io',
  'eth': 'https://etherscan.io',
  'polygon': 'https://polygonscan.com',
};

export const chainIds: { [network: string]: string } = {
  'hardhat': '31337',
  'sepolia': '11155111',
  'base': '8453'
};