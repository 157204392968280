import { PaymentPlatformType } from "@helpers/types";
import { useState, useCallback } from "react";
import { usePrivy } from "@privy-io/react-auth";
import { GetPayeeDetailsResponse } from "@helpers/types";

const API_URL = process.env.CURATOR_API_URL || "";

export default function usePayeeDetails() {
  /**
   * Context
   */
  const { getAccessToken } = usePrivy();

  /**
   * State
   */
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<GetPayeeDetailsResponse | null>(null);

  /**
   * Fetch
   */
  const fetchPayeeDetails = useCallback(async (hashedOnchainId: string, platform: PaymentPlatformType) => {
    setIsLoading(true);
    setError(null);

    try {
      const accessToken = await getAccessToken();
      const response = await fetch(`${API_URL}/deposits/${platform}/${hashedOnchainId}`, {
        method: "GET",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`
        }
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Failed to fetch payee details:", errorText);
        throw new Error(`Failed to fetch: ${response.statusText}`);
      }

      const responseData: GetPayeeDetailsResponse = await response.json();

      setData(responseData);
    } catch (err: any) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    data,
    isLoading,
    error,
    fetchPayeeDetails
  };
}
