import { useState, useCallback } from "react";
import { usePrivy } from "@privy-io/react-auth";
import { IntentSignalRequest, SignalIntentResponse } from "@helpers/types";

const API_URL = process.env.CURATOR_API_URL || "";

export default function useSignIntent() {

  /**
   * Context
   */
  const { getAccessToken } = usePrivy();

  /**
   * State
   */
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<SignalIntentResponse | null>(null);

  /**
   * Fetch
   */
  const fetchSignedIntent = useCallback(async (requestData: IntentSignalRequest) => {
    setIsLoading(true);
    setError(null);
    
    try {
      const accessToken = await getAccessToken();
      const response = await fetch(`${API_URL}/intents`, {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Failed to fetch signalIntent:", errorText);
        throw new Error(`Failed to fetch: ${response.statusText}`);
      }

      const jsonResponse = await response.json() as SignalIntentResponse;

      setData(jsonResponse);
    } catch (err: any) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    data,
    isLoading,
    error,
    fetchSignedIntent
  };
}