import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';

import useSmartContracts from '@hooks/contexts/useSmartContracts';


export default function useFulfillIntentTransaction(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */

  const { escrowAddress, escrowAbi } = useSmartContracts();

  /*
   * State
   */

  const [paymentProofInput, setPaymentProofInput] = useState<string | null>(null);
  const [intentHashInput, setIntentHashInput] = useState<string | null>(null);

  const [shouldConfigureFulfillIntentWrite, setShouldConfigureFulfillIntentWrite] = useState<boolean>(false);

  /*
   * Contract Writes
   */

  const {
    config: writeFulfillIntentConfig,
    error: prepareFulfillIntentError,
    isError: isWriteFulfillIntentPrepareError,
  } = usePrepareContractWrite({
    address: escrowAddress,
    abi: escrowAbi,
    functionName: 'fulfillIntent',
    args: [
      paymentProofInput,
      intentHashInput,
    ],
    onError(error: any) {
      console.log('writeFulfillIntentConfig failed: ', error.message);

      setShouldConfigureFulfillIntentWrite(false);

      onError?.(error);
    },
    enabled: shouldConfigureFulfillIntentWrite,
    request: {
      maxPriorityFeePerGas: (existing: bigint) => existing * BigInt(2),
    },
  });

  const {
    data: submitFulfillIntentResult,
    status: signFulfillIntentTransactionStatus,
    writeAsync: writeFulfillIntentAsync,
  } = useContractWrite(writeFulfillIntentConfig);

  const {
    status: mineFulfillIntentTransactionStatus,
  } = useWaitForTransaction({
    hash: submitFulfillIntentResult ? submitFulfillIntentResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeFulfillIntentAsync successful: ', data);
      setShouldConfigureFulfillIntentWrite(false);
      onSuccess?.(data);
    },
  });

  return {
    writeFulfillIntentAsync,
    paymentProofInput,
    intentHashInput,
    setPaymentProofInput,
    setIntentHashInput,
    setShouldConfigureFulfillIntentWrite,
    prepareFulfillIntentError,
    isWriteFulfillIntentPrepareError,
    signFulfillIntentTransactionStatus,
    mineFulfillIntentTransactionStatus,
    transactionHash: submitFulfillIntentResult?.hash,
  };
};
