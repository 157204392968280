import React, { useReducer, useRef } from "react";
import styled from 'styled-components';
import { X, ChevronDown } from 'react-feather';
import Link from '@mui/material/Link';

import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { Overlay } from '@components/modals/Overlay';
import { PlatformRow } from '@components/modals/selectors/platform/PlatformRow';
import { paymentPlatforms, paymentPlatformInfo, PaymentPlatformType } from '@helpers/types';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import { ZKP2P_TG_LINK } from "@helpers/docUrls";
import { Z_INDEX } from '@theme/zIndex';


interface PlatformSelectorProps {
  paymentPlatform: PaymentPlatformType | null;
  setPaymentPlatform: (platform: PaymentPlatformType) => void;
  allPlatforms?: PaymentPlatformType[];
}

export const PlatformSelector: React.FC<PlatformSelectorProps> = ({
  paymentPlatform,
  setPaymentPlatform,
  allPlatforms
}) => {
  const [isOpen, toggleOpen] = useReducer((s) => !s, false)

  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, isOpen ? toggleOpen : undefined)

  /*
   * Handlers
   */

  const handleOverlayClick = () => {
    toggleOpen();
  };

  const handleSelectPlatform = (platform: PaymentPlatformType) => {
    if (setPaymentPlatform) {
      setPaymentPlatform(platform);

      toggleOpen();
    }
  };

  /*
   * Component
   */

  return (
    <Wrapper ref={ref}>
      <PlatformNameAndChevronContainer onClick={toggleOpen}>
        
      {paymentPlatform ? 
        <PlatformLabel>
          {paymentPlatformInfo[paymentPlatform as PaymentPlatformType].platformName}
        </PlatformLabel>
        :
        <AllPlatformLabel>
          All platforms
        </AllPlatformLabel>
      }
        
        <StyledChevronDown/>
      </PlatformNameAndChevronContainer>
    

      {isOpen && (
        <ModalAndOverlayContainer>
          <Overlay onClick={handleOverlayClick}/>

          <ModalContainer>
            <TableHeader>
              <ThemedText.SubHeader style={{ textAlign: 'left' }}>
                Select a platform
              </ThemedText.SubHeader>

              <button
                onClick={handleOverlayClick}
                style={{ background: 'none', border: 'none', cursor: 'pointer' }}
              >
                <StyledX/>
              </button>
            </TableHeader>

            <HorizontalDivider/>

            <Table>
              {allPlatforms?.map((platform, index) => (
                <PlatformRow
                  key={index}
                  platformName={paymentPlatformInfo[platform].platformName || ''}
                  platformCurrencies={paymentPlatformInfo[platform].platformCurrencies || []}
                  isSelected={paymentPlatform === platform}
                  onRowClick={() => handleSelectPlatform(platform)}
                />
              ))}
            </Table>

            <HorizontalDivider/>

            <TableFooter>
              Let us know which platforms you are interested in seeing ZKP2P add support
              for. <Link href={ ZKP2P_TG_LINK } target="_blank">
                Give feedback ↗
              </Link>
            </TableFooter>
          </ModalContainer>
        </ModalAndOverlayContainer>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const PlatformNameAndChevronContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 24px;
  background: ${colors.selectorColor};
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 6px 8px 6px 14px;
  gap: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.selectorHover};
    border: 1px solid ${colors.selectorHoverBorder};
  }
`;

const PlatformLabel = styled.div`
  color: #FFF;
  font-weight: 700;
  letter-spacing: 0.02em;
  padding: 1px 5px 0px 5px;
  min-width: 55px;
`;

const AllPlatformLabel = styled.div`
  color: #FFF;
  font-weight: 700;
  letter-spacing: 0.02em;
  padding: 1px 5px 0px 5px;

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;


const StyledChevronDown = styled(ChevronDown)`
  width: 20px;
  height: 20px;
  color: #FFF;
`;

const ModalAndOverlayContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  align-items: flex-start;
  top: 0;
  left: 0;
  z-index: ${Z_INDEX.overlay};
`;

const ModalContainer = styled.div`
  width: 80vw;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: ${colors.container};
  color: #FFF;
  align-items: center;
  z-index: 20;
  
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TableHeader = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px 16px 20px;
`;

const HorizontalDivider = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.defaultBorderColor};
`;

const StyledX = styled(X)`
  color: #FFF;
`;

const Table = styled.div`
  width: 100%;
  color: #616161;
  overflow-y: auto;
  scrollbar-width: thin;
  height: 35vh;
`;

const TableFooter = styled.div`
  padding: 20px;
  font-size: 14px;
  text-align: left;
  line-height: 1.5;
  color: ${colors.grayText};
`;