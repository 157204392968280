import { createContext } from 'react';

import { ExtensionNotaryProofRequest, ExtensionRequestMetadata, PaymentPlatformType } from '@helpers/types';


interface ExtensionProxyProofsValues {
  isSidebarInstalled: boolean;
  isSidebarNeedsUpdate: boolean;
  sideBarVersion: string | null;
  refetchExtensionVersion: () => void;

  openNewTab: (url: string) => void;

  venmoMetadata: ExtensionRequestMetadata[] | null;
  venmoMetadataExpiresAt: number | null;

  revolutMetadata: ExtensionRequestMetadata[] | null;
  revolutMetadataExpiresAt: number | null;

  cashappMetadata: ExtensionRequestMetadata[] | null;
  cashappMetadataExpiresAt: number | null;

  wiseMetadata: ExtensionRequestMetadata[] | null;
  wiseMetadataExpiresAt: number | null;

  paymentProof: ExtensionNotaryProofRequest | null;
  generatePaymentProof: (platform: PaymentPlatformType, intentHash: string, originalIndex: number) => void;
  fetchPaymentProof: (platform: PaymentPlatformType) => void;
  resetProofState: () => void;
};

const defaultValues: ExtensionProxyProofsValues = {
  isSidebarInstalled: false,
  isSidebarNeedsUpdate: false,
  sideBarVersion: null,
  refetchExtensionVersion: () => { },

  openNewTab: (_url: string) => { },

  venmoMetadata: null,
  venmoMetadataExpiresAt: null,

  revolutMetadata: null,
  revolutMetadataExpiresAt: null,

  cashappMetadata: null,
  cashappMetadataExpiresAt: null,

  wiseMetadata: null,
  wiseMetadataExpiresAt: null,

  paymentProof: null,
  generatePaymentProof: (_platform: PaymentPlatformType, _intentHash: string, _originalIndex: number) => { },
  fetchPaymentProof: (_platform: PaymentPlatformType) => { },
  resetProofState: () => { },
};

const ExtensionProxyProofsContext = createContext<ExtensionProxyProofsValues>(defaultValues);

export default ExtensionProxyProofsContext;
