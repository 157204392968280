import React, { useEffect, useState, ReactNode } from 'react';
import { useContractRead } from 'wagmi';

import { EscrowCurrency, EscrowDepositVerifierData, EscrowIntentView, EscrowVerifierDataView } from '@helpers/types/escrow';
import { esl, ZERO, ZERO_ADDRESS } from '@helpers/constants';
import useAccount from '@hooks/contexts/useAccount';
import useSmartContracts from '@hooks/contexts/useSmartContracts';

import OnRamperIntentsContext from './OnRamperIntentsContext';
import { parseEscrowIntentView } from '@helpers/parseEscrowState';

interface ProvidersProps {
  children: ReactNode;
}

const OnRamperIntentsProvider = ({ children }: ProvidersProps) => {
  /*
   * Contexts
   */

  const { isLoggedIn, loggedInEthereumAddress } = useAccount();
  const { escrowAddress, escrowAbi } = useSmartContracts();

  /*
   * State
   */

  const [currentIntentView, setCurrentIntentView] = useState<EscrowIntentView | null>(null);
  const [shouldFetchIntentView, setShouldFetchIntentView] = useState<boolean>(false);
  const [currentIntentHash, setCurrentIntentHash] = useState<string | null>(null);

  /*
   * Contract Reads
   */

  //  function getAccountIntent(address _account) external view returns (IntentView memory intentView)
  const {
    data: intentViewRaw,
    refetch: refetchIntentView,
  } = useContractRead({
    address: escrowAddress,
    abi: escrowAbi,
    functionName: 'getAccountIntent',
    args: [
      loggedInEthereumAddress
    ],
    enabled: shouldFetchIntentView
  })

  /*
   * Hooks
   */

  useEffect(() => {
    esl && console.log('shouldFetchIntentView_1');
    esl && console.log('checking isLoggedIn: ', isLoggedIn);
    esl && console.log('checking loggedInEthereumAddress: ', loggedInEthereumAddress);

    if (isLoggedIn && loggedInEthereumAddress) {
      esl && console.log('shouldFetchIntentView_2');

      setShouldFetchIntentView(true);
    } else {
      esl && console.log('shouldFetchIntentView_3');

      setShouldFetchIntentView(false);
      setCurrentIntentView(null)
      setCurrentIntentHash(null)
    }
  }, [isLoggedIn, loggedInEthereumAddress]);

  useEffect(() => {
    esl && console.log('intentRaw_1');
    esl && console.log('checking intentRaw: ', intentViewRaw);
  
    if (intentViewRaw) {
      esl && console.log('intentRaw_2');

      const intentViewData = intentViewRaw as any;
      const intentViewProcessed = parseEscrowIntentView(intentViewData);

      if (intentViewProcessed.intent.owner !== ZERO_ADDRESS) {
        esl && console.log('intentRaw_4');

        setCurrentIntentView(intentViewProcessed);
        setCurrentIntentHash(intentViewProcessed.intentHash);
      } else {
        esl && console.log('intentRaw_5');

        setCurrentIntentView(null);
        setCurrentIntentHash(null);
      }

    } else {
      esl && console.log('intentRaw_3');

      setCurrentIntentView(null);
      setCurrentIntentHash(null);
    }
  }, [intentViewRaw]);

  return (
    <OnRamperIntentsContext.Provider
      value={{
        currentIntentView,
        refetchIntentView,
        shouldFetchIntentView,
        currentIntentHash
      }}
    >
      {children}
    </OnRamperIntentsContext.Provider>
  );
};

export default OnRamperIntentsProvider;
