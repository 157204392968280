import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import useSmartContracts from '@hooks/contexts/useSmartContracts';

export default function useCancelIntentTransaction(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */
  const { escrowAddress, escrowAbi } = useSmartContracts();

  /*
   * State
   */
  const [intentHashInput, setIntentHashInput] = useState<string | null>(null);
  const [shouldConfigureCancelIntentWrite, setShouldConfigureCancelIntentWrite] = useState<boolean>(false);

  /*
   * Contract Writes
   */
  const { config: writeCancelIntentConfig } = usePrepareContractWrite({
    address: escrowAddress,
    abi: escrowAbi,
    functionName: 'cancelIntent',
    args: [
      intentHashInput,
    ],
    onError(error: any) {
      console.log('writeCancelIntentConfig failed: ', error.message);
      setShouldConfigureCancelIntentWrite(false);
      onError?.(error);
    },
    enabled: shouldConfigureCancelIntentWrite,
    request: {
      maxPriorityFeePerGas: (existing: bigint) => existing * BigInt(2),
    },
  });

  const {
    data: submitCancelIntentResult,
    status: signCancelIntentTransactionStatus,
    writeAsync: writeCancelIntentAsync,
  } = useContractWrite(writeCancelIntentConfig);

  const {
    status: mineCancelIntentTransactionStatus,
  } = useWaitForTransaction({
    hash: submitCancelIntentResult ? submitCancelIntentResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeCancelIntentAsync successful: ', data);
      setShouldConfigureCancelIntentWrite(false);
      onSuccess?.(data);
    },
  });

  return {
    writeCancelIntentAsync,
    intentHashInput,
    setIntentHashInput,
    shouldConfigureCancelIntentWrite,
    setShouldConfigureCancelIntentWrite,
    signCancelIntentTransactionStatus,
    mineCancelIntentTransactionStatus,
    transactionHash: submitCancelIntentResult?.hash,
  };
}

