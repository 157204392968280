export class SolverStatusTimeoutError extends Error {
    constructor(txHash, attemptCount) {
        super(`Failed to receive a successful response for solver status check with hash '${txHash}' after ${attemptCount} attempt(s).`);
        Object.defineProperty(this, "txHash", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "blockExplorerBaseUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.name = 'SolverStatusTimeoutError';
        this.txHash = txHash;
    }
}
