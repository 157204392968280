import { createContext } from 'react';

import { TokenType } from '@helpers/types';

interface SendSettingsValues {
  sendToken?: TokenType;
  setSendToken?: React.Dispatch<React.SetStateAction<TokenType>>;
};

const SendSettingsContext = createContext<SendSettingsValues>({});

export default SendSettingsContext;
