import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import styled from 'styled-components';
import Link from '@mui/material/Link';
import { colors } from '@theme/colors';
import { ethers, BigNumber } from 'ethers';

import { ThemedText } from '@theme/text';
import { Input } from '@components/common/Input';
import { Button } from "@components/common/Button";
import { RowBetween } from '@components/layouts/Row';
import { NumberedStep } from "@components/common/NumberedStep";
import { 
  CurrencyType, 
  LoginStatus, 
  NewDepositTransactionStatus,
  NewDepositTransactionStatusType,
  EscrowRange,
  EscrowDepositVerifierData,
  EscrowCurrency,
  PaymentPlatform,
  PaymentPlatformType,
  paymentPlatformInfo,
  paymentPlatforms,
  currencyInfo,
  Token,
  TokenType,
  tokenInfo
} from '@helpers/types';
import { 
  etherUnits,
  tokenUnits,
  tokenUnitsToReadable
} from '@helpers/units';
import { ZERO } from '@helpers/constants';
import { MODALS } from '@helpers/types';
import { NewPaymentPlatform } from '@components/Deposit/NewDeposit/PaymentPlatform';
import { InputWithTokenSelector } from '@components/modals/selectors/token';
import { PostDepositDetailsRequest } from '@helpers/types';
import { AccessoryButton } from '@components/common/AccessoryButton';
import Spinner from '@components/common/Spinner';

import useAccount from '@hooks/contexts/useAccount';
import useBalances from '@hooks/contexts/useBalance';
import useDeposits from '@hooks/contexts/useDeposits';
import useSmartContracts from '@hooks/contexts/useSmartContracts';
import useModal from '@hooks/contexts/useModal';

import useCreateDeposit from '@hooks/transactions/useCreateDeposit';
import useTokenApprove from '@hooks/transactions/useTokenApprove';
import usePostDepositDetails from '@hooks/backend/usePostDepositDetails';

import { esl } from '@helpers/constants';
import { commonStrings } from '@helpers/strings';
import useLocalStorage from '@hooks/useLocalStorage';
import useLiquidity from '@hooks/contexts/useLiquidity';
import { AdvancedSettings } from './AdvancedSettings';

interface NewPositionProps {
  handleBackClick: () => void;
}
 
export const NewPosition: React.FC<NewPositionProps> = ({
  handleBackClick
}) => {
  NewPosition.displayName = 'NewPosition';

  /*
   * Contexts
   */

  const { isLoggedIn, loginStatus } = useAccount();
  const { 
    usdcAddress,
    platformToVerifierAddress,
    gatingServiceAddress,
    witnessAddresses
  } = useSmartContracts();
  const { 
    usdcApprovalToEscrow, 
    usdcBalance, 
    refetchUsdcApprovalToEscrow, 
    refetchUsdcBalance,
  } = useBalances();
  const { refetchDepositViews: refetchLiquidityDepositViews } = useLiquidity();
  const { refetchDepositViews } = useDeposits();
  const { openModal } = useModal();

  /*
   * State
   */
  
  const [depositState, setDepositState] = useState<NewDepositTransactionStatusType>(NewDepositTransactionStatus.DEFAULT);
  
  const [depositToken, setDepositToken] = useState<TokenType>(Token.USDC);
  const [depositAmountValue, setDepositAmountValue] = useState<string>('');
  const [minAmountValue, setMinAmountValue] = useState<string>('0.1');
  const [maxAmountValue, setMaxAmountValue] = useState<string>('');

  const [platforms, setPlatforms] = useState<PaymentPlatformType[]>([paymentPlatforms[0]]);
  const [platformPayeeDetails, setPlatformPayeeDetails] = useState<Map<PaymentPlatformType, string>>(
    new Map([[paymentPlatforms[0], '']])
  );
  const [platformConversionRates, setPlatformConversionRates] = useState<Map<PaymentPlatformType, Map<CurrencyType, string>>>(
    new Map([[paymentPlatforms[0], new Map()]])
  );

  const [hashedPayeeDetails, setHashedPayeeDetails] = useState<Map<PaymentPlatformType, string>>(new Map());
  const [allPayeeDetailsPosted, setAllPayeeDetailsPosted] = useState<boolean>(false);

  const [storedPayeeDetails, setStoredPayeeDetails] = useLocalStorage<{[key: string]: string}>('STORED_PAYEE_DETAILS', {});

  const [telegramUsername, setTelegramUsername] = useState<string>('');

  /*
   * Backend writes
   */

  const { 
    postDepositDetails, 
    isLoading: postDepositDetailsIsLoading,
    error: postDepositDetailsError
  } = usePostDepositDetails();

  /*
   * Contract Writes
   */

  const onSuccessCreateDeposit = useCallback((data: any) => {
    console.log('onSuccessCreateDeposit: ', data);

    refetchDepositViews?.();

    refetchLiquidityDepositViews?.();

    refetchUsdcBalance?.();

    refetchUsdcApprovalToEscrow?.();

    // Empty fields
    // setDepositAmountValue('');
    // setMinAmountValue('');
    // setMaxAmountValue('');
    // setPlatforms([]);
    // setPlatformPayeeDetails(new Map());
    // setPlatformConversionRates(new Map());

    handleBackClick();

    setDepositState(NewDepositTransactionStatus.TRANSACTION_SUCCEEDED);
  }, [refetchDepositViews, refetchUsdcBalance]);

  const {
    writeCreateDepositAsync,
    setTokenInput: setDepositTokenInput,
    setAmountInput: setDepositAmountInput,
    setIntentAmountRangeInput,
    setVerifiersInput,
    setVerifierDataInput,
    setCurrenciesInput,
    shouldConfigureCreateDepositWrite,
    setShouldConfigureCreateDepositWrite,
    signCreateDepositTransactionStatus,
    mineCreateDepositTransactionStatus,
  } = useCreateDeposit(onSuccessCreateDeposit);


  //
  // approve(address spender, uint256 value)
  //

  const onSuccessApprove = useCallback((data: any) => {
    console.log('onSuccessApprove: ', data);

    refetchUsdcBalance?.();

    refetchUsdcApprovalToEscrow?.();
  }, [refetchUsdcBalance, refetchUsdcApprovalToEscrow]);

  const {
    writeApproveAsync,
    setTokenAddressInput: setApproveTokenAddressInput,
    setAmountToApproveInput,
    setShouldConfigureApproveWrite,
    signApproveTransactionStatus,
    mineApproveTransactionStatus,
  } = useTokenApprove(onSuccessApprove);

  /*
   * Hooks
   */

  useEffect(() => {
    platforms.forEach(platform => {
      const platformCurrencies = paymentPlatformInfo[platform].platformCurrencies;
      platformCurrencies.forEach(currency => {
        setPlatformConversionRates(
          prevConversionRates => new Map(prevConversionRates).set(
            platform,
            new Map(prevConversionRates.get(platform)).set(currency, '')
          )
        );
      });
    });
  }, [depositToken]);

  useEffect(() => {
    const updateDepositState = async () => {
      const successfulDepositTransaction = mineCreateDepositTransactionStatus === 'success';

      if (successfulDepositTransaction) {
        setDepositState(NewDepositTransactionStatus.TRANSACTION_SUCCEEDED);
      } else {
        const currentTokenBalance = usdcBalance;
        const currentTokenApprovalToEscrow = usdcApprovalToEscrow;

        if (depositAmountValue !== '' && currentTokenBalance !== null && currentTokenApprovalToEscrow !== null) {
          const depositAmountBN = tokenUnits(depositAmountValue, tokenInfo[depositToken].tokenDecimals);
          const isDepositAmountGreaterThanBalance = depositAmountBN.gt(currentTokenBalance);
          const isDepositAmountLessThanMinDepositSize = depositAmountBN.lt(tokenInfo[depositToken].minimumDepositAmount);
          const isDepositAmountGreaterThanApprovedBalance = depositAmountBN.gt(currentTokenApprovalToEscrow);
          
          if (isDepositAmountGreaterThanBalance) {
            setDepositState(NewDepositTransactionStatus.INSUFFICIENT_BALANCE);
          } else if (isDepositAmountLessThanMinDepositSize) {
            setDepositState(NewDepositTransactionStatus.MIN_DEPOSIT_THRESHOLD_NOT_MET);
          } else {
            if (maxAmountValue !== '' && minAmountValue !== '') {
              const maxPerOrderBN = tokenUnits(maxAmountValue, tokenInfo[depositToken].tokenDecimals);
              const minPerOrderBN = tokenUnits(minAmountValue, tokenInfo[depositToken].tokenDecimals);
              const isMaxPerOrderGreaterThanDepositAmount = maxPerOrderBN.gt(depositAmountBN);
              const isMinPerOrderGreaterThanMaxPerOrder = minPerOrderBN.gt(maxPerOrderBN);
              const isMinPerOrderLessThanMinimumAmount = minPerOrderBN.lt(tokenInfo[depositToken].minimumDepositAmount);

              if (isMaxPerOrderGreaterThanDepositAmount) {
                setDepositState(NewDepositTransactionStatus.MAX_PER_ORDER_GREATER_THAN_DEPOSIT_AMOUNT);
              } else if (isMinPerOrderGreaterThanMaxPerOrder) {
                setDepositState(NewDepositTransactionStatus.MIN_PER_ORDER_GREATER_THAN_MAX_PER_ORDER);
              } else if (isMinPerOrderLessThanMinimumAmount) {
                setDepositState(NewDepositTransactionStatus.MIN_PER_ORDER_LESS_THAN_MINIMUM_AMOUNT);
              } else {
                const platformsWithMissingPayeeDetails = platforms.filter((platform) => {
                  const payeeDetails = platformPayeeDetails.get(platform);
                  return !payeeDetails || payeeDetails.trim() === '';
                });

                const platformsWithMissingRates = platforms.filter((platform: PaymentPlatformType) => {
                  let atleastOneRateSet = false;
                  const platformCurrencies = paymentPlatformInfo[platform].platformCurrencies;
                  for (const currency of platformCurrencies) {
                    const rate = platformConversionRates.get(platform)?.get(currency);
                    if (rate !== '0' && rate !== undefined && rate !== '' && rate !== '0.') {
                      atleastOneRateSet = true;
                      break;
                    }
                  }
                  return !atleastOneRateSet;
                });

                esl && console.log('platformsWithMissingPayeeDetails: ', platformsWithMissingPayeeDetails);
                esl && console.log('platformsWithMissingRates: ', platformsWithMissingRates);

                if(platforms.length === 0) {
                  setDepositState(NewDepositTransactionStatus.MISSING_PLATFORMS);
                } else if (platformsWithMissingPayeeDetails.length > 0) {
                  setDepositState(NewDepositTransactionStatus.MISSING_PAYEE_DETAILS);
                } else if (platformsWithMissingRates.length > 0) {
                  setDepositState(NewDepositTransactionStatus.INVALID_PLATFORM_CURRENCY_RATES);
                } else {
                  if (allPayeeDetailsPosted) {
                    const signingApproveTransaction = signApproveTransactionStatus === 'loading';
                    const miningApproveTransaction = mineApproveTransactionStatus === 'loading';
                    const successfulApproveTransaction = mineApproveTransactionStatus === 'success';

                    if (isDepositAmountGreaterThanApprovedBalance && !successfulApproveTransaction) {
                      if (signingApproveTransaction) {
                        setDepositState(NewDepositTransactionStatus.TRANSACTION_SIGNING);
                      } else if (miningApproveTransaction) {
                        setDepositState(NewDepositTransactionStatus.TRANSACTION_MINING);
                      } else {
                        setDepositState(NewDepositTransactionStatus.APPROVAL_REQUIRED);
                      }
                    } else {
                      const signingDepositTransaction = signCreateDepositTransactionStatus === 'loading';
                      const miningDepositTransaction = mineCreateDepositTransactionStatus === 'loading';
                
                      if (signingDepositTransaction) {
                        setDepositState(NewDepositTransactionStatus.TRANSACTION_SIGNING);
                      } else if (miningDepositTransaction){
                        setDepositState(NewDepositTransactionStatus.TRANSACTION_MINING);
                      } else {
                        setDepositState(NewDepositTransactionStatus.VALID);
                      }
                    }
                  } else {
                    setDepositState(NewDepositTransactionStatus.VALIDATE_PAYEE_DETAILS);
                  }
                }
              } 
            } else {
              setDepositState(NewDepositTransactionStatus.MISSING_MIN_MAX_AMOUNTS);
            }
          }
        } else {
          setDepositState(NewDepositTransactionStatus.MISSING_AMOUNTS);
        }
      }
    }

    updateDepositState();
  }, [
      depositAmountValue,
      minAmountValue,
      maxAmountValue,
      depositToken,
      platforms,
      platformPayeeDetails,
      platformConversionRates,
      usdcBalance,
      usdcApprovalToEscrow,
      allPayeeDetailsPosted,
      signApproveTransactionStatus,
      mineApproveTransactionStatus,
      signCreateDepositTransactionStatus,
      mineCreateDepositTransactionStatus
    ]
  );

  useEffect(() => {
    const isApprovalRequired = depositState === NewDepositTransactionStatus.APPROVAL_REQUIRED;
    setShouldConfigureApproveWrite(isApprovalRequired);
  }, [depositState]);

  useEffect(() => {
    if (usdcAddress) {
      setApproveTokenAddressInput(usdcAddress);
      setDepositTokenInput(usdcAddress);
    }
  }, [depositToken, usdcAddress]);

  useEffect(() => {
    if (depositAmountValue !== '' && isValidInput(depositAmountValue)) {
      setDepositAmountInput(tokenUnits(depositAmountValue, 6).toString());
    } else {
      setDepositAmountInput('0');
    }
  }, [depositAmountValue]);

  useEffect(() => {
    if (depositAmountValue !== '' && isValidInput(depositAmountValue)) {
      const maxUsdcValue = Math.min(parseFloat(depositAmountValue), 1000).toString();
      setMaxAmountValue(maxUsdcValue);
    }
  }, [depositAmountValue]);

  useEffect(() => {
    if (minAmountValue !== '' && isValidInput(minAmountValue) && maxAmountValue !== '' && isValidInput(maxAmountValue)) {
      setIntentAmountRangeInput({
        min: tokenUnits(minAmountValue, tokenInfo[depositToken].tokenDecimals),
        max: tokenUnits(maxAmountValue, tokenInfo[depositToken].tokenDecimals)
      });
    } else {
      setIntentAmountRangeInput({
        min: ZERO,
        max: ZERO
      });
    }
  }, [minAmountValue, maxAmountValue, depositToken]);

  useEffect(() => {
    setVerifiersInput(platforms.map(
      (platform) => platformToVerifierAddress[platform as PaymentPlatformType] as `0x${string}` ?? null
    ));
  }, [platforms]);

  useEffect(() => {
    setHashedPayeeDetails(new Map());
    setAllPayeeDetailsPosted(false);
  }, [platforms, platformPayeeDetails]);

  useEffect(() => {
    const currenciesInput: { code: string, conversionRate: string }[][] = platforms.map((platform) => {
      const platformCurrencies = paymentPlatformInfo[platform].platformCurrencies;
      const platformRates = platformConversionRates.get(platform);
      return platformCurrencies
        .map((currency) => {
          const rate = platformRates?.get(currency);
          if (!rate || rate === '' || rate === '0') return null;

          try {
          const conversionRate = etherUnits(rate);
          if (conversionRate === ZERO) return null;
            return {
              code: currencyInfo[currency].currencyCodeHash,
              conversionRate: conversionRate.toString()
            };  
          } catch (error) {
            console.error('Faile to convert rate:', error);
            return null;
          }
        })
        .filter((currency): currency is { code: string, conversionRate: string } => currency !== null);
    });
    setCurrenciesInput(currenciesInput);
  }, [platforms, platformConversionRates]);

  useEffect(() => {
    setShouldConfigureCreateDepositWrite(depositState === NewDepositTransactionStatus.VALID);
  }, [depositState]);

  useEffect(() => {
    const currentTokenApprovalToEscrow = usdcApprovalToEscrow;
    esl && console.log('currentTokenApprovalToEscrow: ', currentTokenApprovalToEscrow);

    if (depositAmountValue === '' || currentTokenApprovalToEscrow === null) {
      esl && console.log('setting amount to approve to 0');
      setAmountToApproveInput(ZERO.toString());
    } else {
      const depositAmountBN = tokenUnits(depositAmountValue, tokenInfo[depositToken].tokenDecimals);
      const approvalDifference = depositAmountBN.sub(currentTokenApprovalToEscrow);

      esl && console.log('approvalDifference: ', approvalDifference);
      if (approvalDifference.gt(ZERO)) {
        setAmountToApproveInput(depositAmountBN.toString());
      } else {
        setAmountToApproveInput(ZERO.toString());
      }
    }
    
  }, [depositAmountValue, usdcApprovalToEscrow]);


  useEffect(() => {
    if (allPayeeDetailsPosted && witnessAddresses) {
      const verifierDataInput: EscrowDepositVerifierData[] = platforms.map((platform) => {
        const hashedOnchainId = hashedPayeeDetails.get(platform) ?? '';
        const depositData = ethers.utils.defaultAbiCoder.encode(
          ['address[]'],
          [witnessAddresses]
        );
        return {
          intentGatingService: gatingServiceAddress as `0x${string}`,
          payeeDetails: hashedOnchainId,
          data: depositData
        };
      });

      setVerifierDataInput(verifierDataInput);
    }
  }, [allPayeeDetailsPosted, hashedPayeeDetails, witnessAddresses]);


  /*
   * Helpers
   */

  const postAllPayeeDetails = async () => {
    const requests: PostDepositDetailsRequest[] = [];

    platforms.forEach(platform => {
      const payeeDetail = platformPayeeDetails.get(platform) ?? '';
      if (payeeDetail) {
        requests.push({
          depositData: paymentPlatformInfo[platform].deposit.getDepositData(payeeDetail, telegramUsername),
          processorName: platform
        });
      }
    });

    try {
      setDepositState(NewDepositTransactionStatus.POSTING_PAYEE_DETAILS);
      const responses = await Promise.all(
        requests.map(request => postDepositDetails(request))
      );

      const hasError = responses.some(response => !response);
      if (hasError) {
        setDepositState(NewDepositTransactionStatus.INVALID_PAYEE_DETAILS);
        return;
      }

      responses.forEach((response) => {
        if (response?.responseObject) {
          const platform = response.responseObject.processorName;
          const payeeDetail = platformPayeeDetails.get(platform) ?? '';
          
          setStoredPayeeDetails((prev: {[key: string]: string}) => ({
            ...prev,
            [platform]: payeeDetail
          }));

          setHashedPayeeDetails(
            prevHashedPayeeDetails => new Map(prevHashedPayeeDetails).set(
              platform,
              response.responseObject.hashedOnchainId
            )
          );
        }
      });

      setAllPayeeDetailsPosted(true);

    } catch (error) {
      console.error('Error posting payee details:', error);
      setDepositState(NewDepositTransactionStatus.INVALID_PAYEE_DETAILS);
    }
  };

  function isValidInput(value: string) {
    const isValid = /^-?\d*(\.\d{0,6})?$/.test(value);
    
    return parseFloat(value) >= 0 && isValid;
  }

  const ctaDisabled = (): boolean => {
    switch (depositState) {
      case NewDepositTransactionStatus.INSUFFICIENT_BALANCE:
      case NewDepositTransactionStatus.APPROVAL_REQUIRED:
      case NewDepositTransactionStatus.VALIDATE_PAYEE_DETAILS:
      case NewDepositTransactionStatus.VALID:
      case NewDepositTransactionStatus.TRANSACTION_SUCCEEDED:
        return false;

      default:
        return true;
    }
  }

  const ctaLoading = (): boolean => {
    switch (depositState) {
      case NewDepositTransactionStatus.TRANSACTION_SIGNING:
      case NewDepositTransactionStatus.TRANSACTION_MINING:
      case NewDepositTransactionStatus.POSTING_PAYEE_DETAILS:
        return true;

      default:
        return false;
    }
  };

  const ctaText = (): string => {

    const minimumDepositAmountString = tokenUnitsToReadable(tokenInfo[depositToken].minimumDepositAmount, tokenInfo[depositToken].tokenDecimals);

    switch (depositState) {
      case NewDepositTransactionStatus.MISSING_AMOUNTS:
        return 'Input deposit amount';

      case NewDepositTransactionStatus.MISSING_MIN_MAX_AMOUNTS:
        return 'Input min and max per order amounts';
      
      case NewDepositTransactionStatus.MISSING_PLATFORMS:
        return 'Add at least one payment platform';

      case NewDepositTransactionStatus.MISSING_PAYEE_DETAILS:
        return 'Missing payee details';

      case NewDepositTransactionStatus.VALIDATE_PAYEE_DETAILS:
        return 'Validate payee details';

      case NewDepositTransactionStatus.POSTING_PAYEE_DETAILS:
        return 'Posting payee details';

      case NewDepositTransactionStatus.INVALID_PAYEE_DETAILS:
        return 'Payee details validation failed. Please enter valid payee details';

      case NewDepositTransactionStatus.INVALID_PLATFORM_CURRENCY_RATES:
        return 'Each platform must have at least one currency rate set';

      case NewDepositTransactionStatus.INSUFFICIENT_BALANCE:
        return `Insufficient balance — Deposit USDC`;
      
      case NewDepositTransactionStatus.MIN_DEPOSIT_THRESHOLD_NOT_MET:
        return `Minimum deposit amount is ${minimumDepositAmountString}`;

      case NewDepositTransactionStatus.MAX_PER_ORDER_GREATER_THAN_DEPOSIT_AMOUNT:
        return `Max per order cannot be greater than deposit amount`;

      case NewDepositTransactionStatus.MIN_PER_ORDER_GREATER_THAN_MAX_PER_ORDER:
        return `Min per order cannot be greater than max per order`;

      case NewDepositTransactionStatus.MIN_PER_ORDER_LESS_THAN_MINIMUM_AMOUNT:
        return `Min per order cannot be less than ${minimumDepositAmountString}`;

      case NewDepositTransactionStatus.TRANSACTION_SIGNING:
        return 'Signing Transaction';

      case NewDepositTransactionStatus.TRANSACTION_MINING:
        return 'Mining Transaction';

      case NewDepositTransactionStatus.APPROVAL_REQUIRED:
        return `Approve ${tokenInfo[depositToken].ticker}`;

      case NewDepositTransactionStatus.VALID:
        return 'Create Deposit';

      case NewDepositTransactionStatus.TRANSACTION_SUCCEEDED:
        return 'Go to Deposits';

      case NewDepositTransactionStatus.DEFAULT:
      default:
        return 'Create Deposit';
    }
  }

  const ctaOnClick = async () => {
    switch (depositState) {
      case NewDepositTransactionStatus.APPROVAL_REQUIRED:
        try {
          console.log('writeSubmitApproveAsync: ');

          await writeApproveAsync?.();
        } catch (error) {
          console.log('writeSubmitApproveAsync failed: ', error);
        }
        break;

      case NewDepositTransactionStatus.VALIDATE_PAYEE_DETAILS:
        try {
          await postAllPayeeDetails();
        } catch (error) {
          console.log('postAllPayeeDetails failed: ', error);
        }
        break;

      case NewDepositTransactionStatus.VALID:
        try {
          await writeCreateDepositAsync?.();
        } catch (error) {
          console.log('writeCreateDepositAsync failed: ', error);
        }
        break;

      case NewDepositTransactionStatus.TRANSACTION_SUCCEEDED:
        handleBackClick();
        break;

      case NewDepositTransactionStatus.INSUFFICIENT_BALANCE:
        openModal(MODALS.RECEIVE);
        break;

      default:
        break;
    }
  }

  const tokenBalanceLabel = useMemo(() => {
    if (isLoggedIn) {
      return `${tokenUnitsToReadable(usdcBalance ?? ZERO, 6)} USDC`
    } else {
      return '';
    }
  }, [usdcBalance, isLoggedIn]);

  /*
   * Handlers
   */

  const handleDepositAmountChange = (value: string) => {
    if (value === "") {
      setDepositAmountValue('');
    } else if (value === ".") {
      setDepositAmountValue('0.');
    } else if (isValidInput(value)) {
      setDepositAmountValue(value);
    }
  };

  const handleMaxButtonClick = () => {
    setDepositAmountValue(tokenUnitsToReadable(usdcBalance ?? ZERO, 6));
  };

  const handleAddPlatformButtonClick = () => {
    // Initialize a new platform with default values
    const newPlatform = getAvailablePlatforms(platforms.length)[0];
    
    setPlatforms(prevPlatforms => [...prevPlatforms, newPlatform]);
    setPlatformPayeeDetails(prevPayeeDetails => new Map(prevPayeeDetails).set(newPlatform, ''));
    setPlatformConversionRates(prevConversionRates => new Map(prevConversionRates).set(newPlatform, new Map()));
  };

  const handleRemovePlatformButtonClick = (platform: PaymentPlatformType) => {
    setPlatforms(prevPlatforms => prevPlatforms.filter(p => p !== platform));
    setPlatformPayeeDetails(prevPayeeDetails => new Map(prevPayeeDetails).set(platform, ''));
    setPlatformConversionRates(prevConversionRates => new Map(prevConversionRates).set(platform, new Map()));
  };

  const handleMinAmountChange = (value: string) => {
    if (value === "") {
      setMinAmountValue('');
    } else if (value === ".") {
      setMinAmountValue('0.');
    } else if (isValidInput(value)) {
      setMinAmountValue(value);
    }
  };

  const handleMaxAmountChange = (value: string) => {
    if (value === "") {
      setMaxAmountValue('');
    } else if (value === ".") {
      setMaxAmountValue('0.');
    } else if (isValidInput(value)) {
      setMaxAmountValue(value);
    }
  };

  const handleMaxPerOrderButtonClick = () => {
    setMaxAmountValue(depositAmountValue);
  };

  /*
   * Helpers
   */

  const getAvailablePlatforms = (currentIndex: number) => {
    return paymentPlatforms.filter((platform) => {
      const isNotAlreadySelected = !platforms.includes(platform) || platforms[currentIndex] === platform;
      return isNotAlreadySelected;
    });
  };
  
  const addPlatform = (index: number): ((platform: PaymentPlatformType) => void) => {
    return (platform: PaymentPlatformType) => {
      setPlatforms(prevPlatforms => [...prevPlatforms.slice(0, index), platform, ...prevPlatforms.slice(index + 1)]);
    };
  };

  const getPayeeDetailsSetter = (platform: PaymentPlatformType) => {
    return (details: string) => {
      setPlatformPayeeDetails(prevPayeeDetails => new Map(prevPayeeDetails).set(platform, details));
    };
  };

  const getConversionRateSetter = (platform: PaymentPlatformType): ((currency: CurrencyType, rate: string) => void) => {
    return (currency: CurrencyType, rate: string) => {
      setPlatformConversionRates(
        prevConversionRates => new Map(prevConversionRates).set(
          platform,
          new Map(prevConversionRates.get(platform)).set(currency, rate)
        )
      );
    };
  };

  /*
   * Component
   */

  return (
    <Container>
      <RowBetween style={{ padding: '0.25rem 0rem 1.5rem 0rem' }}>
        <div style={{ flex: 0.5 }}>
          <button
            onClick={handleBackClick}
            style={{ background: 'none', border: 'none', cursor: 'pointer' }}
          >
            <StyledArrowLeft/>
          </button>
        </div>

        <ThemedText.HeadlineSmall style={{ flex: '1', margin: 'auto', textAlign: 'center' }}>
          New Deposit
        </ThemedText.HeadlineSmall>

        <div style={{ flex: 0.5 }}/>
      </RowBetween>

      <Body>        
        <InputsContainer>
          <InputWithTokenSelector
            label="Deposit Amount"
            name={`depositAmount`}
            value={depositAmountValue}
            onChange={(e) => handleDepositAmountChange(e.currentTarget.value)}
            type="number"
            inputLabel="USDC"
            placeholder="0"
            accessoryLabel={tokenBalanceLabel}
            enableMax={true}
            maxButtonOnClick={handleMaxButtonClick}
            hasSelector={true}
            selectedToken={depositToken}
            setSelectedToken={setDepositToken}
            onlyShowDepositAllowedTokens={true}
          />

          <Input
            label="Telegram Username (Optional)"
            name="telegramUsername"
            value={telegramUsername}
            onChange={(e) => setTelegramUsername(e.currentTarget.value)}
            placeholder="@username"
            helperText='This will help your counterparty find you on Telegram if they need to contact you.'
          />

          <NewPaymentPlatformContainer>
            {platforms.map((platform, index) => 
              <NewPaymentPlatform
                key={index}
                depositToken={depositToken}
                selectedPlatform={platform}
                allPlatforms={getAvailablePlatforms(index)}
                setSelectedPlatform={addPlatform(index)}
                payeeDetails={platformPayeeDetails.get(platform) ?? ''}
                setPayeeDetails={getPayeeDetailsSetter(platform)}
                conversionRates={platformConversionRates.get(platform) ?? new Map()}
                setConversionRates={getConversionRateSetter(platform)}
                handleRemovePlatform={() => handleRemovePlatformButtonClick(platform)}
              />
            )}
          </NewPaymentPlatformContainer> 

          {getAvailablePlatforms(platforms.length).length > 0 && (
            <AddPaymentPlatformButtonContainer>
              <AccessoryButton
                onClick={handleAddPlatformButtonClick}
                height={36}
                icon="plus"
                title="Add Payment Platform"
                iconPosition='left'
                textAlign='right'
                fullWidth={false}
              />
            </AddPaymentPlatformButtonContainer>
          )}

          <AdvancedSettings
            minAmountValue={minAmountValue}
            maxAmountValue={maxAmountValue}
            handleMinAmountChange={handleMinAmountChange}
            handleMaxAmountChange={handleMaxAmountChange}
            handleMaxPerOrderButtonClick={handleMaxPerOrderButtonClick}
          />

          <ButtonContainer>
            <Button
              fullWidth={true}
              disabled={ctaDisabled()}
              onClick={ctaOnClick}
            >
              <ButtonContentWrapper>
                {ctaLoading() && <StyledSpinner size={20} />}
                <span>{ctaText()}</span>
              </ButtonContentWrapper>
            </Button>
          </ButtonContainer>
        </InputsContainer>
      </Body>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: ${colors.container};
  border-radius: 16px;
  border: 1px solid ${colors.defaultBorderColor};

  @media (min-width: 600px) {
    min-width: 484px;
    max-width: 484px;
  }

  @media (max-width: 600px) {
    padding: 12px;
  }
`;


const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: ${colors.container};
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ButtonContainer = styled.div`
  display: grid;
`;

const StyledArrowLeft = styled(ArrowLeft)`
  color: #FFF;
`;

const NewPaymentPlatformContainer = styled.div`
  width: 100%;
  display: grid;
  gap: 1rem;
`;

const AddPaymentPlatformButtonContainer = styled.div`
  display: grid;
  justify-content: flex-end;
`;

const ButtonContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledSpinner = styled(Spinner)`
  margin-left: 8px;
`;
