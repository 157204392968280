export enum Z_INDEX {
  default = 1,
  landing_hero = 5,
  bottom_nav = 5,
  overlay = 10,
  receive_modal = 20,
  confetti = 20,
  mobile_landing_page = 50,

  popover = 1000,
};
