export var LogLevel;
(function (LogLevel) {
    LogLevel[LogLevel["Verbose"] = 4] = "Verbose";
    LogLevel[LogLevel["Info"] = 3] = "Info";
    LogLevel[LogLevel["Warn"] = 2] = "Warn";
    LogLevel[LogLevel["Error"] = 1] = "Error";
    LogLevel[LogLevel["None"] = 0] = "None";
})(LogLevel || (LogLevel = {}));
export const log = (params, level, currentLevel) => {
    if (currentLevel >= level) {
        const data = params.reduce((params, param, i) => {
            if ((i + 1) % 2) {
                params.push('\n');
            }
            params.push(param);
            return params;
        }, []);
        switch (level) {
            case LogLevel.Info:
                console.info(...data);
                break;
            case LogLevel.Error:
                console.error(...data);
                break;
            case LogLevel.Warn:
                console.warn(...data);
                break;
            default:
                console.log(...data);
                break;
        }
    }
};
