import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import useSmartContracts from '@hooks/contexts/useSmartContracts';

export default function useReleaseFundsToPayerTransaction(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */
  const { escrowAddress, escrowAbi } = useSmartContracts();

  /*
   * State
   */
  const [intentHashInput, setIntentHashInput] = useState<string | null>(null);
  const [shouldConfigureReleaseFundsWrite, setShouldConfigureReleaseFundsWrite] = useState<boolean>(false);

  /*
   * Contract Writes
   */
  const { config: writeReleaseFundsConfig } = usePrepareContractWrite({
    address: escrowAddress,
    abi: escrowAbi,
    functionName: 'releaseFundsToPayer',
    args: [
      intentHashInput,
    ],
    onError(error: any) {
      console.log('writeReleaseFundsConfig failed: ', error.message);
      setShouldConfigureReleaseFundsWrite(false);
      onError?.(error);
    },
    enabled: shouldConfigureReleaseFundsWrite,
    request: {
      maxPriorityFeePerGas: (existing: bigint) => existing * BigInt(2),
    },
  });

  const {
    data: submitReleaseFundsResult,
    status: signReleaseFundsTransactionStatus,
    writeAsync: writeReleaseFundsAsync,
  } = useContractWrite(writeReleaseFundsConfig);

  const {
    status: mineReleaseFundsTransactionStatus,
  } = useWaitForTransaction({
    hash: submitReleaseFundsResult ? submitReleaseFundsResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeReleaseFundsAsync successful: ', data);
      setShouldConfigureReleaseFundsWrite(false);
      onSuccess?.(data);
    },
  });

  return {
    writeReleaseFundsAsync,
    intentHashInput,
    setIntentHashInput,
    shouldConfigureReleaseFundsWrite,
    setShouldConfigureReleaseFundsWrite,
    signReleaseFundsTransactionStatus,
    mineReleaseFundsTransactionStatus,
    transactionHash: submitReleaseFundsResult?.hash,
  };
}
