import React, { useEffect, useState } from 'react';
import { Link, Filter } from 'react-feather';
import styled, { css } from 'styled-components/macro';
import { colors } from '@theme/colors';
import { ThemedText } from '@theme/text';


import { currencies, getCurrencyInfoFromHash, Token, tokenInfo, TokenType } from '@helpers/types';
import { CurrencyType, currencyInfo } from '@helpers/types';
import { PaymentPlatformType,  paymentPlatformInfo, paymentPlatforms } from '@helpers/types';
import { tokenUnitsToReadable, etherUnitsToReadable, usdcUnits } from '@helpers/units';
import { isSupportedCurrency } from '@helpers/types/currency';

import { CurrencySelector } from '@components/modals/selectors/currency';
import { PlatformSelector } from '@components/modals/selectors/platform';
import { ZERO_ADDRESS } from '@helpers/constants';

import useGeolocation from '@hooks/contexts/useGeolocation';
import useMediaQuery from '@hooks/useMediaQuery';
import useLiquidity from '@hooks/contexts/useLiquidity';
import useSmartContracts from '@hooks/contexts/useSmartContracts';
import { LiquidityRow } from './LiquidityRow';
import { LiquidityRowMobile } from './LiquidityRowMobile';

import { esl } from '@helpers/constants';
import { BigNumber } from 'ethers';

const ROWS_PER_PAGE = 9;


interface LiquidityRowData {
  depositor: string;
  token: TokenType;
  availableLiquidity: string;
  currency: CurrencyType;
  conversionRate: string;
  platform: PaymentPlatformType;
  intentAmountRange: {
    min: string;
    max: string;
  };
}

export const LiquidityTable: React.FC = () => {

  LiquidityTable.displayName = 'LiquidityTable';
  /*
   * Contexts
   */

  const currentDeviceSize = useMediaQuery();
  const isMobile = currentDeviceSize === 'mobile';
  
  const {addressToPlatform, usdcAddress} = useSmartContracts();
  const { depositViews } = useLiquidity();
  const { currencyCode } = useGeolocation();

  /*
   * State
   */

  const [liquidityRows, setLiquidityRows] = useState<LiquidityRowData[]>([]);
  
  const [selectedCurrency, setSelectedCurrency] = useState<CurrencyType | null>(
    currencyCode ? (isSupportedCurrency(currencyCode) ? currencyCode : 'USD') : null
  );
  const [selectedPlatform, setSelectedPlatform] = useState<PaymentPlatformType | null>(null);
  const [allPlatforms, setAllPlatforms] = useState<PaymentPlatformType[]>(paymentPlatforms);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [paginatedData, setPaginatedData] = useState<LiquidityRowData[]>([]);

  const hasActiveFilters = selectedCurrency !== null || selectedPlatform !== null;

  /*
   * Hooks
   */

  useEffect(() => {
    if (currencyCode) {
      if (isSupportedCurrency(currencyCode)) {
        setSelectedCurrency(currencyCode);
      } else {
        setSelectedCurrency('USD');
      }
    }
  }, [currencyCode]);


  useEffect(() => {
    setCurrentPage(0);

    if (!depositViews) return;

    let filteredViews = depositViews.filter(
      depositView => (
        depositView.deposit.depositor !== ZERO_ADDRESS &&
        depositView.availableLiquidity.gte(usdcUnits('1'))
      )
    );

    let liquidityRowsData: LiquidityRowData[] = [];

    filteredViews.forEach(depositView => {
      const depositor = depositView.deposit.depositor;
      const tokenAddress = depositView.deposit.token;
      const availableLiquidity = depositView.availableLiquidity;
      const token = Token.USDC;
      const tokenDecimals = tokenInfo[token].tokenDecimals;
      const intentAmountRange = {
        min: tokenUnitsToReadable(depositView.deposit.intentAmountRange.min, tokenDecimals, 1),
        max: tokenUnitsToReadable(depositView.deposit.intentAmountRange.max, tokenDecimals, 1)
      };

      depositView.verifiers.forEach(verifier => {
        const verifierAddress = verifier.verifier;
        const platform = addressToPlatform[verifierAddress];

        verifier.currencies.forEach(currency => {
          const currencyCode = currency.code;
          const currencyInfo = getCurrencyInfoFromHash(currencyCode);
          const conversionRate = currency.conversionRate;

          if (!currencyInfo) return;

          liquidityRowsData.push({
            depositor,
            token,
            availableLiquidity: tokenUnitsToReadable(availableLiquidity, tokenDecimals),
            currency: currencyInfo.currencyCode,
            conversionRate: Number(etherUnitsToReadable(conversionRate, 4)).toString(),
            platform,
            intentAmountRange
          });
        });
      });
    });

    esl && console.log('liquidityRowsData', liquidityRowsData);

    // Sort by currency code and price
    liquidityRowsData.sort((a, b) => {
      // First sort by currency code
      if (a.currency !== b.currency) {
        return a.currency.localeCompare(b.currency);
      }
      // Within same currency, sort by price (lowest to highest)
      return parseFloat(a.conversionRate) - parseFloat(b.conversionRate);
    });

    // Apply currency filter
    if (selectedCurrency) {
      liquidityRowsData = liquidityRowsData.filter(row =>
        row.currency === selectedCurrency
      );
    }

    esl && console.log('liquidityRowsData after currency filter', liquidityRowsData);

    // Apply platform filter 
    if (selectedPlatform) {
      liquidityRowsData = liquidityRowsData.filter(row =>
        row.platform === selectedPlatform
      );
    }

    esl && console.log('liquidityRowsData after platform filter', liquidityRowsData);

    setLiquidityRows(liquidityRowsData);
  }, [depositViews, selectedCurrency, selectedPlatform]);


  useEffect(() => {
    if (liquidityRows) {
      setTotalPages(Math.ceil(liquidityRows.length / ROWS_PER_PAGE));
      setPaginatedData(liquidityRows.slice(currentPage * ROWS_PER_PAGE, (currentPage + 1) * ROWS_PER_PAGE));
    }
  }, [liquidityRows, currentPage]);


  useEffect(() => {
    if (selectedCurrency) {
      const platformsThatSupportCurrency = Object.entries(paymentPlatformInfo).filter(
        ([_, platformData]) => platformData.platformCurrencies.includes(selectedCurrency)
      );
      
      setAllPlatforms(platformsThatSupportCurrency.map(([platform]) => platform));
    }
  }, [selectedCurrency]);
  
  /*
   * Handlers
   */

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleClearFilters = () => {
    setSelectedCurrency(null);
    setSelectedPlatform(null);
    setAllPlatforms(paymentPlatforms);
  };

  /*
   * Component
   */

  const renderPageUpdateButtons = () => {

    if (liquidityRows.length === 0 || totalPages === 0 || liquidityRows.length <= ROWS_PER_PAGE) {
      return null;
    }

    return (
      <PaginationContainer>
        <PaginationButton disabled={currentPage === 0} onClick={() => handleChangePage(currentPage - 1)}>
          &#8249;
        </PaginationButton>
        <PageInfo>
          {totalPages === 0 ? '0 of 0' : `${currentPage + 1} of ${totalPages}`}
        </PageInfo>
        <PaginationButton
          disabled={currentPage === totalPages - 1 || totalPages === 0}
          onClick={() => handleChangePage(currentPage + 1)}>  
          &#8250;
        </PaginationButton>
      </PaginationContainer>
    );
  }

  return (
    <Container>
      <TitleRow>
        <ThemedText.HeadlineMedium>
          Liquidity
        </ThemedText.HeadlineMedium>
      
        <FiltersContainer>
          <CurrencyAndPlatformSelectorContainer>  
            <CurrencySelector
              selectedCurrency={selectedCurrency}
              setSelectedCurrency={setSelectedCurrency}
              allCurrencies={currencies}
            />
          
            <PlatformSelector
              paymentPlatform={selectedPlatform}
              setPaymentPlatform={setSelectedPlatform}
              allPlatforms={allPlatforms}
            />
          </CurrencyAndPlatformSelectorContainer>

          <FilterButton 
            onClick={handleClearFilters} 
            active={hasActiveFilters}
            disabled={!hasActiveFilters}
          >
            <Filter size={20} />
          </FilterButton>
        </FiltersContainer>
      </TitleRow>

      <Content>
        {liquidityRows?.length === 0 || liquidityRows == null ? (
          <ErrorContainer>
            <ThemedText.DeprecatedBody textAlign="center">
              <ChainLinkIcon strokeWidth={1} style={{ marginTop: '2em' }} />
              <div>
                No deposits found.
              </div>
            </ThemedText.DeprecatedBody>
          </ErrorContainer>
        ) : (
          <TableContainer>
            {!isMobile ? (
              <>
                <TableHeaderRow>
                  <ColumnHeader>Currency</ColumnHeader>
                  <ColumnHeader>Price</ColumnHeader>
                  <ColumnHeader>Depositor</ColumnHeader>
                  <ColumnHeader>Payment</ColumnHeader>
                  <ColumnHeader>Available / Limits</ColumnHeader>
                  {/* <ColumnHeader>Trade</ColumnHeader> */}
                </TableHeaderRow>
                <Table>
                  {paginatedData.map((liquidityRow, rowIndex) => (
                    <PositionRowStyled key={rowIndex}>
                      <LiquidityRow
                        depositor={liquidityRow.depositor}
                        token={liquidityRow.token}
                        availableLiquidity={liquidityRow.availableLiquidity}
                        currency={liquidityRow.currency}
                        conversionRate={liquidityRow.conversionRate}
                        platform={liquidityRow.platform}
                        rowIndex={rowIndex + currentPage * ROWS_PER_PAGE}
                        limits={liquidityRow.intentAmountRange}
                      />
                    </PositionRowStyled>
                  ))}
                </Table>
              </>
            ) : (
               <>
                <TableMobile>
                  {paginatedData.map((liquidityRow, rowIndex) => (
                    <LiquidityRowMobile
                      key={rowIndex}
                      depositor={liquidityRow.depositor}
                      token={liquidityRow.token}
                      availableLiquidity={liquidityRow.availableLiquidity}
                      currency={liquidityRow.currency}
                      conversionRate={liquidityRow.conversionRate}
                      platform={liquidityRow.platform}
                      limits={liquidityRow.intentAmountRange}
                    />
                  ))}
                </TableMobile>
              </>
            )}
          </TableContainer>
        )}
      </Content>

      {renderPageUpdateButtons()}
    </Container>
  )
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  
  @media (max-width: 600px) {
    gap: 1.5rem;
    margin: 0 auto;
    width: 98%;
  }
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  height: 50px;
  align-items: flex-end;
  justify-content: space-between;
  color: #FFF;
  padding: 0 1rem;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

const Content = styled.main`
  @media (min-width: 600px) {  
    background-color: ${colors.container};
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
      0px 24px 32px rgba(0, 0, 0, 0.01);  
    border: 1px solid ${colors.defaultBorderColor};
    border-radius: 16px;
  }
`;

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 36px;
  max-width: 340px;
  min-height: 25vh;
  gap: 36px;
`;

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;

const ChainLinkIcon = styled(Link)`
  ${IconStyle}
`;

const TableContainer = styled.div`
  display: block;
`;

const TableHeaderRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr 1fr 1fr;
  padding: 1.3rem 1.5rem 1rem 1.5rem;
  gap: 1.5rem;
  text-align: left;
  border-bottom: 1px solid ${colors.defaultBorderColor};

  // @media (min-width: 600px) {
  //   // grid-template-columns: .2fr .9fr .6fr 1.1fr repeat(2, minmax(0,1fr));
  //   // padding: 1.3rem 1.75rem 1rem 1.75rem;
  // }
`;

const Table = styled.div`
  width: 100%;
  border-radius: 8px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  color: #616161;

  @media (max-width: 600px) {
    font-size: 12px;
  };

  & > * {
    border-bottom: 1px solid ${colors.defaultBorderColor};
  }

  & > *:last-child {
    border-bottom: none;
  }
`;

const TableMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ColumnHeader = styled.div`
  text-align: left;
  font-size: 14px;
  opacity: 0.7;
  
  @media (max-width: 600px) {
    font-size: 13px;
  };
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

const PaginationButton = styled.button`
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 8px 16px;
  margin: 0 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  &:disabled {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: not-allowed;
  }
`;

const PageInfo = styled.span`
  color: rgba(255, 255, 255, 0.8);
  word-spacing: 2px;
  font-size: 16px;
`;

const PositionRowStyled = styled.div`
  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;

const CurrencyAndPlatformSelectorContainer = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 600px) {
    gap: 0.3rem;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 600px) {
    gap: 0.3rem;
  }
`;

const FilterButton = styled.button<{ active: boolean }>`
  background: transparent;
  border: none;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: all 0.2s ease;
  position: relative;

  // Default/Idle state
  color: ${colors.lightGrayText};

  // Hover state
  &:hover:not(:disabled) {
    color: ${colors.buttonHover};
    cursor: pointer;
  }

  // Active/Filters Applied state
  ${props => props.active && css`
    color: ${colors.buttonDefault};
    
    &::after {
      content: '';
      position: absolute;
      top: 2px;
      right: 2px;
      width: 8px;
      height: 8px;
      background-color: ${colors.buttonDefault};
      border-radius: 50%;
    }

    &:hover {
      color: ${colors.buttonHover};
      
      &::after {
        background-color: ${colors.buttonHover};
      }
    }
  `}

  // Disabled state
  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;


