import { createContext } from 'react';

import { EscrowIntentView } from '@helpers/types/escrow';


interface OnRamperIntentsValues {
  currentIntentView: EscrowIntentView | null;
  refetchIntentView: (() => void) | null;
  shouldFetchIntentView: boolean;
  currentIntentHash: string | null;
};

const defaultValues: OnRamperIntentsValues = {
  currentIntentView: null,
  refetchIntentView: null,
  shouldFetchIntentView: false,
  currentIntentHash: null
};

const OnRamperIntentsContext = createContext<OnRamperIntentsValues>(defaultValues);

export default OnRamperIntentsContext;
