import React, { useEffect, useState } from 'react';
import styled from "styled-components";

import SwapForm from "@components/Swap";
import { CompleteOrderForm } from '@components/Swap/CompleteOrder';
import { SendPaymentForm } from '@components/Swap/SendPayment';

import useOnRamperIntents from '@hooks/contexts/useOnRamperIntents';
import useEscrowState from '@hooks/contexts/useEscrowState';
import useBalances from '@hooks/contexts/useBalance';
import useMediaQuery from '@hooks/useMediaQuery';

export const Swap: React.FC = () => {
  /*
   * Contexts
   */

  const currentDeviceSize = useMediaQuery();

  const { refetchUsdcBalance, shouldFetchUsdcBalance } = useBalances();
  
  const {
    refetchIntentView,
    shouldFetchIntentView,
  } = useOnRamperIntents();

  const {
    refetchDepositCounter,
    shouldFetchEscrowState,
  } = useEscrowState();

  /*
   * State
   */

  const [showCompleteOrder, setShowCompleteOrder] = useState<boolean>(false);
  const [showSendModal, setShowSendModal] = useState<boolean>(false);

  /*
   * Hooks
   */

  useEffect(() => {
    if (shouldFetchUsdcBalance) {
      refetchUsdcBalance?.();
    }

    if (shouldFetchIntentView) {
      refetchIntentView?.();
    }

    if (shouldFetchEscrowState) {
      refetchDepositCounter?.();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  /*
   * Component
   */

  const renderComponent = () => {
    if (showCompleteOrder) {
      return <CompleteOrderForm 
        handleBackClick={() => {
          setShowCompleteOrder(false);
          setShowSendModal(true);
        }}
        handleGoBackToSwap={() => {
          setShowCompleteOrder(false);
          setShowSendModal(false);
        }}
      />;
    }

    if (showSendModal) {
      return <SendPaymentForm
        onBackClick={() => setShowSendModal(false)}
        onCompleteClick={() => {
          setShowSendModal(false);
          setShowCompleteOrder(true);
        }}
      />;
    }

    return <SwapForm 
      onCompleteOrderClick={() => setShowSendModal(true)}
    />;
  }

  return (
    <PageWrapper $isMobile={currentDeviceSize === 'tablet' || currentDeviceSize === 'mobile'}>
      {renderComponent()}
    </PageWrapper>
  );
};

const PageWrapper = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 7rem;

  @media (min-width: 600px) {
    padding: 12px 8px;
    padding-bottom: 3rem;
    padding-top: 1rem;
  }
`;