import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import useSmartContracts from '@hooks/contexts/useSmartContracts';

export default function useSignalIntent(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */
  const { escrowAddress, escrowAbi } = useSmartContracts();

  /*
   * State
   */
  const [depositIdInput, setDepositIdInput] = useState<number | null>(null);
  const [tokenAmountInput, setTokenAmountInput] = useState<string | null>(null);
  const [recipientAddressInput, setRecipientAddressInput] = useState<string | null>(null);
  const [verifierAddressInput, setVerifierAddressInput] = useState<string | null>(null);
  const [currencyCodeHashInput, setCurrencyCodeHashInput] = useState<string | null>(null);
  const [gatingServiceSignatureInput, setGatingServiceSignatureInput] = useState<string | null>(null);
  const [shouldConfigureSignalIntentWrite, setShouldConfigureSignalIntentWrite] = useState<boolean>(false);

  /*
   * Contract Writes
   */
  const { config: writeSignalIntentConfig } = usePrepareContractWrite({
    address: escrowAddress,
    abi: escrowAbi,
    functionName: 'signalIntent',
    args: [
      depositIdInput,
      tokenAmountInput,
      recipientAddressInput,
      verifierAddressInput,
      currencyCodeHashInput,
      gatingServiceSignatureInput,
    ],
    onError(error: any) {
      console.log('writeSignalIntentConfig failed: ', error.message);
      setShouldConfigureSignalIntentWrite(false);
      onError?.(error);
    },
    enabled: shouldConfigureSignalIntentWrite,
    request: {
      maxPriorityFeePerGas: (existing: bigint) => existing * BigInt(2),
    },
  });

  const {
    data: submitSignalIntentResult,
    status: signSignalIntentTransactionStatus,
    writeAsync: writeSignalIntentAsync,
  } = useContractWrite(writeSignalIntentConfig);

  const {
    status: mineSignalIntentTransactionStatus,
  } = useWaitForTransaction({
    hash: submitSignalIntentResult ? submitSignalIntentResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeSignalIntentAsync successful: ', data);
      setShouldConfigureSignalIntentWrite(false);
      onSuccess?.(data);
    },
  });

  return {
    writeSignalIntentAsync,
    depositIdInput,
    setDepositIdInput,
    tokenAmountInput,
    setTokenAmountInput,
    recipientAddressInput,
    setRecipientAddressInput,
    verifierAddressInput,
    setVerifierAddressInput,
    currencyCodeHashInput,
    setCurrencyCodeHashInput,
    gatingServiceSignatureInput,
    setGatingServiceSignatureInput,
    shouldConfigureSignalIntentWrite,
    setShouldConfigureSignalIntentWrite,
    signSignalIntentTransactionStatus,
    mineSignalIntentTransactionStatus,
    transactionHash: submitSignalIntentResult?.hash,
  };
}
