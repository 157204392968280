import React, { useEffect, useState, ReactNode } from 'react';
import { esl } from '@helpers/constants';
import usePayeeDetails from '@hooks/backend/useGetPayeeDetails';
import { PaymentPlatformType, PaymentPlatform } from '@helpers/types';

import BackendContext from './BackendContext';
import { GetPayeeDetailsResponse } from '@helpers/types';

interface ProvidersProps {
  children: ReactNode;
}

const BackendProvider = ({ children }: ProvidersProps) => {
  /*
   * Hooks
   */
  const { fetchPayeeDetails, data } = usePayeeDetails();

  /*
   * State
   */
  const [shouldFetchPayeeDetails, setShouldFetchPayeeDetails] = useState<boolean>(false);
  const [currentHashedOnchainId, setCurrentHashedOnchainId] = useState<string | null>(null);
  const [currentPlatform, setCurrentPlatform] = useState<PaymentPlatformType | null>(null);

  const [rawPayeeDetails, setRawPayeeDetails] = useState<string>('');
  const [depositorTgUsername, setDepositorTgUsername] = useState<string>('');
  const [isFetchingRawPayeeDetails, setIsFetchingRawPayeeDetails] = useState<boolean>(false);

  /*
   * Effects
   */

  useEffect(() => {
    esl && console.log('payeeDetails_1');
    esl && console.log('checking shouldFetchPayeeDetails:', shouldFetchPayeeDetails);
    esl && console.log('checking currentHashedOnchainId:', currentHashedOnchainId);
    esl && console.log('checking currentPlatform:', currentPlatform);

    if (shouldFetchPayeeDetails && currentHashedOnchainId && currentPlatform) {
      esl && console.log('payeeDetails_2');
      setIsFetchingRawPayeeDetails(true);
      fetchPayeeDetails(currentHashedOnchainId, currentPlatform);
    }
  }, [shouldFetchPayeeDetails, currentHashedOnchainId, currentPlatform, fetchPayeeDetails]);

  useEffect(() => {
    esl && console.log('rawPayeeDetails_1');

    if (data) {
      esl && console.log('rawPayeeDetails_2');
      esl && console.log('rawPayeeDetails:', data);

      const extractRawPayeeDetails = (data: GetPayeeDetailsResponse) => {
        switch(currentPlatform) {
          case PaymentPlatform.VENMO:
            return data.responseObject.depositData.venmoUsername;
          case PaymentPlatform.REVOLUT:
            return data.responseObject.depositData.revolutUsername;
          case PaymentPlatform.CASHAPP:
            return data.responseObject.depositData.cashtag;
          case PaymentPlatform.WISE:
            return data.responseObject.depositData.wisetag;
          default:
            return '';
        }
      };

      const tgUsername = data.responseObject.depositData.telegramUsername;
      if (tgUsername) {
        setDepositorTgUsername(tgUsername);
      } else {
        setDepositorTgUsername('');
      }

      setRawPayeeDetails(extractRawPayeeDetails(data));
      setIsFetchingRawPayeeDetails(false);
    }
  }, [data, currentPlatform]);

  const initiatePayeeDetailsFetch = (hashedOnchainId: string, platform: PaymentPlatformType) => {
    setCurrentHashedOnchainId(hashedOnchainId);
    setCurrentPlatform(platform);
    setShouldFetchPayeeDetails(true);
  };

  return (
    <BackendContext.Provider
      value={{
        rawPayeeDetails,
        depositorTgUsername,
        initiatePayeeDetailsFetch,
        isFetchingRawPayeeDetails,
      }}
    >
      {children}
    </BackendContext.Provider>
  );
};

export default BackendProvider;