import { BigNumber } from 'ethers';
import { usdcUnits } from '@helpers/units';
import usdcTokenSvg from '../../assets/images/tokens/usdc.svg';
import { default as solTokenSvg } from '../../assets/images/solana.svg';
import { default as ethSvg } from '../../assets/images/eth.svg';
import { default as cbbtcSvg } from '../../assets/images/tokens/cbbtc.webp';
import { default as baseSvg } from '../../assets/images/base.svg';
import solanaSvg from '../../assets/images/solana-sol-logo.svg';
import trumpSvg from '../../assets/images/tokens/official_trump.webp';
import usdtTokenSvg from '../../assets/images/tokens/tether.webp';
import polygonSvg from '../../assets/images/tokens/polygon.webp';

import { ETH_CHAIN_ID, POLYGON_CHAIN_ID, ZERO } from '@helpers/constants';

import { BASE_CHAIN_ID, SOLANA_CHAIN_ID } from '@helpers/constants';


export const Token = {
  // Base tokens
  USDC: "USDC",
  ETH: "Base ETH",
  CBBTC: "CBBTC",
  // Solana tokens
  SOL: "SOL",
  SOL_USDC: "USDC (Solana)",
  TRUMP: "TRUMP",
  // Ethereum tokens
  MAINNET_ETH: "ETH",
  MAINNET_USDC: "USDC (Ethereum)",
  MAINNET_USDT: "USDT (Ethereum)",

  // Polygon tokens
  POLYGON: "Polygon",
  POLYGON_USDC: "USDC (Polygon)",
  POLYGON_USDT: "USDT (Polygon)"
};

export type TokenType = typeof Token[keyof typeof Token];

function getTokens(): string[] {
  let tokens = [
    // Base tokens
    Token.USDC,
    Token.ETH,
    Token.CBBTC,
    // Solana tokens
    Token.SOL,
    Token.SOL_USDC,
    Token.TRUMP,
    // Ethereum tokens
    Token.MAINNET_ETH,
    Token.MAINNET_USDC,
    Token.MAINNET_USDT,
    // Polygon tokens
    Token.POLYGON,
    Token.POLYGON_USDC,
    Token.POLYGON_USDT
  ];

  return tokens;
};

export const tokens = getTokens();

export interface TokenData {
  token: TokenType;
  name: string;
  tokenDecimals: number;
  ticker: string;
  icon: string;
  minimumDepositAmount: BigNumber;
  chainName: string;
  networkChainId: number;
  chainIcon: string;
  address: string;
  decimalsToDisplay: number;
  isNative: boolean;
  depositAllowed: boolean;
}

export const tokenInfo: Record<TokenType, TokenData> = {
  // Base tokens
  [Token.USDC]: {
    token: Token.USDC,
    name: "USD Coin",
    tokenDecimals: 6,
    ticker: "USDC",
    icon: usdcTokenSvg,
    minimumDepositAmount: usdcUnits(0.1),
    networkChainId: BASE_CHAIN_ID,
    chainName: "Base",
    chainIcon: baseSvg,
    address: "0x833589fcd6edb6e08f4c7c32d4f71b54bda02913",
    decimalsToDisplay: 2,
    isNative: true,
    depositAllowed: true
  },
  [Token.ETH]: {
    token: Token.ETH,
    name: "Ethereum",
    tokenDecimals: 18,
    ticker: "ETH",
    icon: ethSvg,
    minimumDepositAmount: ZERO, // unused
    networkChainId: BASE_CHAIN_ID,
    chainName: "Base",
    chainIcon: baseSvg,
    address: "0x0000000000000000000000000000000000000000",
    decimalsToDisplay: 6,
    isNative: true,
    depositAllowed: false
  },
  [Token.CBBTC]: {
    token: Token.CBBTC,
    name: "Coinbase Wrapped BTC",
    tokenDecimals: 8,
    ticker: "CBBTC",
    icon: cbbtcSvg,
    minimumDepositAmount: ZERO, // unused
    networkChainId: BASE_CHAIN_ID,
    chainName: "Base",
    chainIcon: baseSvg,
    address: "0xcbb7c0000ab88b473b1f5afd9ef808440eed33bf",
    decimalsToDisplay: 6,
    isNative: true,
    depositAllowed: false
  },

  // Solana tokens
  [Token.SOL]: {
    token: Token.SOL,
    name: "Solana",
    tokenDecimals: 9,
    ticker: "SOL",
    icon: solTokenSvg,
    minimumDepositAmount: ZERO, // unused
    networkChainId: SOLANA_CHAIN_ID,
    chainName: "Solana",
    chainIcon: solanaSvg,
    address: "11111111111111111111111111111111",
    decimalsToDisplay: 6,
    isNative: false,
    depositAllowed: false
  },
  [Token.SOL_USDC]: {
    token: Token.SOL_USDC,
    name: "USDC",
    tokenDecimals: 6,
    ticker: "USDC",
    icon: usdcTokenSvg,
    minimumDepositAmount: usdcUnits(0.1),
    networkChainId: SOLANA_CHAIN_ID,
    chainName: "Solana",
    chainIcon: solanaSvg,
    address: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    decimalsToDisplay: 2,
    isNative: false,
    depositAllowed: false
  },
  [Token.TRUMP]: {
    token: Token.TRUMP,
    name: "Official Trump",
    tokenDecimals: 6,
    ticker: "TRUMP",
    icon: trumpSvg,
    minimumDepositAmount: ZERO, // unused
    networkChainId: SOLANA_CHAIN_ID,
    chainName: "Solana",
    chainIcon: solanaSvg,
    address: "6p6xgHyF7AeE6TZkSmFsko444wqoP15icUSqi2jfGiPN",
    decimalsToDisplay: 8,
    isNative: false,
    depositAllowed: false
  },

  // Ethereum tokens
  [Token.MAINNET_ETH]: {
    token: Token.MAINNET_ETH,
    name: "Ethereum",
    tokenDecimals: 18,
    ticker: "ETH",
    icon: ethSvg,
    minimumDepositAmount: ZERO, // unused
    networkChainId: ETH_CHAIN_ID,
    chainName: "Ethereum",
    chainIcon: ethSvg,
    address: "0x0000000000000000000000000000000000000000",
    decimalsToDisplay: 6,
    isNative: false,
    depositAllowed: false
  },
  [Token.MAINNET_USDC]: {
    token: Token.MAINNET_USDC,
    name: "USD Coin",
    tokenDecimals: 6,
    ticker: "USDC",
    icon: usdcTokenSvg,
    minimumDepositAmount: usdcUnits(0.1),
    networkChainId: ETH_CHAIN_ID,
    chainName: "Ethereum",
    chainIcon: ethSvg,
    address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    decimalsToDisplay: 2,
    isNative: false,
    depositAllowed: false
  },
  [Token.MAINNET_USDT]: {
    token: Token.MAINNET_USDT,
    name: "Tether USD",
    tokenDecimals: 6,
    ticker: "USDT",
    icon: usdtTokenSvg,
    minimumDepositAmount: usdcUnits(0.1),   // same 6 decimals as USDC
    networkChainId: ETH_CHAIN_ID,
    chainName: "Ethereum",
    chainIcon: ethSvg,
    address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    decimalsToDisplay: 2,
    isNative: false,
    depositAllowed: false
  },

  // Polygon tokens
  [Token.POLYGON]: {
    token: Token.POLYGON,
    name: "Polygon",
    tokenDecimals: 18,
    ticker: "POL",
    icon: polygonSvg,
    minimumDepositAmount: ZERO, // unused
    networkChainId: POLYGON_CHAIN_ID,
    chainName: "Polygon",
    chainIcon: polygonSvg,
    address: "0x0000000000000000000000000000000000000000",
    decimalsToDisplay: 4,
    isNative: false,
    depositAllowed: false
  },
  [Token.POLYGON_USDC]: {
    token: Token.POLYGON_USDC,
    name: "USD Coin",
    tokenDecimals: 6,
    ticker: "USDC",
    icon: usdcTokenSvg,
    minimumDepositAmount: usdcUnits(0.1),
    networkChainId: POLYGON_CHAIN_ID,
    chainName: "Polygon",
    chainIcon: polygonSvg,
    address: "0x3c499c542cef5e3811e1192ce70d8cc03d5c3359",
    decimalsToDisplay: 2,
    isNative: false,
    depositAllowed: false
  },
  [Token.POLYGON_USDT]: {
    token: Token.POLYGON_USDT,
    name: "Tether USD (PoS)",
    tokenDecimals: 6,
    ticker: "USDT",
    icon: usdtTokenSvg,
    minimumDepositAmount: usdcUnits(0.1),
    networkChainId: POLYGON_CHAIN_ID,
    chainName: "Polygon",
    chainIcon: polygonSvg,
    address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    decimalsToDisplay: 2,
    isNative: false,
    depositAllowed: false
  }
};


