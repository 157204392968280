import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { ChevronDown } from 'react-feather';
import { ENSName, AddressDisplayEnum } from 'react-ens-name';
import { colors } from '@theme/colors';

import { DetailsItem } from '@components/Swap/SendPayment/DetailsItem';
import { alchemyMainnetEthersProvider } from 'index';
import { paymentPlatformInfo, Token, currencyInfo, tokenInfo } from '@helpers/types';
import { tokenUnits } from '@helpers/units';
import Link from '@mui/material/Link';

import useSmartContracts from '@hooks/contexts/useSmartContracts';
import useBackend from '@hooks/contexts/useBackend';
import useQuery from '@hooks/useQuery';
import { ETH_CHAIN_ID, SOLANA_CHAIN_ID, POLYGON_CHAIN_ID } from '@helpers/constants';

interface DetailsProps {
  intentData: {
    paymentPlatform: string;
    depositorOnchainPayeeDetails: string;
    receiveToken: string;
    amountTokenToReceive: string;
    sendCurrency: string;
    amountFiatToSend: string;
    expirationTimestamp: string;
    recipientAddress: string;
  };
};

export const Details: React.FC<DetailsProps> = ({
  intentData,
}) => {

  /*
   * Context
   */

  const { blockscanUrl, solanaBlockscanUrl, ethBlockscanUrl, polygonBlockscanUrl } = useSmartContracts();
  const { rawPayeeDetails, depositorTgUsername } = useBackend();
  const { queryParams } = useQuery();
  /*
   * State
   */

  const [isOpen, setIsOpen] = useState(false);


  /*
   * Helpers
   */

  const getReceiveTokenLabel = () => {
    if (!intentData) return '';

    // Only if all the query params are present
    if (
      queryParams.TO_TOKEN && 
      queryParams.INDICATIVE_TOKEN_AMOUNT
    ) {
      return `~${queryParams.INDICATIVE_TOKEN_AMOUNT} ${queryParams.TO_TOKEN}`
    }

    return `${intentData.amountTokenToReceive} ${intentData.receiveToken}`
  }

  /*
   * Component
   */

  return (
    <Container>
      <TitleLabelAndDropdownIconContainer $isOpen={isOpen}>
        {rawPayeeDetails ? (
          <TitleLabel>
            {`Send ${currencyInfo[intentData.sendCurrency].currencySymbol}${intentData.amountFiatToSend} to ${rawPayeeDetails}`}
          </TitleLabel>
        ) : (
          <TitleLabel>
            <LoadingRectangle />
          </TitleLabel>
        )}
        
        <StyledChevronDown
          onClick={() => setIsOpen(!isOpen)}
          $isOpen={isOpen}
        />
      </TitleLabelAndDropdownIconContainer>

      <DetailsDropdown $isOpen={isOpen}>
        <PaymentDetailsListContainer>
          {intentData && (
            <>
              {/* <DetailsItem 
                label={"Send Amount"}
                value={`${intentData.amountFiatToSend} ${intentData.sendCurrency}`}
              />
              
              <DetailsItem 
                label={"Send to"}
                value={rawPayeeDetails}
                copyable={true}
              /> */}

              <DetailsItem
                label={"Send on"}
                value={paymentPlatformInfo[intentData.paymentPlatform].platformName}
              />

              {depositorTgUsername && (
                <DetailsItem
                  label={`Seller's Telegram`}
                  value={depositorTgUsername}
                  copyable={true}
                  labelHelperText={`If you want to contact the seller, you can use their Telegram username.`}
                />
              )}

              <DetailsItem 
                label={"Receive"}
                value={getReceiveTokenLabel()}
              />

              { (queryParams.TO_TOKEN && tokenInfo[queryParams.TO_TOKEN].networkChainId === SOLANA_CHAIN_ID && queryParams.RECIPIENT_ADDRESS) ? (
                <DetailsItem
                  label="Recipient (Solana)"
                  value={
                    <Link href={`${solanaBlockscanUrl}/address/${queryParams.RECIPIENT_ADDRESS}`} target="_blank">
                      {queryParams.RECIPIENT_ADDRESS.slice(0, 8)}
                    </Link>
                  }
                />
              ) : (queryParams.TO_TOKEN && tokenInfo[queryParams.TO_TOKEN].networkChainId === ETH_CHAIN_ID && queryParams.RECIPIENT_ADDRESS) ? (
                <DetailsItem
                  label="Recipient (Ethereum)"
                  value={
                    <Link href={`${ethBlockscanUrl}/address/${queryParams.RECIPIENT_ADDRESS}`} target="_blank">
                      <ENSName
                        provider={alchemyMainnetEthersProvider}
                        address={queryParams.RECIPIENT_ADDRESS}
                        displayType={AddressDisplayEnum.FIRST6}
                      />
                    </Link>
                  }
                />
              ) : (queryParams.TO_TOKEN && tokenInfo[queryParams.TO_TOKEN].networkChainId === POLYGON_CHAIN_ID && queryParams.RECIPIENT_ADDRESS) ? (
                <DetailsItem
                  label="Recipient (Polygon)"
                  value={
                    <Link href={`${polygonBlockscanUrl}/address/${queryParams.RECIPIENT_ADDRESS}`} target="_blank">
                      <ENSName
                        provider={alchemyMainnetEthersProvider}
                        address={queryParams.RECIPIENT_ADDRESS}
                        displayType={AddressDisplayEnum.FIRST6}
                      />
                    </Link>
                  }
                />
              ) : (
                <DetailsItem
                  label={"Recipient Address"}
                  value={
                    <Link href={`${blockscanUrl}/address/${intentData.recipientAddress}`} target="_blank">
                      <ENSName
                        provider={alchemyMainnetEthersProvider}
                        address={intentData.recipientAddress}
                        displayType={AddressDisplayEnum.FIRST6}
                      />
                    </Link>
                  }
                />
              )}

              <DetailsItem
                label={"Expires At"}
                value={intentData.expirationTimestamp}
              />
            </>
          )}
        </PaymentDetailsListContainer>
      </DetailsDropdown>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: ${colors.container};
  overflow: hidden;
  width: 100%;
`;

const TitleLabelAndDropdownIconContainer = styled.div<{ $isOpen: boolean }>`
  min-height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;  
  width: 90%;
  border-bottom: ${({ $isOpen }) => $isOpen ? `1px solid ${colors.defaultBorderColor}` : 'none'};
  position: relative;
`;

const LoadingRectangle = styled.div`
  width: 100%;
  height: 24px;
  background: ${colors.defaultBorderColor};
  border-radius: 4px;
  animation: pulse 1.5s infinite;
  align-self: center;    

  @keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 0.6;
    }
  }
`;

const TitleLabel = styled.div`
  flex: 1;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  padding: 0 20px;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

interface StyledChevronDownProps {
  $isOpen?: boolean;
};

const StyledChevronDown = styled(ChevronDown)<StyledChevronDownProps>`
  position: absolute;
  right: 15px;
  width: 20px;
  height: 20px;
  color: ${colors.darkText};
  cursor: pointer;
  transition: transform 0.4s;
  transform: ${({ $isOpen }) => $isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

interface DetailsDropdownProps {
  $isOpen?: boolean;
};

const DetailsDropdown = styled.div<DetailsDropdownProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${colors.container};
  color: ${colors.darkText};
  align-items: center;
  overflow: hidden;

  max-height: ${({ $isOpen }) => $isOpen ? '500px' : '0px'};
  transition: max-height 0.4s ease-out;
`;

const PaymentDetailsListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 0;
`;
