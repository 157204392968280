import { useCallback, useEffect, useRef, useState } from 'react'
import { Execute, getClient, GetPriceParameters, GetQuoteParameters, ProgressData } from '@reservoir0x/relay-sdk'
import { useWalletClient } from 'wagmi'
import { TokenType } from '@helpers/types';

export type ParsedQuoteData = {
  token: TokenType;
  inAmountUsdcFormatted: string;
  outAmountFormatted: string;
  outAmountInUsd: string;
  recipientAddress: string;
  totalGasFeesInUsd: string;
  zkp2pFeeInUsd: string;
  relayerFeeInUsd: string;
  serviceTimeSeconds: number;
  totalFeesInUsd: string;
}

export const parseExecuteQuoteResponse = (quoteRes: Execute, to_token: TokenType): ParsedQuoteData => {
  const inAmount = quoteRes.details?.currencyIn?.amountFormatted;
  const outAmount = quoteRes.details?.currencyOut?.amountFormatted;
  const outAmountInUsd = quoteRes.details?.currencyOut?.amountUsd;
  const serviceTimeSeconds = quoteRes.details?.timeEstimate ?? 0;
  const totalGasFeesInUsd = quoteRes.fees?.gas?.amountUsd ?? '0';
  const zkp2pFeeInUsd = quoteRes.fees?.app?.amountUsd ?? '0';
  const relayerFeeInUsd = quoteRes.fees?.relayer?.amountUsd ?? '0';

  return {
    token: to_token,
    inAmountUsdcFormatted: inAmount,
    outAmountFormatted: outAmount,
    outAmountInUsd: outAmountInUsd,
    recipientAddress: quoteRes.details?.recipient ?? '',
    totalGasFeesInUsd,
    zkp2pFeeInUsd,
    relayerFeeInUsd,
    serviceTimeSeconds,
  } as ParsedQuoteData;
}

type OnProgressCallback = (progress: ProgressData) => void;

export default function useRelayBridge() {
  const { data: walletClient } = useWalletClient();

  /*
   * State
   */
  const onProgressRef = useRef<OnProgressCallback | undefined>(undefined);

  /*
   * Effect
   */
  const getRelayPrice = useCallback(
    async (params: GetPriceParameters) => {
      try {
        const client = getClient();
        if (!client) throw new Error('Not initialized')

        const request: GetPriceParameters = {
          user: params.user,
          recipient: params.recipient,
          originChainId: params.originChainId,
          destinationChainId: params.destinationChainId,
          amount: params.amount,
          originCurrency: params.originCurrency,
          destinationCurrency: params.destinationCurrency,
          tradeType: params.tradeType,
          options: {
            appFees: [{
              recipient: '0x0bC26FF515411396DD588Abd6Ef6846E04470227', // ZKP2P SAFE
              fee: "0" // 0 bps
            }]
          }
        }

        const price = await client.actions.getPrice(request);
        return price
      } catch (err) {
        console.error('Failed to fetch Relay price via SDK:', err)
        throw err
      }
    },
    []
  )

  const getRelayQuote = useCallback(
    async (params: GetQuoteParameters) => {
      try {
        const client = getClient();
        if (!client) throw new Error('Not initialized');

        const request: GetQuoteParameters = {
          wallet: walletClient,
          chainId: params.chainId,
          toChainId: params.toChainId,
          amount: params.amount,
          currency: params.currency,
          toCurrency: params.toCurrency,
          tradeType: params.tradeType,
          recipient: params.recipient,
          options: {
            referrer: 'zkp2p.xyz',
            // @ts-ignore
            userOperationGasOverhead: 300000,
            appFees: [{
              recipient: '0x0bC26FF515411396DD588Abd6Ef6846E04470227', // ZKP2P SAFE
              fee: "0" // 0 bps
            }]
          }
        }

        const quote = await client.actions.getQuote(request);
        return quote;
      } catch (err) {
        console.error('Failed to fetch Relay quote via SDK:', err)
        throw err
      }
    },
    [walletClient]
  )

  const executeRelayQuote = useCallback(
    async (
      quote: Execute,
      onProgress?: OnProgressCallback
    ) => {
      onProgressRef.current = onProgress;

      const client = getClient();
      return client.actions.execute({
        quote,
        wallet: walletClient,
        onProgress: (progress) => {
          onProgressRef.current?.(progress);
          onProgress?.(progress);
        },
      });
    },
    [walletClient]
  );

  return {
    getRelayPrice,
    getRelayQuote,
    executeRelayQuote,
  }
}