import { zeroAddress } from 'viem';
import * as viemChains from 'viem/chains';
import { ASSETS_RELAY_API } from '../constants/servers.js';
const viemChainMap = Object.values(viemChains).reduce((chains, chain) => {
    chains[chain.id] = chain;
    return chains;
}, {});
export const configureViemChain = (chain) => {
    let viemChain;
    const staticChain = viemChainMap[chain.id];
    if (staticChain) {
        viemChain = staticChain;
    }
    else {
        viemChain = {
            id: chain.id,
            name: chain.displayName,
            nativeCurrency: {
                name: chain.currency.name ?? 'Ethereum',
                decimals: chain.currency.decimals ?? 18,
                symbol: chain.currency.symbol ?? 'ETH'
            },
            rpcUrls: {
                default: {
                    http: [chain.httpRpcUrl],
                    webSocket: [chain.wsRpcUrl]
                },
                public: {
                    http: [chain.httpRpcUrl],
                    webSocket: [chain.wsRpcUrl]
                }
            },
            blockExplorers: {
                etherscan: {
                    name: chain.explorerName,
                    url: chain.explorerUrl
                },
                default: {
                    name: chain.explorerName,
                    url: chain.explorerUrl
                }
            }
        };
    }
    return {
        ...chain,
        viemChain,
        icon: {
            dark: chain.iconUrl ?? `${ASSETS_RELAY_API}/icons/${chain.id}/dark.png`,
            light: chain.iconUrl ?? `${ASSETS_RELAY_API}/icons/${chain.id}/light.png`,
            squaredDark: chain.iconUrl ??
                `${ASSETS_RELAY_API}/icons/square/${chain.id}/dark.png`,
            squaredLight: chain.iconUrl ??
                `${ASSETS_RELAY_API}/icons/square/${chain.id}/light.png`
        }
    };
};
export const convertViemChainToRelayChain = (chain) => {
    return {
        id: chain.id,
        name: chain.name.replace(' ', '-'),
        displayName: chain.name,
        httpRpcUrl: chain.rpcUrls.default && chain.rpcUrls.default && chain.rpcUrls.default
            ? chain.rpcUrls.default.http[0] ?? ''
            : '',
        wsRpcUrl: chain.rpcUrls && chain.rpcUrls.default.webSocket
            ? chain.rpcUrls.default.webSocket[0] ?? ''
            : '',
        icon: {
            dark: `${ASSETS_RELAY_API}/icons/${chain.id}/dark.png`,
            light: `${ASSETS_RELAY_API}/icons/${chain.id}/light.png`,
            squaredDark: `${ASSETS_RELAY_API}/icons/square/${chain.id}/dark.png`,
            squaredLight: `${ASSETS_RELAY_API}/icons/square/${chain.id}/light.png`
        },
        currency: {
            address: zeroAddress,
            ...chain.nativeCurrency
        },
        explorerUrl: chain.blockExplorers?.default.url ?? '',
        vmType: 'evm',
        depositEnabled: true,
        viemChain: chain
    };
};
