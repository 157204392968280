import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { Inbox, Lock } from 'react-feather';
import Link from '@mui/material/Link';
import { colors } from '@theme/colors';
import { ThemedText } from '@theme/text';

import { Currency, ExtensionRequestMetadata, currencyInfo } from '@helpers/types';
import { ValidatePaymentStatus } from '@helpers/types';
import { tokenUnits } from '@helpers/units';
import { calculateFiatFromRequestedUSDC } from '@helpers/intentHelper';
import { getCurrencyInfoFromHash } from '@helpers/types';

import { Button } from '@components/common/Button'
import { InstallExtension } from './InstallExtension';
import { PaymentRow } from '@components/Swap/CompleteOrder/PaymentRow';
import { paymentPlatformInfo, PaymentPlatformType, PaymentPlatform } from '@helpers/types/paymentPlatform';
import { getRandomFunnyRestrictionsMessage } from '@helpers/funnyMessages';
import useTableScroll from '@hooks/useTableScroll';
import useBackend from '@hooks/contexts/useBackend';
import { formatDateTime } from '@helpers/dateFormat';
import useOnRamperIntents from '@hooks/contexts/useOnRamperIntents';
import useSmartContracts from '@hooks/contexts/useSmartContracts';
import useExtensionProxyProofs from '@hooks/contexts/useExtensionProxyProofs';
import { AccessoryButton } from '@components/common/AccessoryButton';
import { ConsentInstructions } from './ConsentInstructions';
import { isVersionOutdated } from '@helpers/sidebar';
import { BreadcrumbStep } from '@components/common/Breadcrumb';


const EXPIRY_AND_PROOF_GEN_BUFFER = 1000 * 30; // 30 seconds

interface PaymentTableProps {
  paymentPlatform: PaymentPlatformType;
  setSelectedPayment: (payment: ExtensionRequestMetadata) => void;
  handleVerifyPaymentClicked: () => void;
  isProofModalOpen: boolean;
  handleUseReclaimFlowClick: () => void;
  showUseReclaimFlow: boolean;
  setTitle: (title: string) => void;
  setBreadcrumbStep: (step: BreadcrumbStep) => void;
};
  
export const PaymentTable: React.FC<PaymentTableProps> = ({
  paymentPlatform,
  setSelectedPayment,
  handleVerifyPaymentClicked,
  isProofModalOpen,
  showUseReclaimFlow,
  handleUseReclaimFlowClick,
  setTitle,
  setBreadcrumbStep
}) => {

  PaymentTable.displayName = "PaymentTable";

  /*
   * Context
   */

  const { currentIntentView } = useOnRamperIntents();
  const { usdcAddress } = useSmartContracts();
  const { rawPayeeDetails } = useBackend();
  const {
    openNewTab,
    isSidebarInstalled,
    isSidebarNeedsUpdate,
    venmoMetadata,
    venmoMetadataExpiresAt,
    revolutMetadata,
    revolutMetadataExpiresAt,
    cashappMetadata,
    cashappMetadataExpiresAt,
    wiseMetadata,
    wiseMetadataExpiresAt,
  } = useExtensionProxyProofs();
  const { tableRef, isScrolling } = useTableScroll();
  
  /*
   * State
   */

  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [isPlatformAuthenticated, setIsPlatformAuthenticated] = useState<boolean>(false);

  const [paymentsMetadata, setPaymentsMetadata] = useState<ExtensionRequestMetadata[] | null>(null);
  const [paymentsMetadataExpiresAt, setPaymentsMetadataExpiresAt] = useState<number | null>(null);
  const [arePaymentsExpired, setArePaymentsExpired] = useState<boolean>(false);

  const [isScrollEnabled, setIsScrollEnabled] = useState(false);
  
  const [ctaButtonTitle, setCtaButtonTitle] = useState<string>('');
  const [ctaButtonDisabled, setCtaButtonDisabled] = useState<boolean>(false);

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  
  const [validatePaymentStatus, setValidatePaymentStatus] = useState<string>(ValidatePaymentStatus.DEFAULT);

  const [triggerPaymentValidation, setTriggerPaymentValidation] = useState<number>(0);


  /*
   * Hooks
   */

  useEffect(() => {
    if (!isSidebarInstalled) {
      setTitle('Install PeerAuth Extension');
      setBreadcrumbStep(BreadcrumbStep.EXTENSION);
      return;
    } 

    if (isSidebarNeedsUpdate) {
      setTitle('Update Extension');
      setBreadcrumbStep(BreadcrumbStep.EXTENSION);
      return;
    }

    if (!isPlatformAuthenticated) {
      setTitle(`Sign in with ${platformName}`);
      setBreadcrumbStep(BreadcrumbStep.AUTHENTICATE);
    } else {
      setTitle(`Verify Payment`);
      setBreadcrumbStep(BreadcrumbStep.VERIFY);
    }
  }, [isSidebarInstalled, isSidebarNeedsUpdate]);

  useEffect(() => {
    switch (paymentPlatform) {
      case PaymentPlatform.VENMO: 
        setPaymentsMetadata(venmoMetadata);
        setPaymentsMetadataExpiresAt(venmoMetadataExpiresAt);
        break;
      case PaymentPlatform.REVOLUT:
        setPaymentsMetadata(revolutMetadata);
        setPaymentsMetadataExpiresAt(revolutMetadataExpiresAt);
        break;
      case PaymentPlatform.CASHAPP:
        setPaymentsMetadata(cashappMetadata);
        setPaymentsMetadataExpiresAt(cashappMetadataExpiresAt);
        break;
      case PaymentPlatform.WISE:
        setPaymentsMetadata(wiseMetadata);
        setPaymentsMetadataExpiresAt(wiseMetadataExpiresAt);
        break;
    }

    setIsRefreshing(false);
    setArePaymentsExpired(false);
    setValidatePaymentStatus(ValidatePaymentStatus.DEFAULT);

    if (paymentsMetadata && paymentsMetadata.length > 0) {
      setSelectedPayment(paymentsMetadata[0]);
      setSelectedIndex(0);
      setTriggerPaymentValidation(triggerPaymentValidation + 1);
    }
  }, [
    paymentPlatform, 
    venmoMetadata, 
    revolutMetadata, 
    cashappMetadata, 
    wiseMetadata,
    venmoMetadataExpiresAt, 
    revolutMetadataExpiresAt, 
    cashappMetadataExpiresAt,
    wiseMetadataExpiresAt
  ]);

  useEffect(() => {
    if (paymentsMetadata !== null && !isPlatformAuthenticated) {
      setIsAuthenticating(false);
      setIsPlatformAuthenticated(true);

      setTitle(`Verify Payment`);
      setBreadcrumbStep(BreadcrumbStep.VERIFY);
    }
  }, [paymentsMetadata, isPlatformAuthenticated]);

  useEffect(() => {
    const lastPlatform = localStorage.getItem('lastPaymentPlatform');
    
    if (lastPlatform !== paymentPlatform) {
      console.log('paymentPlatform changed', paymentPlatform);
      
      setPaymentsMetadata(null);
      setIsPlatformAuthenticated(false);
      setIsAuthenticating(false);
      
      localStorage.setItem('lastPaymentPlatform', paymentPlatform);
    }
  }, [paymentPlatform, setPaymentsMetadata]);

  useEffect(() => {
    const tableElement = tableRef.current;
    if (tableElement) {
      setIsScrollEnabled(tableElement.scrollHeight > tableElement.clientHeight);
    }
  }, [paymentsMetadata, tableRef]);

  useEffect(() => {
    if (selectedIndex === null || currentIntentView === null) {
      return;
    }

    const selectedPayment = paymentsMetadata?.[selectedIndex];
    if (selectedPayment === undefined) {
      return;
    }

    const tokenDecimals = currentIntentView.deposit.deposit.token == usdcAddress ? 6 : 18;
    let parsedPaymentAmount = '';
    let parsedPaymentDate = '';
    let parsedPaymentRecipientId = '';
    let parsedPaymentCurrency = '';
    
    switch (paymentPlatform) {
      case PaymentPlatform.VENMO:
        parsedPaymentAmount = selectedPayment.amount?.replace(/[^0-9.]/g, '') ?? '';
        
        parsedPaymentDate = `${selectedPayment.date ?? ''}Z`;
        parsedPaymentRecipientId = selectedPayment.recipient ?? '';
        parsedPaymentCurrency = Currency.USD;
        break;
      case PaymentPlatform.REVOLUT:
        parsedPaymentAmount = selectedPayment.amount ?? '';
        parsedPaymentDate = selectedPayment.date ?? '';
        parsedPaymentRecipientId = selectedPayment.recipient ?? '';
        parsedPaymentCurrency = selectedPayment.currency ?? '';
        break;
      case PaymentPlatform.CASHAPP:
        parsedPaymentAmount = selectedPayment.amount ?? '';
        parsedPaymentDate = selectedPayment.date ?? '';
        parsedPaymentRecipientId = selectedPayment.recipient ?? '';
        parsedPaymentCurrency = selectedPayment.currency ?? '';
        break;
      case PaymentPlatform.WISE:
        parsedPaymentAmount = selectedPayment.amount ?? '';
        parsedPaymentDate = selectedPayment.date ?? '';

        // "<strong>Richard Liang</strong>"
        parsedPaymentRecipientId = selectedPayment.recipient?.replace(/<[^>]*>?/gm, '') ?? '';
        parsedPaymentCurrency = selectedPayment.currency ?? '';
        break;
      default:
        return;
    }

    // const requiredPaymentAmount = calculateFiatFromRequestedUSDC(
    //   currentIntentView.intent.amount, 
    //   currentIntentView.intent.conversionRate, 
    //   tokenDecimals
    // );
  
    // const paymentAmount = tokenUnits(parsedPaymentAmount, tokenDecimals);
    // if (paymentAmount.lt(requiredPaymentAmount)) {
    //   console.log('paymentAmount < requiredPaymentAmount', paymentAmount.toString(), requiredPaymentAmount.toString());
    //   setValidatePaymentStatus(ValidatePaymentStatus.AMOUNT_TOO_LOW);
    //   return;
    // }
    
    // const paymentTimestamp = new Date(parsedPaymentDate).getTime();
    // const intentTimestamp = Number(currentIntentView.intent.timestamp) * 1000; // Convert to milliseconds
    // if (paymentTimestamp < intentTimestamp + 30000) {   // allow buffer of 30 seconds
    //   console.log('paymentTimestamp < intentTimestamp', paymentTimestamp, intentTimestamp);
    //   setValidatePaymentStatus(ValidatePaymentStatus.BEFORE_INTENT);
    //   return;
    // }

    // if (parsedPaymentRecipientId !== rawPayeeDetails) {
    //   console.log('parsedPaymentRecipientId !== rawPayeeDetails', parsedPaymentRecipientId, rawPayeeDetails);
    //   setValidatePaymentStatus(ValidatePaymentStatus.INVALID_RECIPIENT);
    //   return;
    // }

    // const requiredCurrency = getCurrencyInfoFromHash(currentIntentView.intent.fiatCurrency)?.currency;
    // if (parsedPaymentCurrency !== requiredCurrency) {
    //   console.log('parsedPaymentCurrency !== requiredCurrency', parsedPaymentCurrency, requiredCurrency);
    //   setValidatePaymentStatus(ValidatePaymentStatus.INVALID_CURRENCY);
    //   return;
    // }

    setValidatePaymentStatus(ValidatePaymentStatus.VALID);
  }, [selectedIndex, currentIntentView, triggerPaymentValidation]);


  useEffect(() => {
    switch (validatePaymentStatus) {
      case ValidatePaymentStatus.DEFAULT:
        setCtaButtonTitle('Select Payment');
        break;
      
      case ValidatePaymentStatus.PAYMENTS_EXPIRED:
        setCtaButtonTitle('Please refresh to see payments');
        break;

      case ValidatePaymentStatus.AMOUNT_TOO_LOW:
        setCtaButtonTitle('Payment amount too low');
        break;

      case ValidatePaymentStatus.BEFORE_INTENT:
        setCtaButtonTitle('Payment date before intent');
        break;

      case ValidatePaymentStatus.INVALID_RECIPIENT:
        setCtaButtonTitle('Payment is not to ' + rawPayeeDetails);
        break;
      
      case ValidatePaymentStatus.INVALID_CURRENCY:
        setCtaButtonTitle('Payment currency invalid');
        break;

      case ValidatePaymentStatus.VALID:
        if (isProofModalOpen) {
          setCtaButtonTitle('Verifying Payment');
        } else {
          setCtaButtonTitle('Verify Payment');
        }
        break;
    }
  }, [isProofModalOpen, validatePaymentStatus]);

  useEffect(() => {
    switch (validatePaymentStatus) {
      case ValidatePaymentStatus.DEFAULT:
      case ValidatePaymentStatus.AMOUNT_TOO_LOW:
      case ValidatePaymentStatus.BEFORE_INTENT:
      case ValidatePaymentStatus.INVALID_RECIPIENT:
      case ValidatePaymentStatus.INVALID_CURRENCY:
      case ValidatePaymentStatus.PAYMENTS_EXPIRED:
        setCtaButtonDisabled(true);
        break;

      case ValidatePaymentStatus.VALID:
        if (isProofModalOpen) {
          setCtaButtonDisabled(true);
        } else {
          setCtaButtonDisabled(false);
        }
        break;
    }
  }, [validatePaymentStatus, isProofModalOpen]);

  useEffect(() => {
    const checkExpiration = () => {
      // Expire payments before such that if they are chosen for proof generation, they don't expire while proof is being generated
      if (
        !isRefreshing 
        && paymentsMetadataExpiresAt 
        && new Date(paymentsMetadataExpiresAt).getTime() < Date.now() + EXPIRY_AND_PROOF_GEN_BUFFER
      ) {
        setValidatePaymentStatus(ValidatePaymentStatus.PAYMENTS_EXPIRED);
      }
    };

    const interval = setInterval(checkExpiration, 1000); // Check every second

    return () => clearInterval(interval); // Cleanup on unmount
  }, [paymentsMetadataExpiresAt, isRefreshing]);


  /*
   * Handlers
   */

  const handleAuthClicked = () => {
    setIsAuthenticating(true);
    openNewTab(paymentPlatformInfo[paymentPlatform].authLink);  
  }

  const handleRefreshClicked = () => {
    setIsRefreshing(true);
    openNewTab(paymentPlatformInfo[paymentPlatform].authLink);  
  }

  const handlePaymentRowClicked = (metadata: ExtensionRequestMetadata, index: number) => {
    if (validatePaymentStatus === ValidatePaymentStatus.PAYMENTS_EXPIRED) {
      return;
    }

    setSelectedPayment(metadata);
    setSelectedIndex(index);
  };

  /*
   * Helpers
   */

  const getSubjectText = (metadata: ExtensionRequestMetadata) => {
    switch (paymentPlatform) {
      case PaymentPlatform.VENMO:
        return `Sent ${metadata.amount} to ${metadata.recipient}`;
      case PaymentPlatform.REVOLUT:
        const currencySymbol = currencyInfo[metadata.currency ?? '']?.currencySymbol ?? '';
        return `Sent ${currencySymbol}${metadata.amount} to ${metadata.recipient}`;
      case PaymentPlatform.CASHAPP:
        return `Sent ${metadata.amount} to ${metadata.recipient}`;
      case PaymentPlatform.WISE:
        const recipient = metadata.recipient?.replace(/<[^>]*>?/gm, '') ?? '';
        const amount = metadata.amount?.replace(/<[^>]*>?/gm, '') ?? '';
        return `Sent ${amount} to ${recipient}`;
      default:
        return `Sent ${metadata.amount} to ${metadata.recipient}`;
    }
  }

  const platformName = paymentPlatformInfo[paymentPlatform].platformName;
  const getConsentInstructions = () => {
    return [
      `Instantly verify payment to complete your order`,
      `Extract select details from your last ${paymentPlatformInfo[paymentPlatform].numPaymentsFetched} payments`,
      'Redact any sensitive information using zero-knowledge proofs',
      'Data never leaves your device'
    ];
  }
  
  const funnyRestrictionMessage = useMemo(() => getRandomFunnyRestrictionsMessage(), []);
  const getRestrictions = () => {
    return [
      'Make payments on your behalf',
      funnyRestrictionMessage
    ];
  }

  /*
   * Component
   */

  return (
    <Container>
      {
        !isSidebarInstalled || isSidebarNeedsUpdate ? (
          <InstallExtension
            handleUseReclaimFlowClick={handleUseReclaimFlowClick}
            showUseReclaimFlow={showUseReclaimFlow}
          />
        ) : (
          !isPlatformAuthenticated ? (
            <LoginContainer>
              <ConsentInstructions 
                instructionsTitle="This will allow ZKP2P to:"
                instructions={getConsentInstructions()}
                restrictionsTitle="This will NOT allow ZKP2P to:"
                restrictions={getRestrictions()}
              />

              <LoginButtonContainer>
                <Button
                  onClick={handleAuthClicked}
                  height={48}
                  width={260}
                >
                  Sign in with {platformName}
                </Button>
                {showUseReclaimFlow && 
                  <AccessoryButton
                    onClick={handleUseReclaimFlowClick}
                    title="Or Continue on Phone"
                    width={260}
                    textAlign="center"
                    borderRadius={24}
                    // icon={<Phone />}
                  />
                }
              </LoginButtonContainer>
            </LoginContainer>
          ) : (
            <LoggedInContainer>
              <TitleContainer>
                <TitleAndSubHeaderContainer>
                  <ThemedText.SubHeader textAlign="left">
                    Your {paymentPlatformInfo[paymentPlatform].platformName} Payments
                  </ThemedText.SubHeader>
                  <SubHeaderContainer>
                    <ThemedText.BodySmall textAlign="left">
                      {`Select a payment to verify`}
                    </ThemedText.BodySmall>
                  </SubHeaderContainer>
                </TitleAndSubHeaderContainer>

                <AccessoryButton
                  onClick={handleRefreshClicked}
                  height={36}
                  title={'Refresh'}
                  icon={'refresh'}
                />
              </TitleContainer>

              <TableContainer>
                {paymentsMetadata && (
                  paymentsMetadata.length === 0 ? (
                    <EmptyPaymentsContainer>
                      <StyledInbox />
                      <ThemedText.SubHeaderSmall textAlign="center" lineHeight={1.3}>
                        { 'No payments found.' }
                      </ThemedText.SubHeaderSmall>
                    </EmptyPaymentsContainer>
                  ) : (
                    <Table ref={tableRef}>
                      {paymentsMetadata.map((metadata, index) => (
                        <PaymentRow
                          key={index}
                          isFirstRow={index === 0}
                          isLastRow={index === paymentsMetadata.length - 1}
                          subjectText={
                            validatePaymentStatus === ValidatePaymentStatus.PAYMENTS_EXPIRED 
                            ? 'Please refresh to see payments' 
                            : getSubjectText(metadata)
                          }
                          dateText={
                            validatePaymentStatus === ValidatePaymentStatus.PAYMENTS_EXPIRED 
                            ? '' 
                            : formatDateTime(metadata.date ?? '', paymentPlatform)
                          }
                          isSelected={selectedIndex === index}
                          onRowClick={() => handlePaymentRowClicked(metadata, index)}
                          isScrolling={isScrolling}
                        />
                      ))}
                    </Table>
                  )
                )}
              </TableContainer>

              <ButtonContainer>
                <Button
                  disabled={ctaButtonDisabled}
                  onClick={handleVerifyPaymentClicked}
                >
                  {ctaButtonTitle}
                </Button>
              </ButtonContainer>
            </LoggedInContainer>
          )
      )
    }
    </Container>
  )
};

const Container = styled.div`
  background-color: ${colors.container};
  border-radius: 16px;
  align-items: center;
  width: 100%;
`;

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;

const ButtonContainer = styled.div`
  display: grid;
`;

const EmptyPaymentsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.9rem 0rem;
  max-width: 75%;
  margin: auto;
  gap: 1rem;
`;

const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 25vh;
  line-height: 1.3;
  gap: 1rem;
`;


const LockIcon = styled(Lock)`
  ${IconStyle}
`;

const LoggedInContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 1rem;
`;

const TitleAndSubHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

const SubHeaderContainer = styled.div`
  color: ${colors.lightGrayText};
`;

const LoginButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  min-width: 50%;
`;

const StyledInbox = styled(Inbox)`
  color: #FFF;
  width: 28px;
  height: 28px;
`;

const TableContainer = styled.div`
  border: 1px solid ${colors.defaultBorderColor};
  border-radius: 8px;
  background-color: ${colors.container};
`;


const Table = styled.div`
  max-height: 40vh;
  border-radius: 8px;

  @media (max-width: 600px) {
    max-height: 30vh;
  }

  font-size: 16px;
  color: #616161;
  overflow-y: auto;
  scrollbar-width: thin;
  
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.defaultBorderColor};
    border-radius: 4px;
  }

  & > *:last-child::after {
    display: none;
  }
`;