import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { ChevronDown } from 'react-feather';
import { ENSName, AddressDisplayEnum } from 'react-ens-name';
import { colors } from '@theme/colors';

import { DetailsItem } from '@components/Swap/SendPayment/DetailsItem';
import { alchemyMainnetEthersProvider } from 'index';
import { Token, tokenInfo, TokenType } from '@helpers/types';
import Link from '@mui/material/Link';

import useSmartContracts from '@hooks/contexts/useSmartContracts';
import { ParsedQuoteData } from '@hooks/useRelayBridge';
import { ETH_CHAIN_ID, SOLANA_CHAIN_ID, POLYGON_CHAIN_ID } from '@helpers/constants';

interface SwapDetailsProps {
  isLoading: boolean;
  quoteData: ParsedQuoteData;
  countdown: number;
};

export const SwapDetails: React.FC<SwapDetailsProps> = ({
  isLoading,
  quoteData,
  countdown,
}) => {

  /*
   * Context
   */

  const { blockscanUrl, solanaBlockscanUrl, ethBlockscanUrl, polygonBlockscanUrl } = useSmartContracts();
  /*
   * State
   */

  const [isOpen, setIsOpen] = useState(true);

  /*
   * Hooks
   */

  useEffect(() => {
    if (isLoading) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [isLoading]);

  /*
   * Helpers
   */

  const formatTokenAmount = () => {
    const numDecimalsToDisplay = tokenInfo[quoteData.token].decimalsToDisplay;
    return `${Number(quoteData.outAmountFormatted).toFixed(numDecimalsToDisplay)} ${tokenInfo[quoteData.token].name} ($${Number(quoteData.outAmountInUsd).toFixed(3)})`;
  }

  const getFeeHelperText = () => {
    return `Network fees: $${Number(quoteData.totalGasFeesInUsd).toFixed(3)}` +
    `\nZKP2P fee: $${Number(quoteData.zkp2pFeeInUsd).toFixed(3)}` +
    `\nRelayer fee: $${Number(quoteData.relayerFeeInUsd).toFixed(3)}`;
  }

  const getTotalFeesInUsd = () => {
    const totalFees = Number(quoteData.totalGasFeesInUsd) + Number(quoteData.zkp2pFeeInUsd) + Number(quoteData.relayerFeeInUsd);
    return `$${totalFees.toFixed(4)}`;
  }


  /*
   * Component
  */

  const renderRecipientAddress = () => {
    if (tokenInfo[quoteData.token].networkChainId === SOLANA_CHAIN_ID) {
      return (
        <DetailsItem
          label="Recipient Address (SOL)"
          value={
            <Link href={`${solanaBlockscanUrl}/address/${quoteData.recipientAddress}`} target="_blank">
              {quoteData.recipientAddress.slice(0, 8)}
            </Link>
          }
        />
      )
    } else if (tokenInfo[quoteData.token].networkChainId === ETH_CHAIN_ID) {
      return (
        <DetailsItem
          label="Recipient (Ethereum)"
          value={
            <Link href={`${ethBlockscanUrl}/address/${quoteData.recipientAddress}`} target="_blank">
              <ENSName
                provider={alchemyMainnetEthersProvider}
                address={quoteData.recipientAddress}
                displayType={AddressDisplayEnum.FIRST6}
              />
            </Link>
          }
        />
      )
    } else if (tokenInfo[quoteData.token].networkChainId === POLYGON_CHAIN_ID) {
      return (
        <DetailsItem
          label="Recipient (Polygon)"
          value={
            <Link href={`${polygonBlockscanUrl}/address/${quoteData.recipientAddress}`} target="_blank">
              <ENSName
                provider={alchemyMainnetEthersProvider}
                address={quoteData.recipientAddress}
                displayType={AddressDisplayEnum.FIRST6}
              />
            </Link>
          }
        />
      )
    } else {
      return (
        <DetailsItem
          label="Recipient Address"
          value={
            <Link href={`${blockscanUrl}/address/${quoteData.recipientAddress}`} target="_blank">
              <ENSName
                provider={alchemyMainnetEthersProvider}
                address={quoteData.recipientAddress}
                displayType={AddressDisplayEnum.FIRST6}
              />
            </Link>
          }
        />
      )
    }
  }

  return (
    <Container>
      <TitleLabelAndDropdownIconContainer $isOpen={isOpen}>
        <TitleLabel>
          {"Swap Details"}
        </TitleLabel>
        
        <StyledChevronDown
          onClick={() => setIsOpen(!isOpen)}
          $isOpen={isOpen}
        />
      </TitleLabelAndDropdownIconContainer>

      <DetailsDropdown $isOpen={isOpen}>
        <PaymentDetailsListContainer>
          {quoteData && (
            <>
              <DetailsItem 
                label={"Send Amount"}
                value={`${Number(quoteData.inAmountUsdcFormatted).toFixed(2)} USDC`}
              />
              
              <DetailsItem 
                label={"Receive Amount"}
                value={formatTokenAmount()}
              />

              {renderRecipientAddress()}

              <DetailsItem
                label={"Fees"}
                value={getTotalFeesInUsd()}
                helperText={getFeeHelperText()}
              />

              <DetailsItem
                label={"Arrival Time"}
                value={`${quoteData.serviceTimeSeconds}s`}
              />
              <DetailsItem
                label={"Quote Refreshes every"}
                value={`${countdown}s`}
              />
            </>
          )}
        </PaymentDetailsListContainer>
      </DetailsDropdown>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: ${colors.container};
  border: 1px solid ${colors.defaultBorderColor};
  overflow: hidden;
  width: 100%;
`;

const TitleLabelAndDropdownIconContainer = styled.div<{ $isOpen: boolean }>`
  min-height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;  
  width: 100%;
  border-bottom: ${({ $isOpen }) => $isOpen ? `1px solid ${colors.defaultBorderColor}` : 'none'};
`;

const TitleLabel = styled.div`
  font-size: 14px;
  padding: 0 20px;

  @media (max-width: 600px) {
    font-size: 13px;
  }
`;

interface StyledChevronDownProps {
  $isOpen?: boolean;
};

const StyledChevronDown = styled(ChevronDown)<StyledChevronDownProps>`
  width: 20px;
  height: 20px;
  color: ${colors.darkText};
  padding: 0 20px;

  transition: transform 0.4s;
  transform: ${({ $isOpen }) => $isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

interface DetailsDropdownProps {
  $isOpen?: boolean;
};

const DetailsDropdown = styled.div<DetailsDropdownProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${colors.container};
  color: ${colors.darkText};
  align-items: center;
  overflow: hidden;

  max-height: ${({ $isOpen }) => $isOpen ? '500px' : '0px'};
  transition: max-height 0.4s ease-out;
`;

const PaymentDetailsListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 0;
`;
