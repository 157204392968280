export const getCurrentStepData = (steps) => {
    let currentStep = null;
    let currentStepItem;
    let txHashes = [];
    for (const step of steps) {
        for (const item of step.items || []) {
            if (item.txHashes && item.txHashes.length > 0) {
                txHashes = item.txHashes.concat([...txHashes]);
            }
            if (item.internalTxHashes && item.internalTxHashes.length > 0) {
                txHashes = item.internalTxHashes.concat([...txHashes]);
            }
            if (item.status === 'incomplete') {
                currentStep = step;
                currentStepItem = item;
                break; // Exit the inner loop once the first incomplete item is found
            }
        }
        if (currentStep && currentStepItem)
            break; // Exit the outer loop if the current step and item have been found
    }
    return { currentStep, currentStepItem, txHashes };
};
