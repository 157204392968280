import { axios } from '../utils/axios.js';
import { getClient } from '../client.js';
import { zeroAddress } from 'viem';
export async function getSolverCapacity(data, axiosInstance = axios) {
    const client = getClient();
    if (!client) {
        throw new Error('Client not initialized');
    }
    // Fallback to zero address if user is not provided
    data.user = data.user || zeroAddress;
    data.currency = data.currency;
    const response = await axiosInstance.get(`${client.baseApiUrl}/config/v2`, { params: data });
    if (response.data) {
        return response.data;
    }
    throw 'No solver capacity data';
}
