import React, { useCallback, useEffect, useState } from "react";
import styled from 'styled-components';

import { AccessoryButton } from '@components/common/AccessoryButton';
import { TransactionIconButton } from '@components/common/TransactionIconButton';
import QuestionHelper from '@components/common/QuestionHelper';

import useMediaQuery from "@hooks/useMediaQuery";
import useBalances from "@hooks/contexts/useBalance";
import useDeposits from "@hooks/contexts/useDeposits";
import useWithdrawDeposit from "@hooks/transactions/useWithdrawDeposit";

import { esl } from '@helpers/constants';
import { paymentPlatformInfo, PaymentPlatformType } from "@helpers/types/paymentPlatform";
import { currencyInfo, CurrencyType } from "@helpers/types";

interface DepositRowProps {
  depositId: string;
  tokenTicker: string;
  tokenIcon: string;
  conversionRates: Map<PaymentPlatformType, Map<CurrencyType, string>>;
  originalAmount: string;
  availableLiquidity: string;
  intentAmountRange: {
    min: string;
    max: string;
  }
  isDepositActive: boolean;
  rowIndex: number;
  activeOrders: string[];
  amountLockedInIntents: string;
  handleEditRateClicked: (depositId: string, platform: PaymentPlatformType, currency: CurrencyType, currentRate: string) => void;
}


export const DepositRow: React.FC<DepositRowProps> = ({
  depositId,
  tokenTicker,
  tokenIcon,
  conversionRates,
  originalAmount,
  availableLiquidity,
  intentAmountRange,
  isDepositActive,
  rowIndex,
  activeOrders,
  amountLockedInIntents,
  handleEditRateClicked
}) => {

  DepositRow.displayName = "DepositRow";

  const isMobile = useMediaQuery() === 'mobile';

  /*
   * States
   */

  const [isWithdrawDepositLoading, setIsWithdrawDepositLoading] = useState(false);
  const [isEditRateLoading, setIsEditRateLoading] = useState(false);

  /*
   * Contexts
   */

  const { refetchUsdcBalance } = useBalances();
  const {
    refetchDepositViews
  } = useDeposits();
  
  /*
   * Transactions
   */

  const onWithdrawDepositSuccess = useCallback((data: any) => {
    esl && console.log('writeWithdrawAsync successful: ', data);

    refetchDepositViews?.();
    refetchUsdcBalance?.();

  }, [refetchDepositViews, refetchUsdcBalance]);

  const {
    writeWithdrawAsync,
    setDepositIdInput: setWithdrawDepositIdInput,
    shouldConfigureWithdrawWrite,
    setShouldConfigureWithdrawWrite,
    signWithdrawTransactionStatus,
    mineWithdrawTransactionStatus
  } = useWithdrawDeposit(onWithdrawDepositSuccess);

  /*
   * Hooks
   */

  useEffect(() => {
    if (depositId) {
      setWithdrawDepositIdInput(Number(depositId));
    }
  }, [depositId, setWithdrawDepositIdInput]);

  useEffect(() => {
    const executeWithdrawDeposit = async () => {
      esl && console.log('executeWithdrawDeposit: ', depositId, signWithdrawTransactionStatus);
      const requiredStatusForExecution = signWithdrawTransactionStatus === 'idle' 
        || signWithdrawTransactionStatus === 'error'
        || signWithdrawTransactionStatus === 'success'
      ;

      if (shouldConfigureWithdrawWrite && writeWithdrawAsync && requiredStatusForExecution) {
        try {
          esl && console.log('executeWithdrawDeposit: ', depositId);

          // Prevent multiple withdrawals from being triggered on re-render
          setShouldConfigureWithdrawWrite(false);

          await writeWithdrawAsync();
        } catch (error) {
          esl && console.log('writeWithdrawAsync failed: ', error);

          setShouldConfigureWithdrawWrite(false);
        }
      }
    };
  
    executeWithdrawDeposit();
  }, [
    shouldConfigureWithdrawWrite,
    writeWithdrawAsync,
    signWithdrawTransactionStatus,
  ]);

  useEffect(() => {
    setIsWithdrawDepositLoading(signWithdrawTransactionStatus === 'loading' || mineWithdrawTransactionStatus === 'loading');
  }, [signWithdrawTransactionStatus, mineWithdrawTransactionStatus]);

  /*
   * Handlers
   */

  const handleWithdrawClick = async () => {
    esl && console.log('handleWithdrawClick: ', depositId);

    setShouldConfigureWithdrawWrite(true);
  };

  /*
   * Helpers
   */
  const getStatusText = (): string => {
    const hasLockedFunds = parseFloat(amountLockedInIntents) > 0;
    const hasAvailableFunds = parseFloat(availableLiquidity) > 0;

    if (isDepositActive) {
      return `Deposit is active and accepting new orders. ${
        hasAvailableFunds ? 'Click on withdraw to withdraw the available amount.' : ''
      }`;
    }
    
    return `Deposit is inactive and NOT accepting new orders.${
      hasLockedFunds ? ' You can withdraw the locked amount once the active orders are cancelled, filled or expired.' : ''
    }${
      hasAvailableFunds ? ' You can withdraw the available amount.' : ''
    }`;
  };

  /*
   * Render
   */

  const renderConversionRates = () => {
    return (
      <PricesSection>
        {Array.from(conversionRates.entries()).map(([platform, currencies]) => (
          <PlatformGroup key={platform}>
            <PlatformName>{paymentPlatformInfo[platform].platformName}</PlatformName>
            <CurrenciesGroup>
              {Array.from(currencies.entries()).map(([currency, rate]) => (
                <CurrencyRow key={currency}>
                  <CurrencyCode>{currencyInfo[currency].currencyCode}</CurrencyCode>
                  <RateContainer>
                    <ConversionRate>{rate}</ConversionRate>
                    <TransactionIconButton
                      icon="edit"
                      text={`Edit ${currency} rate`}
                      loading={isEditRateLoading}
                      onClick={() => handleEditRateClicked(depositId, platform, currency, rate)}
                      size={18}
                      hasBackground={false}
                    />
                  </RateContainer>
                </CurrencyRow>
              ))}
            </CurrenciesGroup>
          </PlatformGroup>
        ))}
      </PricesSection>
    );
  };

  const renderAmounts = () => (
    <AmountsSection>
      <AmountRow>
        <AmountLabel>Deposited:</AmountLabel>
        <AmountValue>{parseFloat(originalAmount)} {tokenTicker}</AmountValue>
      </AmountRow>
      <AmountRow>
        <AmountLabelWithHelper>
          <AmountLabel>Available:</AmountLabel>
          <QuestionHelper text="Amount available for takers. This amount is NOT locked and can be withdrawn." size="sm" />
        </AmountLabelWithHelper>
        <AmountValue>{parseFloat(availableLiquidity)} {tokenTicker}</AmountValue>
      </AmountRow>
      <AmountRow>
        <AmountLabelWithHelper>
          <AmountLabel>Locked:</AmountLabel>
          <QuestionHelper text="Amount locked by active orders. This amount is locked by takers and cannot be withdrawn immediately." size="sm" />
        </AmountLabelWithHelper>
        <AmountValue>{parseFloat(amountLockedInIntents)} USDC</AmountValue>
      </AmountRow>
      <AmountRow>
        <AmountLabel>Active Orders:</AmountLabel>
        <AmountValue>{activeOrders.length}</AmountValue>
      </AmountRow>
      <AmountRow>
        <AmountLabelWithHelper>
          <AmountLabel>Order Limit:</AmountLabel>
          <QuestionHelper text="Min and Max amount for takers per order." size="sm" />
        </AmountLabelWithHelper>
        <AmountValue>{parseFloat(intentAmountRange.min)} - {parseFloat(intentAmountRange.max)} USDC</AmountValue>
      </AmountRow>
      <AmountRow>
        <AmountLabelWithHelper>
          <AmountLabel>Status:</AmountLabel>
          <QuestionHelper 
            text={getStatusText()}
            size="sm" 
          />
        </AmountLabelWithHelper>
        <AmountValue>{isDepositActive ? 'Active 🟢' : 'Inactive 🔴'}</AmountValue>
      </AmountRow>
    </AmountsSection>
  );

  const renderDesktopView = () => (
    <RowContent>
      <RowIndex>{rowIndex + 1}</RowIndex>
      
      <CoinSection>
        <TokenSvg src={tokenIcon} />
        <CoinSymbol>{tokenTicker}</CoinSymbol>
      </CoinSection>

      {renderConversionRates()}
      {renderAmounts()}

      <ActionSection>
        <AccessoryButton
          onClick={handleWithdrawClick}
          height={36}
          loading={isWithdrawDepositLoading}
          title={'Withdraw'}
          icon={'logout'}
        />
      </ActionSection>
    </RowContent>
  );

  const renderMobileView = () => (
    <MobileRowContent>
      <MobileDetailsCotainer>
        {renderConversionRates()}
        {renderAmounts()}
      </MobileDetailsCotainer>

      <ActionSection>
        <AccessoryButton
          onClick={handleWithdrawClick}
          height={36}
          loading={isWithdrawDepositLoading}
          title={'Withdraw'}
        />
      </ActionSection>
    </MobileRowContent>
  );

  return (
    <Container>
      {isMobile ? renderMobileView() : renderDesktopView()}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const RowContent = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 1fr 1.5fr 1.5fr 1fr;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  align-items: center;
  gap: 1rem;
`;

const MobileRowContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1rem 1rem 1rem;
`;

const MobileDetailsCotainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  align-items: center;
`;

const RowIndex = styled.span`
  color: #6C757D;
  font-size: 14px;
`;

const CoinSection = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const TokenSvg = styled.img`
  border-radius: 18px;
  width: 24px;
  height: 24px;
`;

const CoinSymbol = styled.span`
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
`;

const PricesSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 0;
`;

const PlatformGroup = styled.div`
  display: flex;
  gap: 0.75rem;
  padding: 8px 0;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(108, 117, 125, 0.2);
    margin-bottom: 4px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const PlatformName = styled.span`
  color: #6C757D;
  font-size: 14px;
  min-width: 70px;
`;

const CurrenciesGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const CurrencyRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 140px;

  @media (max-width: 600px) {
    min-width: 140px;
  }
`;

const CurrencyCode = styled.span`
  color: #6C757D;
  font-size: 14px;
  min-width: 30px;
`;

const RateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  min-width: 80px;
`;

const ConversionRate = styled.span`
  color: #FFFFFF;
  font-size: 14px;
  width: 40px;
  text-align: right;
`;

const AmountsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const AmountRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

const AmountValue = styled.span`
  color: #FFFFFF;
  font-size: 14px;
  text-align: right;
`;

const ActionSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AmountLabelWithHelper = styled.div`  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const AmountLabel = styled.span`
  color: #6C757D;
  font-size: 14px;
`;