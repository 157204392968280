import styled from 'styled-components/macro';

import { AutoColumn } from '@components/layouts/Column';
import { LiquidityTable } from '@components/Liquidity/LiquidityTable';

export default function Deposit() {
  /*
   * Component
   */

  return (
    <Wrapper>
      <LiquidityTable/>
    </Wrapper>
  );
};

const Wrapper = styled(AutoColumn)`
  max-width: 920px;
  width: 100%;
`;
