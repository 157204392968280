import React, { useEffect, useState, useCallback } from 'react';
import styled, { css } from 'styled-components/macro';
import { BigNumber } from 'ethers';

import { ProofGenerationStatus, ProofGenerationStatusType } from '@helpers/types';
import { parseExtensionProof } from '@helpers/types';
import { ExtensionRequestMetadata } from '@helpers/types';
import { PaymentPlatformType, PaymentPlatform, paymentPlatformInfo } from '@helpers/types/paymentPlatform';
import { BreadcrumbStep } from '@components/common/Breadcrumb';

import { Proof } from '@helpers/types';
import { ProvePayment } from '@components/modals/ProvePayment';
import { PaymentTable } from '@components/Swap/CompleteOrder/PaymentTable';

import useQuery from '@hooks/useQuery';
import useExtensionProxyProofs from '@hooks/contexts/useExtensionProxyProofs';
import { ParsedQuoteData } from '@hooks/useRelayBridge';
import { rollbar } from '@helpers/rollbar';


const PROOF_FETCH_INTERVAL = 3000;
const PROOF_GENERATION_TIMEOUT = 60000; // 60 seconds timeout

export type ParsedPayment = {
  amount: string;
  timestamp: string;
  recipientId: string;
  index: number;
};


interface ExtensionProofFormProps {
  intentHash: string;
  paymentPlatform: PaymentPlatformType;
  paymentProof: Proof | null;
  setPaymentProof: (proof: Proof | null) => void;
  proofGenerationStatus: ProofGenerationStatusType;
  setProofGenerationStatus: (status: ProofGenerationStatusType) => void;
  handleCompleteOrderClick: () => void;
  handleGoBackToSwap: () => void;
  handleSubmitSwapClick: () => void;
  completeOrderTransactionSigningStatus: string;
  completeOrderTransactionMiningStatus: string;
  completeOrderTransactionHash: string;
  showUseReclaimFlow: boolean;
  handleUseReclaimFlowClick: () => void;
  bridgingNeeded: boolean;
  quoteData: ParsedQuoteData | null;
  bridgeTransactions: {
    txHash: string;
    chainId: number;
  }[] | null;
  simulationErrorMessage: string | null;
  setTitle: (title: string) => void;
  setBreadcrumbStep: (step: BreadcrumbStep) => void;
}

export const ExtensionProofForm: React.FC<ExtensionProofFormProps> = ({
  intentHash,
  paymentPlatform,
  paymentProof,
  setPaymentProof,
  proofGenerationStatus,
  setProofGenerationStatus,
  handleCompleteOrderClick,
  handleGoBackToSwap,
  handleSubmitSwapClick,
  completeOrderTransactionSigningStatus,
  completeOrderTransactionMiningStatus,
  completeOrderTransactionHash,
  showUseReclaimFlow,
  handleUseReclaimFlowClick,
  bridgingNeeded,
  quoteData,
  bridgeTransactions,
  simulationErrorMessage,
  setTitle,
  setBreadcrumbStep
}) => {

  ExtensionProofForm.displayName = "ExtensionProofForm";

  /*
   * Context
   */

  const { navigateWithQuery } = useQuery();

  const {
    paymentProof: extensionPaymentProof,
    fetchPaymentProof,
    generatePaymentProof,
    resetProofState
  } = useExtensionProxyProofs();
  
  /*
   * State
   */


  const [selectedPayment, setSelectedPayment] = useState<ExtensionRequestMetadata | null>(null);

  const [shouldShowVerificationModal, setShouldShowVerificationModal] = useState<boolean>(false);
  
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const [triggerProofFetchPolling, setTriggerProofFetchPolling] = useState<boolean>(false);

  /*
   * Hooks
   */


  useEffect(() => {
    if (extensionPaymentProof) {
      let transferProof = extensionPaymentProof;
      console.log('---------transferProof---------', transferProof);

      switch (transferProof.status) {
        case 'pending':
          break;
        case 'success':
          console.log('---------setting payment proof---------');
          const parsedProof = parseExtensionProof(transferProof.proof);
          setPaymentProof(parsedProof);

          resetProofState();
          break;
        case 'error':
          setProofGenerationStatus(ProofGenerationStatus.ERROR_FAILED_TO_PROVE);

          // Log proof gen failures in Rollbar
          rollbar.error('Error generating proof', {
            proof: transferProof,
            paymentPlatform,
            intentHash,
            selectedPayment,
          });
          break;
      }
    }
  }, [
    extensionPaymentProof,
  ]);

  // Setup interval for proof fetching
  useEffect(() => {
    if (triggerProofFetchPolling && paymentPlatform) {
      console.log('---------setting interval for proof fetching---------');
      if (intervalId) clearInterval(intervalId);
      
      const id = setInterval(
        () => {
          console.log('fetching proof');
          fetchPaymentProof(paymentPlatform);
        }, 
        PROOF_FETCH_INTERVAL
      );
      setIntervalId(id);

      // Add timeout
      const timeoutId = setTimeout(() => {
        if (intervalId) clearInterval(intervalId);
        setTriggerProofFetchPolling(false);
        setProofGenerationStatus(ProofGenerationStatus.ERROR_FAILED_TO_PROVE);
        
        rollbar.error('Proof generation timed out', {
          paymentPlatform,
          intentHash,
          selectedPayment,
        });
      }, PROOF_GENERATION_TIMEOUT);

      return () => {
        if (intervalId) clearInterval(intervalId);
        clearTimeout(timeoutId);
      };
    } 
  }, [paymentPlatform, fetchPaymentProof, triggerProofFetchPolling]);

  // Clear interval when payment proof is generated
  useEffect(() => {
    if (paymentProof !== null && intervalId !== null) {
      console.log('---------clearing interval---------');
      clearInterval(intervalId);
      setTriggerProofFetchPolling(false);
    }
  }, [paymentProof, intervalId]);

  /*
   * Handlers
   */


  const handleVerifyPaymentClicked = async () => {
    
    setShouldShowVerificationModal(true);

    if (selectedPayment && intentHash && paymentPlatform) {
      // simulate proof request success
      setProofGenerationStatus(ProofGenerationStatus.REQUESTING_PROOF);

      await new Promise(resolve => setTimeout(resolve, 500))

      setProofGenerationStatus(ProofGenerationStatus.REQUESTING_PROOF_SUCCESS);

      await new Promise(resolve => setTimeout(resolve, 100))

      // start proof generation
      setProofGenerationStatus(ProofGenerationStatus.GENERATING_PROOF);

      const intentHashDecimals = BigNumber.from(intentHash).toString();

      setTriggerProofFetchPolling(false);

      if (intervalId) clearInterval(intervalId);    // clear interval before generating proof

      setPaymentProof(null);    // clear payment proof before generating proof

      generatePaymentProof(paymentPlatform, intentHashDecimals, selectedPayment.originalIndex);

      setTriggerProofFetchPolling(true);    // start polling for proof
    }
  }

  const handleModalBackClicked = () => {
    // Clear proof generation if back button is clicked
    if (intervalId) clearInterval(intervalId);
    setShouldShowVerificationModal(false);
  }


  /*
   * Component
   */

  return (
    <>
      {paymentPlatform && (
        <Container>
          {shouldShowVerificationModal && (
            <ProvePayment
              title={"Verify Payment"}
              proof={JSON.stringify(paymentProof) || ''}
              onBackClick={handleModalBackClicked}
              onProofGenCompletion={handleGoBackToSwap}
              status={proofGenerationStatus}
              platform={paymentPlatform}
              buttonTitle={'Complete Order'}
              submitTransactionStatus={completeOrderTransactionSigningStatus}
              isSubmitMining={completeOrderTransactionMiningStatus === 'loading'}
              setProofGenStatus={setProofGenerationStatus}
              handleSubmitVerificationClick={handleCompleteOrderClick}
              transactionAddress={completeOrderTransactionHash}
              provingFailureErrorCode={1}
              bridgingNeeded={bridgingNeeded}
              quoteData={quoteData}
              bridgeTransactions={bridgeTransactions}
              handleSubmitSwapClick={handleSubmitSwapClick}
              isAppclipFlow={false}
              simulationErrorMessage={simulationErrorMessage}
            />
          )}

        <PaymentTable
          paymentPlatform={paymentPlatform}
          setSelectedPayment={setSelectedPayment}
          handleVerifyPaymentClicked={handleVerifyPaymentClicked}
          isProofModalOpen={shouldShowVerificationModal}
          showUseReclaimFlow={showUseReclaimFlow}
          handleUseReclaimFlowClick={handleUseReclaimFlowClick}
          setTitle={setTitle}
          setBreadcrumbStep={setBreadcrumbStep}
        />
      </Container>
    )}
    </>
  )
};

const Container = styled.div`
  width: 100%;
`;
