import React, { useEffect, useState, ReactNode } from 'react'
import { erc20ABI, useBalance, useContractRead } from 'wagmi'
import { BigNumber } from 'ethers'

import { esl, ZERO, ZERO_ADDRESS, ZERO_BIGINT } from '@helpers/constants'
import useAccount from '@hooks/contexts/useAccount'
import useSmartContracts from '@hooks/contexts/useSmartContracts'

import BalancesContext from './BalancesContext'
import { tokenInfo, Token } from '@helpers/types/tokens'


interface ProvidersProps {
  children: ReactNode;
}

const BalancesProvider = ({ children }: ProvidersProps) => {
  /*
   * Contexts
   */

  const { isLoggedIn, loggedInEthereumAddress } = useAccount();
  const {
    escrowAddress,
    usdcAddress,
  } = useSmartContracts();

  /*
   * State
   */

  const [ethBalance, setEthBalance] = useState<BigNumber | null>(null);
  const [usdcBalance, setUsdcBalance] = useState<BigNumber | null>(null);
  const [usdcApprovalToEscrow, setUsdcApprovalToEscrow] = useState<BigNumber | null>(null);

  const [shouldFetchEthBalance, setShouldFetchEthBalance] = useState<boolean>(false);
  const [shouldFetchUsdcBalance, setShouldFetchUsdcBalance] = useState<boolean>(false);
  const [shouldFetchUsdcApprovalToEscrow, setShouldFetchUsdcApprovalToEscrow] = useState<boolean>(false);

  const [shouldFetchCbbtcBalance, setShouldFetchCbbtcBalance] = useState<boolean>(false);
  const [cbbtcBalance, setCbbtcBalance] = useState<BigNumber | null>(null);

  /*
   * Contract Reads
   */

  const {
    data: ethBalanceRaw,
    refetch: refetchEthBalance,
  } = useBalance({
    address: loggedInEthereumAddress ?? ZERO_ADDRESS,
    enabled: shouldFetchEthBalance,
  });

  const {
    data: usdcBalanceRaw,
    refetch: refetchUsdcBalance,
  } = useBalance({
    address: loggedInEthereumAddress ?? ZERO_ADDRESS,
    token: usdcAddress,
    enabled: shouldFetchUsdcBalance,
  })

  const {
    data: usdcApprovalToEscrowRaw,
    refetch: refetchUsdcApprovalToEscrow,
  } = useContractRead({
    address: usdcAddress,
    abi: erc20ABI,
    functionName: "allowance",
    args: [
      loggedInEthereumAddress ?? ZERO_ADDRESS,
      escrowAddress
    ],
    enabled: shouldFetchUsdcApprovalToEscrow,
  });

  const {
    data: cbbtcBalanceRaw,
    refetch: refetchCbbtcBalance,
  } = useBalance({
    address: loggedInEthereumAddress ?? ZERO_ADDRESS,
    token: tokenInfo[Token.CBBTC].address,
    enabled: shouldFetchCbbtcBalance,
  });

  /*
   * Hooks
   */

  useEffect(() => {
    esl && console.log('shouldFetchEthBalance_1');
    esl && console.log('checking isLoggedIn: ', isLoggedIn);
    esl && console.log('checking loggedInEthereumAddress: ', loggedInEthereumAddress);

    if (isLoggedIn && loggedInEthereumAddress) {
      esl && console.log('shouldFetchEthBalance_2');

      setShouldFetchEthBalance(true);
    } else {
      esl && console.log('shouldFetchEthBalance_3');

      setShouldFetchEthBalance(false);

      setEthBalance(null);
      setUsdcBalance(null);
      setUsdcApprovalToEscrow(null);

      setCbbtcBalance(null);
    }
  }, [isLoggedIn, loggedInEthereumAddress]);

  useEffect(() => {
    esl && console.log('shouldFetchUsdcBalanceAndApproval_1');
    esl && console.log('checking isLoggedIn: ', isLoggedIn);
    esl && console.log('checking loggedInEthereumAddress: ', loggedInEthereumAddress);
    esl && console.log('checking escrowAddress: ', escrowAddress);
    esl && console.log('checking usdcAddress: ', usdcAddress);

    if (isLoggedIn && loggedInEthereumAddress && escrowAddress && usdcAddress) {
      esl && console.log('shouldFetchUsdcBalanceAndApproval_2');

      setShouldFetchUsdcBalance(true);
      setShouldFetchUsdcApprovalToEscrow(true);
      setShouldFetchCbbtcBalance(true);
    } else {
      esl && console.log('shouldFetchUsdcBalanceAndApproval_3');

      setShouldFetchUsdcBalance(false);
      setShouldFetchUsdcApprovalToEscrow(false);
      setShouldFetchCbbtcBalance(false);

      setEthBalance(null);
      setUsdcBalance(null);
      setUsdcApprovalToEscrow(null);

      setCbbtcBalance(null);
    }
  }, [isLoggedIn, loggedInEthereumAddress, escrowAddress, usdcAddress]);
  
  useEffect(() => {
    esl && console.log('ethBalanceRaw_1');
    esl && console.log('checking ethBalanceRaw: ', ethBalanceRaw);
  
    if (ethBalanceRaw) {
      esl && console.log('ethBalanceRaw_2');

      const ethBalanceProcessed = ethBalanceRaw.value;

      setEthBalance(ethBalanceProcessed);
    } else {
      esl && console.log('ethBalanceRaw_3');

      setEthBalance(null);
    }
  }, [ethBalanceRaw]);

  useEffect(() => {
    esl && console.log('usdcBalanceRaw_1');
    esl && console.log('checking usdcBalanceRaw: ', usdcBalanceRaw);
  
    if (usdcBalanceRaw) {
      esl && console.log('usdcBalanceRaw_2');

      const usdcBalanceRawProcessed = usdcBalanceRaw.value;

      setUsdcBalance(usdcBalanceRawProcessed);
    } else {
      esl && console.log('usdcBalanceRaw_3');

      setUsdcBalance(null);
    }
  }, [usdcBalanceRaw]);

  useEffect(() => {
    esl && console.log('cbbtcBalanceRaw_1');
    esl && console.log('checking cbbtcBalanceRaw: ', cbbtcBalanceRaw);
  
    if (cbbtcBalanceRaw) {
      esl && console.log('cbbtcBalanceRaw_2');

      const cbbtcBalanceProcessed = cbbtcBalanceRaw.value;

      setCbbtcBalance(cbbtcBalanceProcessed);
    } else {
      esl && console.log('cbbtcBalanceRaw_3');

      setCbbtcBalance(null);
    }
  }, [cbbtcBalanceRaw]);

  useEffect(() => {
    esl && console.log('usdcApprovalToEscrowRaw_1');
    esl && console.log('checking usdcApprovalToEscrowRaw: ', usdcApprovalToEscrowRaw);
  
    if (usdcApprovalToEscrowRaw || usdcApprovalToEscrowRaw === ZERO || usdcApprovalToEscrowRaw === ZERO_BIGINT) { // BigInt(0) is falsy
      esl && console.log('usdcApprovalToEscrowRaw_2');

      setUsdcApprovalToEscrow(usdcApprovalToEscrowRaw);
    } else {
      esl && console.log('usdcApprovalToEscrowRaw_3');
      
      setUsdcApprovalToEscrow(null);
    }
  }, [usdcApprovalToEscrowRaw]);

  return (
    <BalancesContext.Provider
      value={{
        ethBalance,
        refetchEthBalance,
        shouldFetchEthBalance,
        usdcBalance,
        refetchUsdcBalance,
        shouldFetchUsdcBalance,
        usdcApprovalToEscrow,
        refetchUsdcApprovalToEscrow,

        cbbtcBalance,
        refetchCbbtcBalance,
      }}
    >
      {children}
    </BalancesContext.Provider>
  );
};

export default BalancesProvider
