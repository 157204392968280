import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import useSmartContracts from '@hooks/contexts/useSmartContracts';

import { Address, EscrowRange, EscrowDepositVerifierData, EscrowCurrency } from '@helpers/types';
import { Token, TokenType } from '@helpers/types';

export default function useCreateDeposit(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */
  const { escrowAddress, escrowAbi } = useSmartContracts();

  /*
   * State
   */
  const [tokenInput, setTokenInput] = useState<TokenType>(Token.USDC);
  const [amountInput, setAmountInput] = useState<string>('0');
  const [intentAmountRangeInput, setIntentAmountRangeInput] = useState<EscrowRange | null>(null);
  const [verifiersInput, setVerifiersInput] = useState<Address[] | null>(null);
  const [verifierDataInput, setVerifierDataInput] = useState<EscrowDepositVerifierData[] | null>(null);
  const [currenciesInput, setCurrenciesInput] = useState<{ code: string, conversionRate: string }[][] | null>(null);

  const [shouldConfigureCreateDepositWrite, setShouldConfigureCreateDepositWrite] = useState<boolean>(false);

  /*
   * Contract Writes
   */
  const { config: writeCreateDepositConfig } = usePrepareContractWrite({
    address: escrowAddress,
    abi: escrowAbi,
    functionName: 'createDeposit',
    args: [
      tokenInput.toString(),
      amountInput.toString(),
      {
        min: intentAmountRangeInput?.min.toString(),
        max: intentAmountRangeInput?.max.toString()
      },
      verifiersInput,
      verifierDataInput,
      currenciesInput?.map(currencies =>
        currencies.map(currency => ({
          code: currency.code,
          conversionRate: currency.conversionRate.toString()
        }))
      )
    ],
    onError(error: any) {
      console.log('writeCreateDepositConfig failed: ', error.message);
      setShouldConfigureCreateDepositWrite(false);
      onError?.(error);
    },
    enabled: shouldConfigureCreateDepositWrite && Boolean(
      tokenInput &&
      amountInput &&
      intentAmountRangeInput &&
      verifiersInput &&
      verifierDataInput &&
      currenciesInput
    ),
    request: {
      maxPriorityFeePerGas: (existing: bigint) => existing * BigInt(2),
    },
  });

  const {
    data: submitCreateDepositResult,
    status: signCreateDepositTransactionStatus,
    writeAsync: writeCreateDepositAsync,
  } = useContractWrite(writeCreateDepositConfig);

  const {
    status: mineCreateDepositTransactionStatus,
  } = useWaitForTransaction({
    hash: submitCreateDepositResult ? submitCreateDepositResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeCreateDepositAsync successful: ', data);
      setShouldConfigureCreateDepositWrite(false);
      onSuccess?.(data);
    },
  });

  return {
    writeCreateDepositAsync,
    tokenInput,
    setTokenInput,
    amountInput,
    setAmountInput,
    intentAmountRangeInput,
    setIntentAmountRangeInput,
    verifiersInput,
    setVerifiersInput,
    verifierDataInput,
    setVerifierDataInput,
    currenciesInput,
    setCurrenciesInput,
    shouldConfigureCreateDepositWrite,
    setShouldConfigureCreateDepositWrite,
    signCreateDepositTransactionStatus,
    mineCreateDepositTransactionStatus,
    transactionHash: submitCreateDepositResult?.hash,
  };
}
