import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { BigNumber } from 'ethers';
import { ThemedText } from '@theme/text'
import { colors } from '@theme/colors';

import { ConfirmRelease } from '@components/modals/ConfirmRelease';
import { IntentRow, IntentRowData } from "@components/Deposit/OffRamperIntentRow";

import { tokenUnitsToReadable } from '@helpers/units';
import { EscrowIntentView, getCurrencyInfoFromHash } from '@helpers/types';
import { calculateFiatFromRequestedUSDC, formatExpiration } from '@helpers/intentHelper';

import useDeposits from '@hooks/contexts/useDeposits';
import useSmartContracts from '@hooks/contexts/useSmartContracts';


interface OffRamperIntentTableProps {
  selectedRow?: number;
  rowsPerPage?: number;
}

export const OffRamperIntentTable: React.FC<OffRamperIntentTableProps> = ({
  selectedRow,
  rowsPerPage = 10
}) => {
  /*
   * Contexts
   */

  const {
    addressToPlatform,
    usdcAddress
  } = useSmartContracts();

  const {
    intentViews
  } = useDeposits();

  /*
   * State
   */

  const [shouldShowReleaseModal, setShouldShowReleaseModal] = useState<boolean>(false);
  const [selectedReleaseIntentHash, setSelectedReleaseIntentHash] = useState<string>("");
  const [selectedReleaseIntentTokenToSend, setSelectedReleaseIntentTokenToSend] = useState<string>("");
  const [selectedReleaseIntentAmount, setSelectedReleaseIntentAmount] = useState<string>("");
 
  const [intentsRowData, setIntentsRowData] = useState<IntentRowData[]>([]);

  /*
   * Hooks
   */

  useEffect(() => {
    if (intentViews) {
      var sanitizedIntents: IntentRowData[] = [];
      sanitizedIntents = intentViews.map((intentView: EscrowIntentView, index: number) => {
        const intent = intentView.intent;
        const deposit = intentView.deposit;
        const intentHash = intentView.intentHash;

        const verifierAddress = intent.paymentVerifier;
        const paymentPlatform = addressToPlatform[verifierAddress];

        const amountToken = BigNumber.from(intent.amount);
        const tokenAddress = deposit.deposit.token;
        const tokenDecimals = tokenAddress === usdcAddress ? 6 : 18;
        const tokenToSend = 'USDC';
        const tokenAmountToSend = tokenUnitsToReadable(amountToken, tokenDecimals);

        const fiatToReceive = getCurrencyInfoFromHash(intent.fiatCurrency)?.currencyCode || "";
        const tokenToFiatConversionRate = intent.conversionRate;
        const fiatAmountToReceiveBN = calculateFiatFromRequestedUSDC(
          amountToken, 
          tokenToFiatConversionRate, 
          tokenDecimals
        );
        const fiatAmountToReceive = tokenUnitsToReadable(fiatAmountToReceiveBN, tokenDecimals);
        
        const onRamper = intent.owner;
        const isExpired = formatExpiration(BigNumber.from(intent.timestamp)) === "Expired";

        const sanitizedIntent: IntentRowData = {
          paymentPlatform,
          onRamper,
          currencyToReceive: fiatToReceive,
          fiatAmountToReceive,
          tokenToSend,
          tokenAmountToSend,
          isExpired,
          handleReleaseClick: () => {
            if (onReleaseIntentClick) {
              onReleaseIntentClick(intentHash, tokenToSend, tokenAmountToSend);
            }
          }
        }

        console.log('sanitizedIntent: ', sanitizedIntent);
        return sanitizedIntent;
      });

      setIntentsRowData(sanitizedIntents);
    } else {
      setIntentsRowData([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intentViews]);


  /*
   * Handlers
   */

  const onReleaseIntentClick = (intentHash: string, tokenToSend: string, tokenAmountToSend: string) => {
    setSelectedReleaseIntentHash(intentHash);
    setSelectedReleaseIntentTokenToSend(tokenToSend);
    setSelectedReleaseIntentAmount(tokenAmountToSend);

    setShouldShowReleaseModal(true);
  };

  const onCloseReleaseModal = () => {
    setShouldShowReleaseModal(false);

    setSelectedReleaseIntentHash("");
    setSelectedReleaseIntentTokenToSend("");
    setSelectedReleaseIntentAmount("");
  };

  /*
   * Render
   */

  return (
    <Container>
      {
        shouldShowReleaseModal && (
          <ConfirmRelease
            onBackClick={onCloseReleaseModal}
            intentHash={selectedReleaseIntentHash}
            tokenToSend={selectedReleaseIntentTokenToSend}
            amountTokenToSend={selectedReleaseIntentAmount}
          />
        )
      }

      <TitleAndTableContainer>
        <IntentCountTitle>
          <ThemedText.LabelSmall textAlign="left">
            Orders on your deposits
          </ThemedText.LabelSmall>
        </IntentCountTitle>

        <Table>
          {intentsRowData.map((intentsRow, rowIndex) => (
            <IntentRow
              key={rowIndex}
              paymentPlatform={intentsRow.paymentPlatform}
              currencyToReceive={intentsRow.currencyToReceive}
              fiatAmountToReceive={intentsRow.fiatAmountToReceive}
              tokenToSend={intentsRow.tokenToSend}
              tokenAmountToSend={intentsRow.tokenAmountToSend}
              isExpired={intentsRow.isExpired}
              onRamper={intentsRow.onRamper}
              handleReleaseClick={intentsRow.handleReleaseClick}
            />
          ))}
        </Table>
      </TitleAndTableContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: center;
  gap: 1rem;
  background-color: ${colors.container};
  overflow: hidden;
  border: 1px solid ${colors.defaultBorderColor};
  border-radius: 16px;

  @media (min-width: 600px) {
    max-width: 98%;
  }
`;

const TitleAndTableContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const IntentCountTitle = styled.div`
  width: 100%;
  padding-top: 1.25rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  border-bottom: 1px solid ${colors.defaultBorderColor};
`;

const Table = styled.div`
  width: 100%;
  border-radius: 8px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  color: #616161;

  & > * {
    border-bottom: 1px solid ${colors.defaultBorderColor};
  }

  & > *:last-child {
    border-bottom: none;
  }
`;
