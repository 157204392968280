import React, { useCallback, useEffect, useState } from "react";
import styled from 'styled-components';
import { ENSName, AddressDisplayEnum } from 'react-ens-name';
import Link from '@mui/material/Link';
import { colors } from '@theme/colors';

import { PaymentPlatformType, CurrencyType, currencyInfo } from '@helpers/types';
import { TokenType, tokenInfo, paymentPlatformInfo } from '@helpers/types';
import { alchemyMainnetEthersProvider } from "index";

import useMediaQuery from "@hooks/useMediaQuery";
import useSmartContracts from "@hooks/contexts/useSmartContracts";
import { AccessoryButton } from "@components/common/AccessoryButton";

interface LiquidityRowProps {
  depositor: string;
  token: TokenType;
  availableLiquidity: string;
  currency: CurrencyType;
  conversionRate: string;
  platform: PaymentPlatformType;
  rowIndex: number;
  limits: {
    min: string;
    max: string;
  };
}


export const LiquidityRow: React.FC<LiquidityRowProps> = ({
  depositor,
  token,
  availableLiquidity,
  currency,
  conversionRate,
  platform,
  rowIndex,
  limits
}) => {
  const isMobile = useMediaQuery() === 'mobile';

  const {
    blockscanUrl
  } = useSmartContracts();
  

  /*
   * Helpers
   */

  const tokenTicker = tokenInfo[token].ticker;
  const tokenIcon = tokenInfo[token].icon;
  const platformName = paymentPlatformInfo[platform].platformName;
  const depositorEtherscanLink = `${blockscanUrl}/address/${depositor}`;

  const getFormattedAmount = (amount: string) => {
    return parseFloat(amount).toLocaleString('en-US') + ' ' + tokenTicker;
  }

  const getFormattedAmountRange = (min: string, max: string) => {
    return parseFloat(min).toLocaleString('en-US') + ' – ' + parseFloat(max).toLocaleString('en-US') + ' ' + tokenTicker;
  }

  /*
   * Component
   */

  return (
    <Container>
      <RowContent>

        {/* Coin Icon, Symbol, Price */}
        <Section>
          {/* <PriceHeader>
            <PriceSection>
              <CurrencyFlag src={currencyIcon} />
              <PriceInfo>
                <PriceText>{conversionRate} {currency}</PriceText>
                <PriceSubtext>Price per 1 {tokenTicker}</PriceSubtext>
              </PriceInfo>
            </PriceSection>
          </PriceHeader> */}
          <CurrencySection>
            <TokenIcon src={tokenIcon} />
            <CurrencySymbol>{tokenTicker}</CurrencySymbol>
          </CurrencySection>
        </Section>

        {/* Price */}
        <PriceSection>
          <FlagIcon className={`fi fi-${currencyInfo[currency].countryCode}`} />
          <PriceText>{conversionRate} {currency}</PriceText>
        </PriceSection>


        {/* Depositor */}
        <Section>
          <Value>
            <Link href={depositorEtherscanLink} target="_blank">
              <ENSNameWrapper>
                <ENSName
                  provider={alchemyMainnetEthersProvider}
                  address={depositor}
                  displayType={AddressDisplayEnum.FIRST6}
                />
              </ENSNameWrapper>
            </Link>
          </Value>
        </Section>

        {/* Platform */}
        <Section>
          <Value>{platformName}</Value>
        </Section>

        {/* Conversion Rate */}
        <AvailableSection>
          <Value>{getFormattedAmount(availableLiquidity)}</Value>
          <Value>{getFormattedAmountRange(limits.min, limits.max)}</Value>
        </AvailableSection>

        {/* Amounts Section */}
        {/* <Section>
          <AccessoryButton
              borderRadius={16}
              onClick={() => {}}
              width={isMobile ? 80 : 120}
              textAlign="center"
            >
            Buy {tokenTicker}
          </AccessoryButton>
        </Section> */}
      </RowContent>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const RowContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr 1fr 1fr;
  gap: 1.5rem;
  padding: 1rem 1.5rem 1rem 1.5rem;
  align-items: center;
`;

const RowIndex = styled.span`
  color: #6C757D;
  font-size: 14px;
`;

const Section = styled.div`
  display: flex;
  font-size: 15px;
  color: #FFFFFF;
  align-items: center;
`;

const CoinSection = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const PriceHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PriceSubtext = styled.span`
  font-size: 14px;
  color: ${colors.grayText};
`;

const PriceSection = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const PriceInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const CurrencySection = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const TokenIcon = styled.img`
  border-radius: 18px;
  width: 24px;
  height: 24px;
`;

const FlagIcon = styled.span`
  width: 24px;
  height: 24px;
  border-radius: 18px;
  display: inline-block;
  background-size: 150%;
  background-position: center;
`;

const CurrencySymbol = styled.span`
  font-size: 16px;
  color: #FFFFFF;
`;

const PriceText = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: #FFFFFF;
`;

const CoinSvg = styled.img`
  border-radius: 18px;
  width: 24px;
  height: 24px;
`;

const CoinSymbol = styled.span`
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
`;

const PoolSeparator = styled.div`
  padding: 0;
`;


const AvailableSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

const Value = styled.span`
  color: #FFFFFF;
  font-size: 15px;

  @media (max-width: 600px) {
    font-size: 14px;
  };
`;

const ENSNameWrapper = styled.div`
  max-width: 144px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
