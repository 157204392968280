import React, { ReactNode } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

import useMediaQuery from '@hooks/useMediaQuery';

import { TopNav } from "@components/layouts/TopNav";
import { BottomNav } from "@components/layouts/BottomNav";
import { EnvironmentBanner } from '@components/layouts/EnvironmentBanner';

import { Landing } from "./pages/Landing";
import { Swap } from "./pages/Swap";
import { Liquidity } from "./pages/Liquidity";
import { DepositPage } from "./pages/Deposit";
import { Send } from "./pages/Send";
import { Privacy } from "./pages/Privacy";
import { Tos } from "./pages/Tos";
import Modals from "./pages/Modals";

// Common Contexts
import AccountProvider from "./contexts/Account/AccountProvider";
import BalancesProvider from "./contexts/Balances/BalancesProvider";
import SendSettingsProvider from './contexts/SendSettings/SendSettingsProvider';
import SmartContractsProvider from './contexts/SmartContracts/SmartContractsProvider';
import ExtensionProxyProofsProvider from './contexts/ExtensionProxyProofs/ExtensionProxyProofsProvider';
import { ModalSettingsProvider } from 'contexts/ModalSettings';
import OnRamperIntentsProvider from './contexts/OnRamperIntents/OnRamperIntentsProvider';
import DepositsProvider from './contexts/Deposits/DepositsProvider';
import LiquidityProvider from './contexts/Liquidity/LiquidityProvider';
import EscrowProvider from './contexts/Escrow/EscrowProvider';
import BackendProvider from './contexts/Backend/BackendContextProvider';
import GeolocationProvider from './contexts/Geolocation/GeolocationProvider';


import "./App.css";
import "./styles.css";

const App = () => {
  /*
   * Context
   */

  const currentDeviceSize = useMediaQuery();

  /*
   * Component
   */

  return (
    <Router>
      <Providers>
        <div className="app-container">
          <EnvironmentBanner />
          <TopNav />
          <Modals />
          <div className="app-content">
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/swap" element={<Swap />} />
              <Route path="/liquidity" element={<Liquidity />} />
              <Route path="/pool" element={<DepositPage />} />
              <Route path="/send" element={<Send />} />
              <Route path="/pp" element={<Privacy />} />
              <Route path="/tos" element={<Tos />} />
              <Route element={<>Not found</>} />
            </Routes>
          </div>

          {(currentDeviceSize === 'mobile') &&
            <BottomNav />
          }
        </div>
      </Providers>
    </Router>
  );
};

type ProvidersType = [React.ElementType, Record<string, unknown>];
type ChildrenType = {
  children: Array<React.ElementType>;
};

export const buildProvidersTree = (
  componentsWithProps: Array<ProvidersType>,
) => {
  const initialComponent = ({children}: {children: React.ReactNode}) => <>{children}</>;
  return componentsWithProps.reduce(
    (
      AccumulatedComponents: React.ElementType,
      [Provider, props = {}]: ProvidersType,
    ) => {
      return ({children}: ChildrenType) => {
        return (
          <AccumulatedComponents>
            <Provider {...props}>{children}</Provider>
          </AccumulatedComponents>
        );
      };
    },
    initialComponent,
  );
};

const providersWithProps: ProvidersType[] = [
  [AccountProvider, {}],
  [SmartContractsProvider, {}],
  [SendSettingsProvider, {}],
  [BalancesProvider, {}],
  [EscrowProvider, {}],
  [DepositsProvider, {}],
  [LiquidityProvider, {}],
  [BackendProvider, {}],
  
  [ExtensionProxyProofsProvider, {}],

  [ModalSettingsProvider, {}],

  [OnRamperIntentsProvider, {}],
  [GeolocationProvider, {}],
];

const ProviderTree = buildProvidersTree(providersWithProps);

interface ProvidersProps {
  children: React.ReactNode;
}

const Providers: React.FC<ProvidersProps> = ({ children }) => {
  return <ProviderTree>{children}</ProviderTree>;
}

export default App;
