import {} from 'axios';
import { axios } from '../utils/axios.js';
import { zeroAddress } from 'viem';
import prepareCallTransaction from '../utils/prepareCallTransaction.js';
import { isSimulateContractRequest, APIError, adaptViemWallet } from '../utils/index.js';
import { isViemWalletClient } from '../utils/viemWallet.js';
import { getClient } from '../client.js';
import { getDeadAddress } from '../constants/address.js';
/**
 * Method to get a quote
 * @param data - {@link GetQuoteParameters}
 */
export async function getQuote(parameters) {
    const { toChainId, toCurrency, wallet, chainId, currency, tradeType, amount = '0', recipient, options, txs } = parameters;
    const client = getClient();
    if (!client.baseApiUrl || !client.baseApiUrl.length) {
        throw new ReferenceError('RelayClient missing api url configuration');
    }
    let adaptedWallet;
    let caller;
    if (wallet) {
        adaptedWallet = isViemWalletClient(wallet)
            ? adaptViemWallet(wallet)
            : wallet;
        caller = await adaptedWallet.address();
    }
    let preparedTransactions;
    if (txs && txs.length > 0) {
        preparedTransactions = txs.map((tx) => {
            if (isSimulateContractRequest(tx)) {
                return prepareCallTransaction(tx);
            }
            return tx;
        });
    }
    const fromChain = client.chains.find((chain) => chain.id === chainId);
    const toChain = client.chains.find((chain) => chain.id === toChainId);
    const originDeadAddress = fromChain
        ? getDeadAddress(fromChain.vmType, fromChain.id)
        : undefined;
    const destinationDeadAddress = toChain
        ? getDeadAddress(toChain.vmType, toChain.id)
        : undefined;
    const query = {
        user: caller || originDeadAddress || zeroAddress,
        destinationCurrency: toCurrency,
        destinationChainId: toChainId,
        originCurrency: currency,
        originChainId: chainId,
        amount,
        recipient: recipient
            ? recipient
            : caller || destinationDeadAddress || zeroAddress,
        tradeType,
        referrer: client.source || undefined,
        txs: preparedTransactions,
        ...options
    };
    const request = {
        url: `${client.baseApiUrl}/quote`,
        method: 'post',
        data: query
    };
    const res = await axios.request(request);
    if (res.status !== 200) {
        throw new APIError(res?.data?.message, res.status, res.data);
    }
    return { ...res.data, request };
}
