import React, { useEffect, useState, ReactNode } from 'react';

import {
  TokenType,
  Token
} from '@helpers/types';

import SendSettingsContext from './SendSettingsContext'


interface ProvidersProps {
  children: ReactNode;
};

const SendSettingsProvider = ({ children }: ProvidersProps) => {

  /*
   * State
   */

  const [sendToken, setSendToken] = useState<TokenType>(Token.USDC);

  /*
   * Hooks
   */

  useEffect(() => {
    const storedSelectedSendToken = localStorage.getItem('storedSelectedSendToken');
    
    if (storedSelectedSendToken) {
      setSendToken(JSON.parse(storedSelectedSendToken));
    }
  }, []);

  useEffect(() => {
    if (sendToken) {
      localStorage.setItem('storedSelectedSendToken', JSON.stringify(sendToken));
    }
  }, [sendToken]);

  return (
    <SendSettingsContext.Provider
      value={{
        sendToken,
        setSendToken
      }}
    >
      {children}
    </SendSettingsContext.Provider>
  );
};

export default SendSettingsProvider;
