import { Currency, currencyInfo, CurrencyType } from './currency';

export const PaymentPlatform = {
  VENMO: "venmo",
  CASHAPP: "cashapp",
  REVOLUT: "revolut",
  WISE: "wise",
};

function getPaymentPlatforms(): string[] {
  let platforms = [
    PaymentPlatform.VENMO,
    PaymentPlatform.CASHAPP,
    PaymentPlatform.REVOLUT,
    PaymentPlatform.WISE,
  ];

  return platforms;
};

export const paymentPlatforms = getPaymentPlatforms();
export type PaymentPlatformType = typeof PaymentPlatform[keyof typeof PaymentPlatform];


export interface ProofExtractedParameters {
  amount: string;
  recipient: string;
  currency: string;
  paymentPlatform: string;
  date: string;
  paymentId: string;
  intentHash: string;
  providerHash: string;
}

interface PaymentPlatformData {
  platformId: PaymentPlatformType;
  platformName: string;
  platformCurrencies: CurrencyType[];
  troubleScanningQRCodeLink: (
    recipientId: string,
    sendCurrency?: CurrencyType,
    amountFiatToSend?: string
  ) => string;
  getFormattedSendLink: (
    recipientId: string,
    sendCurrency?: CurrencyType,
    amountFiatToSend?: string
  ) => string;
  useCustomQRCode?: boolean;
  minFiatAmount: string;
  authLink: string;
  localeTimeString: string;
  buttonStyle: {
    backgroundColor: string;
    textColor: string;
    fontFamily?: string;
  };
  deposit: {
    payeeDetailInputPlaceholder: string;
    payeeDetailInputHelperText: string;
    payeeDetailValidationFailureMessage: string;
    getDepositData: (payeeDetails: string, telegramUsername?: string) => { [key: string]: string };
  };
  supportsAppclip: boolean;
  numPaymentsFetched: number;
  parseExtractedParameters: (parameters: string) => ProofExtractedParameters;
  depositRequiresApproval: boolean;
  supportsSendingPaymentOnWeb: boolean;
  sendPaymentWarning?: (sendCurrency: CurrencyType, amountFiatToSend: string) => string;
}

export const paymentPlatformInfo: Record<PaymentPlatformType, PaymentPlatformData> = {
  [PaymentPlatform.VENMO]: {
    platformId: PaymentPlatform.VENMO,
    platformName: 'Venmo',
    platformCurrencies: [Currency.USD],
    troubleScanningQRCodeLink: (
      recipientId: string,
      sendCurrency?: CurrencyType,
      amountFiatToSend?: string
    ) => `https://account.venmo.com/pay?recipients=${recipientId}&note=💵&amount=${amountFiatToSend}`,
    getFormattedSendLink: (
      recipientId: string,
      sendCurrency?: CurrencyType,
      amountFiatToSend?: string
    ) => `venmo://paycharge?txn=pay&recipients=${recipientId}&note=💵&amount=${amountFiatToSend}`,
    minFiatAmount: '0.1',   // 1 USD
    authLink: 'https://account.venmo.com/?feed=mine',
    localeTimeString: 'en-US',
    buttonStyle: {
      backgroundColor: '#008CFF',
      textColor: '#FFFFFF',
      fontFamily: 'Helvetica, Arial, sans-serif'
    },
    deposit: {
      payeeDetailInputPlaceholder: "Enter your Venmo username",
      payeeDetailInputHelperText: "This is your Venmo username",
      payeeDetailValidationFailureMessage: "Make sure there are no typos in your username. Do not include the @",
      getDepositData: (payeeDetails: string, telegramUsername?: string) => {
        console.log('getDepositData', payeeDetails, telegramUsername);
        console.log('returning', {
          venmoUsername: payeeDetails,
          telegramUsername: telegramUsername || ''
        });
        return {
          venmoUsername: payeeDetails,
          telegramUsername: telegramUsername || ''
        };
      }
    },
    useCustomQRCode: true,
    numPaymentsFetched: 10,
    supportsAppclip: false,
    parseExtractedParameters: (context: string) => {
      const contextObject = JSON.parse(context);
      const params = contextObject.extractedParameters;

      const date = new Date(params.date + 'Z');
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      });

      const amount = (Number(params.amount.replace('-', ''))).toString();

      return {
        amount: amount,
        date: formattedDate,
        currency: 'USD',
        paymentPlatform: PaymentPlatform.VENMO,
        paymentId: params.paymentId,
        recipient: params.receiverId,
        intentHash: contextObject.contextMessage,
        providerHash: contextObject.providerHash
      };
    },
    depositRequiresApproval: false,
    supportsSendingPaymentOnWeb: true,
    sendPaymentWarning: (sendCurrency: CurrencyType, amountFiatToSend: string) => {
      return `Please do NOT toggle the "Turn on for purchases" option`;
    }
  },
  [PaymentPlatform.CASHAPP]: {
    platformId: PaymentPlatform.CASHAPP,
    platformName: 'Cash App',
    platformCurrencies: [Currency.USD],
    troubleScanningQRCodeLink: (
      recipientId: string,
      sendCurrency?: CurrencyType,
      amountFiatToSend?: string
    ) => recipientId.startsWith('$') ? `https://cash.app/qr/${recipientId}?size=288&margin=0` : `https://cash.app/qr/$${recipientId}?size=288&margin=0`,
    getFormattedSendLink: (
      recipientId: string,
      sendCurrency?: CurrencyType,
      amountFiatToSend?: string
    ) => recipientId.startsWith('$') ? `https://cash.app/qr/${recipientId}?size=288&margin=0` : `https://cash.app/qr/$${recipientId}?size=288&margin=0`,
    minFiatAmount: '0.1',   // 1 USD
    authLink: 'https://cash.app/account/activity',
    localeTimeString: 'en-US',
    buttonStyle: {
      backgroundColor: '#00D632',
      textColor: '#FFFFFF',
      fontFamily: 'CashMarket, Arial, sans-serif'
    },
    deposit: {
      payeeDetailInputPlaceholder: "Enter your Cashtag",
      payeeDetailInputHelperText: "This is your Cashtag. Please ensure you have set your Cashtag as discoverable by others. Do not include the $ symbol.",
      payeeDetailValidationFailureMessage: "Make sure you have set your Cashtag as discoverable by others. Do not include the $ symbol.",
      getDepositData: (payeeDetails: string, telegramUsername?: string) => {
        return {
          cashtag: payeeDetails,
          telegramUsername: telegramUsername || ''
        };
      }
    },
    useCustomQRCode: false,
    supportsAppclip: false,
    numPaymentsFetched: 15,
    parseExtractedParameters: (context: string) => {
      const contextObject = JSON.parse(context);
      const params = contextObject.extractedParameters;

      const date = new Date(Number(params.date));
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      });

      return {
        amount: params.amount,
        date: formattedDate,
        currency: params.currency_code,
        paymentPlatform: PaymentPlatform.CASHAPP,
        paymentId: params.paymentId,
        recipient: params.receiverId,
        intentHash: contextObject.contextMessage,
        providerHash: contextObject.providerHash
      };
    },
    depositRequiresApproval: false,
    supportsSendingPaymentOnWeb: false,
  },
  [PaymentPlatform.REVOLUT]: {
    platformId: PaymentPlatform.REVOLUT,
    platformName: 'Revolut',
    platformCurrencies: [
      Currency.USD,
      Currency.EUR,
      Currency.GBP,
      Currency.SGD,
      Currency.NZD,
      Currency.AUD,
      Currency.CAD,
      Currency.HKD,
      Currency.MXN,
      Currency.SAR,
      Currency.AED,
      Currency.THB,
      Currency.TRY,
      Currency.PLN,
      Currency.CHF,
      Currency.ZAR,
    ],
    troubleScanningQRCodeLink: (recipientId: string, sendCurrency?: CurrencyType, amountFiatToSend?: string) => `https://revolut.me/${recipientId}`,
    getFormattedSendLink: (
      recipientId: string,
      sendCurrency?: CurrencyType,
      amountFiatToSend?: string
    ) => `https://revolut.me/${recipientId}`,
    minFiatAmount: '0.1',   // 1 USD/EUR/GBP
    authLink: 'https://app.revolut.com/home',
    localeTimeString: 'en-US',
    buttonStyle: {
      backgroundColor: '#191C1F',
      textColor: '#FFFFFF',
      fontFamily: 'RevolutGrotesque, Arial, sans-serif'
    },
    deposit: {
      payeeDetailInputPlaceholder: "Enter your Revtag",
      payeeDetailInputHelperText: "This is your Revtag. Make sure you have set your Revtag to be publicly discoverable.",
      payeeDetailValidationFailureMessage: "Make sure you have set your Revtag to be publicly discoverable and there are no typos.",
      getDepositData: (payeeDetails: string, telegramUsername?: string) => {
        return {
          revolutUsername: payeeDetails,
          telegramUsername: telegramUsername || ''
        };
      }
    },
    useCustomQRCode: true,
    supportsAppclip: true,
    numPaymentsFetched: 20,
    parseExtractedParameters: (context: string) => {
      const contextObject = JSON.parse(context);
      const params = contextObject.extractedParameters;

      const date = new Date(Number(params.completedDate));
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      });

      const amount = (Number(params.amount.replace('-', '')) / 100).toString();

      return {
        amount: amount,
        date: formattedDate,
        currency: params.currency,
        paymentPlatform: PaymentPlatform.REVOLUT,
        paymentId: params.id,
        recipient: params.username,
        intentHash: contextObject.contextMessage,
        providerHash: contextObject.providerHash
      };
    },
    depositRequiresApproval: false,
    supportsSendingPaymentOnWeb: false,
    sendPaymentWarning: (sendCurrency: CurrencyType, amountFiatToSend: string) => {
      return `Ensure you are sending ${currencyInfo[sendCurrency].currencyCode} and not performing a cross-currency swap.`;
    }
  },
  [PaymentPlatform.WISE]: {
    platformId: PaymentPlatform.WISE,
    platformName: 'Wise',
    platformCurrencies: [
      Currency.USD,
      Currency.CNY,
      Currency.EUR,
      Currency.GBP,
      Currency.AUD,
      Currency.NZD,
      Currency.CAD,
      Currency.AED,
      Currency.CHF,
      Currency.ZAR,
      Currency.SGD,
      Currency.ILS,
      Currency.HKD,
      Currency.JPY,
      Currency.PLN,
      Currency.TRY,
      Currency.IDR,
      Currency.KES,
      Currency.MYR,
      Currency.MXN,
      Currency.THB,
      Currency.VND,
    ],
    troubleScanningQRCodeLink: (
      recipientId: string,
      sendCurrency?: CurrencyType,
      amountFiatToSend?: string
    ) => `https://wise.com/pay/me/${recipientId}`,
    getFormattedSendLink: (
      recipientId: string,
      sendCurrency?: CurrencyType,
      amountFiatToSend?: string
    ) => `https://wise.com/pay/me/${recipientId}`,
    minFiatAmount: '0.1',   // 1 USD
    authLink: 'https://wise.com/all-transactions?direction=OUTGOING',
    localeTimeString: 'en-US',
    buttonStyle: {
      backgroundColor: '#000000',
      textColor: '#FFFFFF',
      fontFamily: 'Arial, sans-serif'
    },
    deposit: {
      payeeDetailInputPlaceholder: "Enter your Wisetag",
      payeeDetailInputHelperText: "This is your Wisetag. Do not include the @ symbol.",
      payeeDetailValidationFailureMessage: "Make sure there are no typos. Do not include the @ symbol.",
      getDepositData: (payeeDetails: string, telegramUsername?: string) => {
        return {
          wisetag: payeeDetails,
          telegramUsername: telegramUsername || ''
        };
      }
    },
    useCustomQRCode: true,
    supportsAppclip: false,
    numPaymentsFetched: 20,
    parseExtractedParameters: (context: string) => {
      const contextObject = JSON.parse(context);
      const params = contextObject.extractedParameters;

      const date = new Date(Number(params.completedDate));
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      });

      const amount = (Number(params.amount.replace('-', '')) / 100).toString();

      return {
        amount: amount,
        date: formattedDate,
        currency: params.currency,
        paymentPlatform: PaymentPlatform.WISE,
        paymentId: params.id,
        recipient: params.username,
        intentHash: contextObject.contextMessage,
        providerHash: contextObject.providerHash
      };
    },
    depositRequiresApproval: true,
    supportsSendingPaymentOnWeb: true,
    sendPaymentWarning: (sendCurrency: CurrencyType, amountFiatToSend: string) => {
      return `You can send any currency. But ensure recipient gets ${currencyInfo[sendCurrency].currencyCode} ${amountFiatToSend} after fees.`;
    }
  }
};
