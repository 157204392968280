import React, { useEffect, useState, useCallback } from 'react';
import { Inbox, FileText } from 'react-feather';
import styled, { css } from 'styled-components/macro';
import { colors } from '@theme/colors';

import { ThemedText } from '@theme/text';
import { Button } from '@components/common/Button';
import { RowBetween } from '@components/layouts/Row';
import { DepositRow } from "@components/Deposit/DepositRow";
import { CustomConnectButton } from "@components/common/ConnectButton";
import { CurrencyType, getCurrencyInfoFromHash, paymentPlatformInfo, PaymentPlatformType, Token, tokenInfo } from '@helpers/types';
import { etherUnitsToReadable, tokenUnitsToReadable } from '@helpers/units';

import useAccount from '@hooks/contexts/useAccount';
import useDeposits from '@hooks/contexts/useDeposits';
import useSmartContracts from '@hooks/contexts/useSmartContracts';
import { UpdateDepositModal } from './UpdateDepositModal';
import useMediaQuery from '@hooks/useMediaQuery';


interface PositionTableProps {
  handleNewPositionClick: () => void;
}

interface DepositRowData {
  depositId: string;
  tokenTicker: string;
  tokenIcon: string;
  conversionRates: Map<PaymentPlatformType, Map<CurrencyType, string>>;
  originalAmount: string;
  availableLiquidity: string;
  activeOrders: string[];
  amountLockedInIntents: string;
  isDepositActive: boolean;
  intentAmountRange: {
    min: string;
    max: string;
  },
}

export const PositionTable: React.FC<PositionTableProps> = ({
  handleNewPositionClick
}) => {
  PositionTable.displayName = 'PositionTable';

  /*
   * Contexts
   */

  const currentDeviceSize = useMediaQuery(); 
  const isMobile = currentDeviceSize === 'mobile';

  const { isLoggedIn } = useAccount();
  const { usdcAddress, addressToPlatform } = useSmartContracts();
  const { depositViews } = useDeposits();

  /*
   * State
   */

  const [depositRows, setDepositRows] = useState<DepositRowData[]>([]);
  const [showUpdateRateModal, setShowUpdateRateModal] = useState(false);
  const [selectedDepositDetails, setSelectedDepositDetails] = useState<{
    depositId: string;
    platform: string;
    currency: string;
    currentRate: string;
  } | null>(null);


  /*
   * Hooks
   */

  useEffect(() => {
    if (depositViews) {
      const depositRowsData = depositViews.map((depositView) => {
        const deposit = depositView.deposit;
        const depositId = depositView.depositId.toString();
        const tokenKey = Token.USDC;
        const tokenDecimals = tokenInfo[tokenKey].tokenDecimals;
        const tokenTicker = tokenInfo[tokenKey].ticker;
        const tokenIcon = tokenInfo[tokenKey].icon;

        const originalAmount = tokenUnitsToReadable(deposit.depositAmount, tokenDecimals);
        const availableLiquidity = tokenUnitsToReadable(depositView.availableLiquidity, tokenDecimals);
        const intentAmountRangeMin = tokenUnitsToReadable(
          deposit.intentAmountRange.min, tokenDecimals
        );
        const intentAmountRangeMax = tokenUnitsToReadable(
          deposit.intentAmountRange.max, tokenDecimals
        );

        const conversionRates = new Map<PaymentPlatformType, Map<CurrencyType, string>>();
        
        depositView.verifiers.forEach(verifier => {
          const platform = addressToPlatform[verifier.verifier];
          if (!platform) {
            return;
          }
          
          if (!conversionRates.has(platform)) {
            conversionRates.set(platform, new Map<CurrencyType, string>());
          }
          
          const platformMap = conversionRates.get(platform);
          verifier.currencies.forEach(currency => {
            const _currency = getCurrencyInfoFromHash(currency.code)?.currency;
            platformMap?.set(
              _currency ?? '',
              etherUnitsToReadable(currency.conversionRate, 4)
            );
          });
        });

        const activeOrders = deposit.intentHashes;
        const amountLockedInIntents = tokenUnitsToReadable(
          deposit.outstandingIntentAmount, tokenDecimals
        );

        const isDepositActive = deposit.acceptingIntents;

        return {
          depositId,
          tokenTicker,
          tokenIcon,
          conversionRates,
          originalAmount,
          availableLiquidity,
          intentAmountRange: {
            min: intentAmountRangeMin,
            max: intentAmountRangeMax,
          },
          activeOrders,
          amountLockedInIntents,
          isDepositActive,
        }
      });

      setDepositRows(depositRowsData);
    }
  }, [depositViews]);

  /*
   * Component
   */

  const handleEditRateClicked = (depositId: string, platform: PaymentPlatformType, currency: CurrencyType, currentRate: string) => {
    setSelectedDepositDetails({ depositId, platform, currency, currentRate });
    setShowUpdateRateModal(true);
  };

  return (
    <Container>
      <TitleRow>
        <ThemedText.HeadlineMedium>
          Deposits
        </ThemedText.HeadlineMedium>
        {isLoggedIn ? (
          <Button onClick={handleNewPositionClick} height={40}>
              + New Deposit
          </Button>
        ) : null}
      </TitleRow>

      <Content>
        {!isLoggedIn ? (
          <ErrorContainer>
            <ThemedText.DeprecatedBody textAlign="center">
              <InboxIcon strokeWidth={1} style={{ marginTop: '2em' }} />
              <div>
                Your active deposits will appear here.
              </div>
            </ThemedText.DeprecatedBody>
            <CustomConnectButton width={152} />
          </ErrorContainer>
        ) : !depositViews || depositViews.length === 0 ? (
            <ErrorContainer>
              <ThemedText.DeprecatedBody textAlign="center">
                <InboxIcon strokeWidth={1} style={{ marginTop: '2em' }} />
                <div>
                  You have no active deposits.
                </div>
              </ThemedText.DeprecatedBody>
            </ErrorContainer>
        ) : (
          <PositionsContainer>
            <TableHeaderRow isMobile={isMobile}>
              {!isMobile && <ColumnHeader>#</ColumnHeader>}
              {!isMobile && <ColumnHeader>Token</ColumnHeader>}
              <ColumnHeader>Conversion Rates</ColumnHeader>
              <ColumnHeader>Details</ColumnHeader>
              {!isMobile && <ActionsColumnHeader>Actions</ActionsColumnHeader>}
            </TableHeaderRow>

            <Table>
              {depositRows && depositRows.map((depositRow, rowIndex) => (
                <PositionRowStyled key={rowIndex}>
                  <DepositRow
                    depositId={depositRow.depositId}
                    tokenTicker={depositRow.tokenTicker}
                    tokenIcon={depositRow.tokenIcon}
                    conversionRates={depositRow.conversionRates}
                    availableLiquidity={depositRow.availableLiquidity}
                    intentAmountRange={depositRow.intentAmountRange}
                    originalAmount={depositRow.originalAmount}
                    isDepositActive={depositRow.isDepositActive}
                    activeOrders={depositRow.activeOrders}
                    amountLockedInIntents={depositRow.amountLockedInIntents}
                    rowIndex={rowIndex}
                    handleEditRateClicked={handleEditRateClicked}
                  />
                </PositionRowStyled>
              ))}
            </Table>
          </PositionsContainer>
        )}
      </Content>

      {showUpdateRateModal && selectedDepositDetails && (
        <UpdateDepositModal
          depositId={selectedDepositDetails.depositId}
          platform={selectedDepositDetails.platform}
          currency={selectedDepositDetails.currency}
          currentRate={selectedDepositDetails.currentRate}
          onBackClick={() => {
            setShowUpdateRateModal(false);
            setSelectedDepositDetails(null);
          }}
        />
      )}
    </Container>
  )
};

const Container = styled.div`
  width: 100%;
  gap: 1rem;
  justify-content: center;

  @media (max-width: 600px) {
    max-width: 98%;
    margin: 0 auto;
  }
`;

const TitleRow = styled(RowBetween)`
  margin-bottom: 20px;
  height: 50px;
  align-items: flex-end;
  color: #FFF;
  padding: 0 1rem;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    padding: 0 1.75rem;
  };
`;

const Content = styled.main`
  display: flex;
  background-color: ${colors.container};
  flex-direction: column;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  overflow: hidden;
  position: relative;
  border-radius: 16px;
  border: 1px solid ${colors.defaultBorderColor};  
`;

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 36px;
  max-width: 340px;
  min-height: 25vh;
  gap: 36px;
`;

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;

const InboxIcon = styled(Inbox)`
  ${IconStyle}
`;

const PositionsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;


const Table = styled.div`
  width: 100%;
  border-radius: 8px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  color: #616161;
  box-sizing: border-box;

  & > * {
    border-bottom: 1px solid ${colors.defaultBorderColor};
  }

  & > *:last-child {
    border-bottom: none;
  }
`;

const TableHeaderRow = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: ${props => props.isMobile ? '1fr 1.5fr' : '0.2fr 1fr 1.5fr 1.5fr 1fr'};
  gap: ${props => props.isMobile ? '3.5rem' : '1.5rem'};
  padding: ${props => props.isMobile ? '1rem 1rem 0.75rem 1rem' : '1rem 1.5rem 0.75rem 1.5rem'};
  text-align: left;
  color: ${colors.darkText};
  border-bottom: 1px solid ${colors.defaultBorderColor};
  width: 100%;
  box-sizing: border-box;
`;

const ColumnHeader = styled.div`
  text-align: left;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  @media (max-width: 600px) {
    font-size: 13px;
  };
`;

const ActionsColumnHeader = styled(ColumnHeader)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PositionRowStyled = styled.div`
  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;
