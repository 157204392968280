import { axios } from './axios.js';
export function request(config = {}) {
    return axios.request(config);
}
export function isAPIError(error) {
    return error && error.cause === 'APIError';
}
export class APIError extends Error {
    constructor(message = 'Unknown Reason', statusCode, rawError, type = 'APIError', options = {}) {
        super(message, { ...options, cause: 'APIError' });
        Object.defineProperty(this, "type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "statusCode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "rawError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.name = 'APIError';
        this.type = type;
        this.statusCode = statusCode;
        this.rawError = rawError;
    }
}
