import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import useSmartContracts from '@hooks/contexts/useSmartContracts';

export default function useWithdrawDeposit(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */
  const { escrowAddress, escrowAbi } = useSmartContracts();

  /*
   * State
   */
  const [depositIdInput, setDepositIdInput] = useState<number | null>(null);
  const [shouldConfigureWithdrawWrite, setShouldConfigureWithdrawWrite] = useState<boolean>(false);

  /*
   * Contract Writes
   */
  const { config: writeWithdrawConfig } = usePrepareContractWrite({
    address: escrowAddress,
    abi: escrowAbi,
    functionName: 'withdrawDeposit',
    args: [
      depositIdInput,
    ],
    onError(error: any) {
      console.log('writeWithdrawConfig failed: ', error.message);
      setShouldConfigureWithdrawWrite(false);
      onError?.(error);
    },
    enabled: shouldConfigureWithdrawWrite,
    request: {
      maxPriorityFeePerGas: (existing: bigint) => existing * BigInt(2),
    },
  });

  const {
    data: submitWithdrawResult,
    status: signWithdrawTransactionStatus,
    writeAsync: writeWithdrawAsync,
  } = useContractWrite(writeWithdrawConfig);

  const {
    status: mineWithdrawTransactionStatus,
  } = useWaitForTransaction({
    hash: submitWithdrawResult ? submitWithdrawResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeWithdrawAsync successful: ', data);
      setShouldConfigureWithdrawWrite(false);
      onSuccess?.(data);
    },
  });

  return {
    writeWithdrawAsync,
    depositIdInput,
    setDepositIdInput,
    shouldConfigureWithdrawWrite,
    setShouldConfigureWithdrawWrite,
    signWithdrawTransactionStatus,
    mineWithdrawTransactionStatus,
    transactionHash: submitWithdrawResult?.hash,
  };
}
