import { encodeFunctionData } from 'viem';
export default function prepareCallTransaction(request) {
    const { abi, functionName, args } = request;
    const data = encodeFunctionData({ abi, functionName, args });
    return {
        to: request.address,
        value: request?.value?.toString() ?? '0',
        data: data
    };
}
