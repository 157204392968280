import { arbitrum, arbitrumNova, base, baseGoerli, mainnet, optimism, sepolia, zora } from 'viem/chains';
import { LogLevel, log as logUtil } from './utils/logger.js';
import * as actions from './actions/index.js';
import * as utils from './utils/index.js';
import { MAINNET_RELAY_API } from './constants/servers.js';
let _client;
const _backupChains = [
    mainnet,
    base,
    zora,
    optimism,
    arbitrum,
    arbitrumNova
].map((chain) => utils.convertViemChainToRelayChain(chain));
const _backupTestnetChains = [sepolia, baseGoerli].map((chain) => utils.convertViemChainToRelayChain(chain));
export class RelayClient {
    log(message, level = LogLevel.Info) {
        return logUtil(message, level, this.logLevel);
    }
    constructor(options) {
        Object.defineProperty(this, "baseApiUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "source", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "logLevel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "pollingInterval", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "maxPollingAttemptsBeforeTimeout", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "chains", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "utils", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: { ...utils }
        });
        Object.defineProperty(this, "actions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: actions
        });
        this.baseApiUrl = options.baseApiUrl ?? MAINNET_RELAY_API;
        this.logLevel =
            options.logLevel !== undefined ? options.logLevel : LogLevel.None;
        this.pollingInterval = options.pollingInterval;
        this.maxPollingAttemptsBeforeTimeout =
            options.maxPollingAttemptsBeforeTimeout;
        if (options.chains) {
            this.chains = options.chains;
        }
        else if (options.baseApiUrl?.includes('testnets')) {
            this.chains = _backupTestnetChains;
        }
        else {
            this.chains = _backupChains;
        }
        if (!options.source) {
            if (typeof window !== 'undefined') {
                let host = location.hostname;
                if (host.indexOf('www.') === 0) {
                    host = host.replace('www.', '');
                }
                this.source = host;
                console.warn('RelaySDK automatically generated a source based on the url, we recommend providing a source when initializing the sdk. Refer to our docs for steps on how to do this: https://docs.relay.link/references/sdk/getting-started#configuration');
            }
        }
        else {
            this.source = options.source;
        }
    }
    configure(options) {
        this.baseApiUrl = options.baseApiUrl ? options.baseApiUrl : this.baseApiUrl;
        this.source = options.source ? options.source : this.source;
        this.logLevel =
            options.logLevel !== undefined ? options.logLevel : LogLevel.None;
        this.pollingInterval = options.pollingInterval
            ? options.pollingInterval
            : this.pollingInterval;
        this.maxPollingAttemptsBeforeTimeout =
            options.maxPollingAttemptsBeforeTimeout
                ? options.maxPollingAttemptsBeforeTimeout
                : options.maxPollingAttemptsBeforeTimeout;
        if (options.chains) {
            this.chains = options.chains;
        }
    }
}
export async function configureDynamicChains() {
    try {
        const chains = await utils.fetchChainConfigs(_client.baseApiUrl);
        _client.chains = chains;
        return chains;
    }
    catch (e) {
        _client.log(['Failed to fetch remote chain configuration, falling back', e], LogLevel.Error);
        throw e;
    }
}
export function getClient() {
    return _client;
}
/**
 * Creates a Relay Client
 *
 * - Docs: https://docs.relay.link/references/sdk/createClient
 *
 * @param options - {@link RelayClientOptions}
 * @returns A Relay Client. {@link RelayClient}
 *
 * @example
 * import { createClient, LogLevel, MAINNET_RELAY_API } from '@reservoir0x/relay-sdk'
 *
 * const relayClient = createClient({
 *    baseApiUrl: MAINNET_RELAY_API,
 *    source: "YOUR-SOURCE",
 *    logLevel: LogLevel.Verbose,
 * })
 */
export function createClient(options) {
    if (!_client) {
        _client = new RelayClient(options);
    }
    else {
        _client.configure(options);
    }
    return _client;
}
