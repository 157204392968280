import React from 'react';
import styled from 'styled-components';
import { AlertTriangle, X, XOctagon } from 'react-feather';
import { colors } from '@theme/colors';
import { ThemedText } from '@theme/text';

interface WarningTextBoxProps {
  text: string;
  type?: 'warning' | 'error' | 'info';
  showCloseClick?: boolean;
  onCloseClick?: () => void;
  size?: 's' | 'm' | 'l';
}

export const WarningTextBox: React.FC<WarningTextBoxProps> = ({ 
  text, 
  type = 'warning',
  showCloseClick = false,
  onCloseClick,
  size = 'm',
}) => {
  const isError = type === 'error';
  const color = isError ? colors.warningRed : colors.darkText;
  const Icon = isError ? XOctagon : AlertTriangle;

  const iconSizes = {
    s: 20,
    m: 30,
    l: 40
  };

  const closeIconSizes = {
    s: 16,
    m: 20,
    l: 24
  };

  return (
    <Container $type={type} $size={size}>
      <Icon size={iconSizes[size]} color={color} />
      <ThemedText.BodySmall style={{ color }}>
        {text}
      </ThemedText.BodySmall>
      {showCloseClick && (
        <CloseButton onClick={onCloseClick}>
          <X size={closeIconSizes[size]} color={color} />
        </CloseButton>
      )}
    </Container>
  );
};

const Container = styled.div<{ $type?: 'warning' | 'error' | 'info', $size: 's' | 'm' | 'l' }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ $size }) => ($size === 's' ? '6px' : $size === 'l' ? '12px' : '8px')};
  padding: ${({ $size }) => ($size === 's' ? '8px' : $size === 'l' ? '16px' : '12px')};
  background-color: ${({ $type }) => 
    $type === 'error' || $type === 'warning' ? 'rgba(255, 67, 67, 0.1)' : colors.container};
  border: 1px solid ${({ $type }) => 
    $type === 'error' || $type === 'warning' ? colors.warningRed : colors.defaultBorderColor};
  border-radius: 16px;
  white-space: normal;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;