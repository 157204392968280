import { useState } from 'react';
import { BigNumber } from 'ethers';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import useSmartContracts from '@hooks/contexts/useSmartContracts';
import { erc20ABI } from 'wagmi';


export default function useTokenApprove(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */
  const { escrowAddress } = useSmartContracts();
  const {
    usdcAbi
  } = useSmartContracts();

  /*
   * State
   */
  const [tokenAddressInput, setTokenAddressInput] = useState<string | null>(null);
  const [amountToApproveInput, setAmountToApproveInput] = useState<string | null>(null);
  const [shouldConfigureApproveWrite, setShouldConfigureApproveWrite] = useState<boolean>(false);

  /*
   * Hooks
   */

  /*
   * Contract Writes
   */
  const { config: writeApproveConfig } = usePrepareContractWrite({
    address: tokenAddressInput,
    abi: erc20ABI,
    functionName: 'approve',
    args: [
      escrowAddress,
      amountToApproveInput,
    ],
    onError(error: any) {
      console.log('writeApproveConfig failed: ', error.message);
      setShouldConfigureApproveWrite(false);
      onError?.(error);
    },
    enabled: shouldConfigureApproveWrite,
    request: {
      maxPriorityFeePerGas: (existing: bigint) => existing * BigInt(2),
    },
  });

  const {
    data: submitApproveResult,
    status: signApproveTransactionStatus,
    writeAsync: writeApproveAsync,
  } = useContractWrite(writeApproveConfig);

  const {
    status: mineApproveTransactionStatus,
  } = useWaitForTransaction({
    hash: submitApproveResult ? submitApproveResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeApproveAsync successful: ', data);
      setShouldConfigureApproveWrite(false);
      onSuccess?.(data);
    },
  });

  return {
    writeApproveAsync,
    tokenAddressInput,
    setTokenAddressInput,
    amountToApproveInput,
    setAmountToApproveInput,
    shouldConfigureApproveWrite,
    setShouldConfigureApproveWrite,
    signApproveTransactionStatus,
    mineApproveTransactionStatus,
    transactionHash: submitApproveResult?.hash,
  };
}
