export const MINIMUM_REQUIRED_EXTENSION_VERSION = '0.1.6';

export const isVersionOutdated = (current: string): boolean => {
  const currentParts = current.split('.').map(Number);
  const requiredParts = MINIMUM_REQUIRED_EXTENSION_VERSION.split('.').map(Number);

  for (let i = 0; i < 3; i++) {
    if (currentParts[i] > requiredParts[i]) return false;
    if (currentParts[i] < requiredParts[i]) return true;
  }
  return false;
};