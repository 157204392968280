import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro'
import { ArrowLeft } from 'react-feather';
import { useWindowSize } from '@uidotdev/usehooks';
import Link from '@mui/material/Link';
import Confetti from 'react-confetti';
import { Execute, GetQuoteParameters, ProgressData } from '@reservoir0x/relay-sdk';

import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { RowBetween } from '@components/layouts/Row';
import { parseIntentData } from '@helpers/intentHelper';
import { commonStrings } from '@helpers/strings';
import {
  LoginStatus,
  paymentPlatformInfo,
  PaymentPlatformType,
  ProofGenerationStatus,
  ProofGenerationStatusType,
  tokenInfo,
  TokenType,
} from '@helpers/types';

import { Proof } from '@helpers/types';
import { ReclaimProofForm } from './ReclaimProofForm';
import { ExtensionProofForm } from './ExtensionProofForm';
import { encodeProofAsBytes } from '@helpers/types';

import useFulfillIntentTransaction from '@hooks/transactions/useFulfillIntent';

import { Z_INDEX } from '@theme/zIndex';

import useQuery from '@hooks/useQuery';
import useAccount from '@hooks/contexts/useAccount';
import useBalances from '@hooks/contexts/useBalance';
import useOnramperIntents from '@hooks/contexts/useOnRamperIntents';
import useSmartContracts from '@hooks/contexts/useSmartContracts';
import useRelayBridge, { ParsedQuoteData, parseExecuteQuoteResponse } from '@hooks/useRelayBridge';
import { BASE_CHAIN_ID, BASE_USDC_ADDRESS } from '@helpers/constants';
import useMediaQuery from '@hooks/useMediaQuery';
import { rollbar } from '@helpers/rollbar';
import { Breadcrumb, BreadcrumbStep } from '@components/common/Breadcrumb';
import useExtensionProxyProofs from '@hooks/contexts/useExtensionProxyProofs';

const RELAY_QUOTE_REFRESH_INTERVAL = 10000; // 10 seconds

interface CompleteOrderFormProps {
  handleBackClick: () => void;
  handleGoBackToSwap: () => void;
};

export const CompleteOrderForm: React.FC<CompleteOrderFormProps> = ({
  handleBackClick,
  handleGoBackToSwap: originalHandleGoBackToSwap
}) => {
  const size = useWindowSize();

  /*
   * Context
   */

  const currentDeviceSize = useMediaQuery();
  const isMobile = currentDeviceSize === 'mobile';

  const { queryParams } = useQuery();
  const { refetchUsdcBalance } = useBalances();
  const { currentIntentView, refetchIntentView } = useOnramperIntents();
  const { isSidebarInstalled, isSidebarNeedsUpdate } = useExtensionProxyProofs();
  const { addressToPlatform, usdcAddress } = useSmartContracts();

  /*
   * State
   */

  const [paymentPlatform, setPaymentPlatform] = useState<PaymentPlatformType | null>(null);

  const [proofGenerationStatus, setProofGenerationStatus] = useState<ProofGenerationStatusType>(ProofGenerationStatus.NOT_STARTED);
  const [simulationErrorMessage, setSimulationErrorMessage] = useState<string | null>(null);

  const [paymentProof, setPaymentProof] = useState<Proof | null>(null);

  const [useExtensionFlow, setUseExtensionFlow] = useState<boolean>(false);

  const [showUseReclaimFlow, setShowUseReclaimFlow] = useState<boolean>(true);
  
  const [showConfetti, setShowConfetti] = useState<boolean>(false);

  const [bridgeQuoteToExecute, setBridgeQuoteToExecute] = useState<Execute | null>(null);
  const [bridgeQuoteFormatted, setBridgeQuoteFormatted] = useState<ParsedQuoteData | null>(null);
  const [bridgeTransactions, setBridgeTransactions] = useState<{
    txHash: string;
    chainId: number;
  }[] | null>(null);

  const [shouldPollQuote, setShouldPollQuote] = useState<boolean>(true);

  const bridgingNeeded = Boolean(
    queryParams.RECIPIENT_ADDRESS &&
    queryParams.TO_TOKEN &&
    queryParams.AMOUNT_USDC &&
    queryParams.INDICATIVE_TOKEN_AMOUNT
  );

  const [title, setTitle] = useState<string>('Payment');
  const [breadcrumbStep, setBreadcrumbStep] = useState<BreadcrumbStep>(BreadcrumbStep.AUTHENTICATE);

  /*
   * Contract writes
   */

  const onFulfillIntentSuccess = useCallback((data: any) => {
    console.log('Fulfill intent success:', data);
    if (bridgingNeeded) {
      setProofGenerationStatus(ProofGenerationStatus.SWAP_QUOTE_REQUESTING);
    } else {
      setProofGenerationStatus(ProofGenerationStatus.DONE);
    }

    refetchUsdcBalance?.();

    refetchIntentView?.();
  }, [
    refetchUsdcBalance,
    refetchIntentView,
    bridgingNeeded,
  ]);

  const onPrepareFulfillIntentError = useCallback((error: any) => {
    setProofGenerationStatus(ProofGenerationStatus.TRANSACTION_SIMULATION_FAILED);
      
    const errorMessage = error?.message || '';
    const reasonMatch = errorMessage.match(/reason:\s*([^"\n]+)/);
    const reason = reasonMatch ? reasonMatch[1].trim() : 'Unknown error';
    setSimulationErrorMessage(reason);

    // Log proof gen failures in Rollbar
    rollbar.error('Intent simulation failed', {
      error: error,
      intentHash: currentIntentView?.intentHash
    });
  }, [currentIntentView, setSimulationErrorMessage]);

  const {
    writeFulfillIntentAsync,
    intentHashInput,
    paymentProofInput,
    setIntentHashInput,
    setPaymentProofInput,
    setShouldConfigureFulfillIntentWrite,
    signFulfillIntentTransactionStatus,
    mineFulfillIntentTransactionStatus,
    transactionHash,
  } = useFulfillIntentTransaction(onFulfillIntentSuccess, onPrepareFulfillIntentError);

  const {
    getRelayQuote,
    executeRelayQuote,
  } = useRelayBridge();
 
  /*
  * Effects
  */

  useEffect(() => {
    setShouldConfigureFulfillIntentWrite(proofGenerationStatus === ProofGenerationStatus.TRANSACTION_CONFIGURED);
  }, [proofGenerationStatus, setShouldConfigureFulfillIntentWrite]);

  useEffect(() => {
    if (currentIntentView) {
      const intentData = parseIntentData(currentIntentView, addressToPlatform);
      
      if (isMobile) {
        setUseExtensionFlow(false);
        setShowUseReclaimFlow(true);
      } else {
        setUseExtensionFlow(true);
        setShowUseReclaimFlow(false);
      }

      setIntentHashInput(currentIntentView.intentHash);
      setPaymentPlatform(intentData.paymentPlatform);
    }
  }, [currentIntentView, setIntentHashInput, isMobile]);

  // Fulfill intent transaction effects
  useEffect(() => {
    const didFulfillIntentTransactionSucceed = mineFulfillIntentTransactionStatus === 'success';
    if (!didFulfillIntentTransactionSucceed) {
      const paymentProofSelected = paymentProofInput !== null;
      const intentAndPaymentProofSelected = intentHashInput !== null && paymentProofSelected;

      if (intentAndPaymentProofSelected) {
        const signingTransaction = signFulfillIntentTransactionStatus === 'loading';
        const miningTransaction = mineFulfillIntentTransactionStatus === 'loading';

        if (signingTransaction) {
          setProofGenerationStatus(ProofGenerationStatus.TRANSACTION_LOADING);
        } else if (miningTransaction) {
          setProofGenerationStatus(ProofGenerationStatus.TRANSACTION_MINING);
        } else {
          setProofGenerationStatus(ProofGenerationStatus.TRANSACTION_CONFIGURED);
        }
      } else {
        setProofGenerationStatus(ProofGenerationStatus.NOT_STARTED);
      }
    }
  }, [
    paymentProofInput,
    intentHashInput,
    signFulfillIntentTransactionStatus,
    mineFulfillIntentTransactionStatus,
  ]);

  useEffect(() => {
    if (paymentProof) {

      try {
        const encodedProof = encodeProofAsBytes(paymentProof);

        setPaymentProofInput(encodedProof);
      } catch (error) {
        console.error('Error encoding proof: ', error);
        
        setProofGenerationStatus(ProofGenerationStatus.ERROR_FAILED_TO_PROVE);
      }
    }
  }, [paymentProof]);


  useEffect(() => {
    if (proofGenerationStatus === ProofGenerationStatus.DONE) {
      setShowConfetti(true);
      setShouldPollQuote(false);

      setTimeout(() => {
        setShowConfetti(false);
      }, 5000);
    }
  }, [proofGenerationStatus])

  useEffect(() => {
    const fetchBridgeQuote = async () => {
      try {
        const destinationChainId = tokenInfo[queryParams.TO_TOKEN as TokenType].networkChainId;
        const tokenAddress = tokenInfo[queryParams.TO_TOKEN as TokenType].address;
  
        const params: GetQuoteParameters = {
          recipient: queryParams.RECIPIENT_ADDRESS as string,
          chainId: BASE_CHAIN_ID, // Always Base
          toChainId: destinationChainId,
          currency: BASE_USDC_ADDRESS, // Always Base USDC
          toCurrency: tokenAddress,
          amount: queryParams.AMOUNT_USDC as string, // in wei
          tradeType: 'EXACT_INPUT'
        };
  
        const quoteRes = await getRelayQuote(params);
        setBridgeQuoteToExecute(quoteRes);

        const quoteData = parseExecuteQuoteResponse(quoteRes, queryParams.TO_TOKEN as TokenType);
        setBridgeQuoteFormatted(quoteData);
        
        setProofGenerationStatus(ProofGenerationStatus.SWAP_QUOTE_SUCCESS);
      } catch (err) {
        console.error('Error bridging flow:', err);
        setBridgeQuoteToExecute(null);
        setBridgeQuoteFormatted(null);
  
        setProofGenerationStatus(ProofGenerationStatus.SWAP_QUOTE_FAILED);
      }
    };

    const fetchNewQuote = async () => {
      await new Promise(resolve => setTimeout(resolve, 5000));

      fetchBridgeQuote();
    }

    let intervalId: NodeJS.Timeout;

    if (shouldPollQuote && (
        proofGenerationStatus === ProofGenerationStatus.SWAP_QUOTE_REQUESTING ||
        proofGenerationStatus === ProofGenerationStatus.SWAP_QUOTE_SUCCESS ||
        proofGenerationStatus === ProofGenerationStatus.SWAP_QUOTE_FAILED ||
        proofGenerationStatus === ProofGenerationStatus.SWAP_TRANSACTION_FAILED
      )) {
      console.log('Fetching bridge quote because quote status is', proofGenerationStatus);
      // Initial fetch
      fetchBridgeQuote();
      
      // Set up interval
      intervalId = setInterval(fetchBridgeQuote, RELAY_QUOTE_REFRESH_INTERVAL);
    }

    if (proofGenerationStatus === ProofGenerationStatus.SWAP_TRANSACTION_FAILED) {
      console.log('Fetching bridge quote because transaction failed');
      fetchNewQuote();
    }

    // Cleanup interval on unmount or when status changes
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [
    proofGenerationStatus,
    shouldPollQuote
  ]);


  /*
   * Handlers
   */

  const handleGoBackToSwap = useCallback(() => {
    setShouldPollQuote(true);
    originalHandleGoBackToSwap();
  }, [originalHandleGoBackToSwap]);

  const handleBackButtonClick = useCallback(() => {
    if (proofGenerationStatus === ProofGenerationStatus.DONE) {
      handleGoBackToSwap();
    } else {
      handleBackClick();
    }
  }, [handleBackClick, handleGoBackToSwap, proofGenerationStatus]);

  const handleCompleteOrderClick = useCallback(async () => {
    try {
      await writeFulfillIntentAsync();
    } catch (error) {
      console.error('Error completing order: ', error);
    }
  }, [writeFulfillIntentAsync, bridgeQuoteToExecute, bridgingNeeded]);

  const handleUseExtensionFlowClick = () => {
    setUseExtensionFlow(true);
  }

  const handleUseReclaimFlowClick = () => {
    setUseExtensionFlow(false);
  }

  const handleBridgeProgress = useCallback((progress: ProgressData) => {
    const { currentStepItem, txHashes, error } = progress;

    if (error) {
      console.log('Bridge error found:', error);
      setProofGenerationStatus(ProofGenerationStatus.SWAP_TRANSACTION_FAILED);

      // Log bridge failures in Rollbar
      rollbar.error('Relay bridge error', {
        error,
        intentHash: currentIntentView?.intentHash
      });
      return;
    }

    if (currentStepItem?.progressState === 'validating' && txHashes?.length && txHashes?.length === 1) {
      setProofGenerationStatus(ProofGenerationStatus.DONE);
      setBridgeTransactions(txHashes);
    }

    // DON'T WAIT FOR THE SECOND TRANSACTION TO COMPLETE
    // if (currentStepItem?.progressState === 'validating' && txHashes?.length && txHashes?.length > 1) {
    //   setProofGenerationStatus(ProofGenerationStatus.DONE);
    //   setBridgeTransactions(txHashes);
    // }
  }, [currentIntentView]);


  const handleSubmitSwapClick = useCallback(async () => {
    try {
      if (!bridgeQuoteToExecute) {
        throw new Error('No bridge quote to execute');
      }

      setProofGenerationStatus(ProofGenerationStatus.SWAP_TRANSACTION_SIGNING);
      await executeRelayQuote(bridgeQuoteToExecute, handleBridgeProgress);
    } catch (error) {
      console.error('Error executing bridge quote: ', error);

      setProofGenerationStatus(ProofGenerationStatus.SWAP_TRANSACTION_FAILED);

      // Log bridge failures in Rollbar
      rollbar.error('Relay bridge error', {
        error,
        intentHash: currentIntentView?.intentHash
      });
    }
  }, [bridgeQuoteToExecute, executeRelayQuote, handleBridgeProgress, currentIntentView]);

  
  /*
   * Component
   */

  return (
    <Container>
      {showConfetti ? (
        <ConfettiContainer>
          <Confetti
            recycle={false}
            numberOfPieces={500}
            width={size.width ?? undefined}
            height={document.documentElement.scrollHeight}
          />
        </ConfettiContainer>
      ) : null}

      <TitleContainer>
        <StyledRowBetween>
          <div style={{ flex: 0.25 }}>
            <button
              onClick={handleBackButtonClick}
              style={{ background: 'none', border: 'none', cursor: 'pointer' }}
            >
              <StyledArrowLeft/>
            </button>
          </div>

          <ThemedText.HeadlineSmall style={{ flex: '1', margin: 'auto', textAlign: 'center' }}>
            {title}
          </ThemedText.HeadlineSmall>

          <div style={{ flex: 0.2 }}/>
        </StyledRowBetween>

        <Breadcrumb
          currentStep={breadcrumbStep}
          showExtensionStep={!isSidebarInstalled || isSidebarNeedsUpdate}
        />
      </TitleContainer>

      {paymentPlatform && intentHashInput && (
        !useExtensionFlow ?
          <ReclaimProofForm
            intentHash={intentHashInput}
            paymentPlatform={paymentPlatform}
            paymentProof={paymentProof}
            setPaymentProof={setPaymentProof}
            proofGenerationStatus={proofGenerationStatus}
            setProofGenerationStatus={setProofGenerationStatus}
            handleCompleteOrderClick={handleCompleteOrderClick}
            handleGoBackToSwap={handleGoBackToSwap}
            completeOrderTransactionSigningStatus={signFulfillIntentTransactionStatus}
            completeOrderTransactionMiningStatus={mineFulfillIntentTransactionStatus}
            completeOrderTransactionHash={transactionHash}
            handleUseExtensionFlowClick={handleUseExtensionFlowClick}
            bridgingNeeded={bridgingNeeded}
            quoteData={bridgeQuoteFormatted}
            bridgeTransactions={bridgeTransactions}
            handleSubmitSwapClick={handleSubmitSwapClick}
            simulationErrorMessage={simulationErrorMessage}
            setTitle={setTitle}
            setBreadcrumbStep={setBreadcrumbStep}
          /> : 
          <ExtensionProofForm
            intentHash={intentHashInput}
            paymentPlatform={paymentPlatform}
            paymentProof={paymentProof}
            setPaymentProof={setPaymentProof}
            proofGenerationStatus={proofGenerationStatus}
            setProofGenerationStatus={setProofGenerationStatus}
            handleCompleteOrderClick={handleCompleteOrderClick}
            handleGoBackToSwap={handleGoBackToSwap}
            completeOrderTransactionSigningStatus={signFulfillIntentTransactionStatus}
            completeOrderTransactionMiningStatus={mineFulfillIntentTransactionStatus}
            completeOrderTransactionHash={transactionHash}
            showUseReclaimFlow={showUseReclaimFlow}
            handleUseReclaimFlowClick={handleUseReclaimFlowClick}
            bridgingNeeded={bridgingNeeded}
            quoteData={bridgeQuoteFormatted}
            bridgeTransactions={bridgeTransactions}
            handleSubmitSwapClick={handleSubmitSwapClick}
            simulationErrorMessage={simulationErrorMessage}
            setTitle={setTitle}
            setBreadcrumbStep={setBreadcrumbStep}
          />
        )
      }
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  padding: 1.5rem;
  background-color: ${colors.container};
  border: 1px solid ${colors.defaultBorderColor};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;

  @media (min-width: 600px) {
    border-radius: 16px;
    width: 400px;
  }

  @media (max-width: 600px) {
    width: 98%;
    margin: 0 auto;
    box-sizing: border-box;
  }
`;

const ConfettiContainer = styled.div`
  z-index: ${Z_INDEX.confetti};
`;


const TitleContainer = styled.div`
  padding: 0;
  width: 100%;
`;


const StyledArrowLeft = styled(ArrowLeft)`
  color: ${colors.white};
`;

const StyledRowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0;
  
  > div:first-child {
    padding-left: 0.5rem;
  }
  
  > div:last-child {
    padding-right: 0.5rem;
  }
`;