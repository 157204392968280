import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { ChevronRight, Trash2 } from 'react-feather';
import { currencyInfo } from '@helpers/types/currency';
import { tokenInfo } from '@helpers/types/tokens';
import { parseIntentData } from '@helpers/intentHelper';

import useQuery from '@hooks/useQuery';
import useSmartContracts from '@hooks/contexts/useSmartContracts';
import useOnRamperIntents from '@hooks/contexts/useOnRamperIntents';
import { AccessoryButton } from '@components/common/AccessoryButton';
import useMediaQuery from "@hooks/useMediaQuery";
import useCancelIntent from '@hooks/transactions/useCancelIntent';
import { ConfirmCancelIntent } from '@components/modals/ConfirmCancelIntent';
import { paymentPlatformInfo } from '@helpers/types/paymentPlatform';
import { ETH_CHAIN_ID, SOLANA_CHAIN_ID, POLYGON_CHAIN_ID } from '@helpers/constants';
import { PaymentPlatformType } from '@helpers/types/paymentPlatform';


interface OnRamperIntentInfoProps {
  onCompleteOrderClick: () => void;
}

export const OnRamperIntentInfo: React.FC<OnRamperIntentInfoProps> = ({ onCompleteOrderClick }) => {

  /*
   * Contexts
   */

  const isMobile = useMediaQuery() === 'mobile';
  const { currentIntentView, refetchIntentView } = useOnRamperIntents();
  const { addressToPlatform } = useSmartContracts();
  const { queryParams } = useQuery();

  /*
   * State
   */

  const [showCancelModal, setShowCancelModal] = useState(false);

  /*
   * Hooks
   */

  const onCancelIntentSuccess = useCallback(() => {
    refetchIntentView?.();
  }, [refetchIntentView]);

  const {
    writeCancelIntentAsync,
    setIntentHashInput,
    shouldConfigureCancelIntentWrite,
    setShouldConfigureCancelIntentWrite,
    signCancelIntentTransactionStatus,
    mineCancelIntentTransactionStatus,
  } = useCancelIntent(onCancelIntentSuccess);

  /*
   * Effects
   */

  useEffect(() => {
    if (currentIntentView) {
      setIntentHashInput(currentIntentView.intentHash);
    }
  }, [currentIntentView, setIntentHashInput]);

  useEffect(() => {
    const executeCancelIntent = async () => {
      const statusForExecution = signCancelIntentTransactionStatus === 'idle' || signCancelIntentTransactionStatus === 'error';

      if (shouldConfigureCancelIntentWrite && writeCancelIntentAsync && statusForExecution) {
        try {
          await writeCancelIntentAsync();
        } catch (error) {
          console.error('writeCancelIntentAsync failed: ', error);
          setShouldConfigureCancelIntentWrite(false);
        }
      }
    };

    executeCancelIntent();
  }, [
    shouldConfigureCancelIntentWrite,
    writeCancelIntentAsync,
    signCancelIntentTransactionStatus,
    mineCancelIntentTransactionStatus,
    setShouldConfigureCancelIntentWrite,
  ]);

  /*
   * Handlers
   */

  const handleShowCancelModalClick = (e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }
    setShowCancelModal(true);
  };

  const handleCancelClick = () => {
    setShouldConfigureCancelIntentWrite(true);
  };

  const handleCancelModalClose = () => {
    setShowCancelModal(false);
  };

  /*
   * Helpers
   */

  
  // Get output token from query params if available, otherwise from parsed intent
  const parsedIntent = currentIntentView ? parseIntentData(currentIntentView, addressToPlatform) : null;
  const outputToken = queryParams.TO_TOKEN || (parsedIntent?.receiveToken || '');
  const tokenLogo = tokenInfo[outputToken].icon;
  const inputCurrency = parsedIntent?.sendCurrency || '';

  // Only render if we have both currencies
  if (!inputCurrency || !outputToken) {
    return null;
  }

  const getSendCurrencyLabel = () => {
    if (!parsedIntent) return '';
    const currencySymbol = currencyInfo[parsedIntent.sendCurrency].currencySymbol;
    const paymentPlatformName = paymentPlatformInfo[parsedIntent.paymentPlatform as PaymentPlatformType].platformName;
    return `${currencySymbol}${parsedIntent.amountFiatToSend} on ${paymentPlatformName}`;
  };

  const getReceiveTokenLabel = () => {
    if (!parsedIntent) return '';

    const getChainName = (token: string) => {
      const chainId = tokenInfo[token].networkChainId;
      if (chainId === ETH_CHAIN_ID) return 'Ethereum';
      if (chainId === SOLANA_CHAIN_ID) return 'Solana';
      if (chainId === POLYGON_CHAIN_ID) return 'Polygon';
      return 'Base';
    };

    // Only if all the query params are present
    if (queryParams.TO_TOKEN && queryParams.INDICATIVE_TOKEN_AMOUNT) {
      const chainName = getChainName(queryParams.TO_TOKEN);
      return `~${queryParams.INDICATIVE_TOKEN_AMOUNT} ${queryParams.TO_TOKEN} on ${chainName}`;
    }
    
    const chainName = getChainName(parsedIntent.receiveToken);
    return `${parsedIntent.amountTokenToReceive} ${parsedIntent.receiveToken} on ${chainName}`;
  };

  return (
    <Container onClick={onCompleteOrderClick}>
      <TitleContainer>
        <ThemedText.BodySecondary>
          Active transactions
        </ThemedText.BodySecondary>
        <AccessoryButton
          onClick={handleShowCancelModalClick}
          height={36}
          loading={signCancelIntentTransactionStatus === 'loading' || mineCancelIntentTransactionStatus === 'loading'}
          title={'Cancel'}
          icon={isMobile ? undefined : 'trash'}
        />
      </TitleContainer>

      <TransactionCardAndTrashContainer>
        <LogoContainer>
          <IconStack>
            <FlagIcon className={`fi fi-${currencyInfo[inputCurrency].countryCode}`} />
            <TokenIcon src={tokenLogo} />
            <ChainIconWrapper>
              <ChainIcon src={tokenInfo[outputToken].chainIcon} />
            </ChainIconWrapper>
          </IconStack>
        </LogoContainer>

        <TransactionCard>
          <TokenSection>
            <TokenSymbol>{inputCurrency}</TokenSymbol>
            <ArrowIcon>→</ArrowIcon>
            <TokenSymbol>{outputToken}</TokenSymbol>
          </TokenSection>

          <ThemedText.BodySecondary style={{ color: colors.lightGrayText }} fontSize="14px">
            {getSendCurrencyLabel()} → {getReceiveTokenLabel()}
          </ThemedText.BodySecondary>
        </TransactionCard>

        <ButtonsContainer>
          {/* <AccessoryButton
            onClick={handleShowCancelModalClick}
            height={36}
            loading={signCancelIntentTransactionStatus === 'loading' || mineCancelIntentTransactionStatus === 'loading'}
            title={'Cancel'}
            icon={isMobile ? undefined : 'trash'}
          /> */}
          {/* <AccessoryButton
            onClick={handleShowCancelModalClick}
            height={36}
            loading={signCancelIntentTransactionStatus === 'loading' || mineCancelIntentTransactionStatus === 'loading'}
            title={'Complete'}
            icon={isMobile ? undefined : 'trash'}
          /> */}
        </ButtonsContainer>

      </TransactionCardAndTrashContainer>

      {showCancelModal && (
        <ConfirmCancelIntent
          onBackClick={handleCancelModalClose}
          onCancelClick={handleCancelClick}
          signCancelIntentTransactionStatus={signCancelIntentTransactionStatus}
          mineCancelIntentTransactionStatus={mineCancelIntentTransactionStatus}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 16px;
  background-color: ${colors.container};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  border: 1px solid ${colors.buttonDefault};
  cursor: pointer;
  
  &:hover {
    background-color: ${colors.selectorHover}
  }
`;

const TransactionCard = styled.div`
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const TokenSection = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const TokenSymbol = styled(ThemedText.BodyPrimary)`
  font-weight: 500;
`;

const ArrowIcon = styled.span`
  font-size: 16px;
  color: ${colors.darkText};
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconStack = styled.div`
  position: relative;
  width: 58px;
  height: 24px;
`;

const FlagIcon = styled.span`
  position: absolute;
  left: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
  background-size: 150%;
  background-position: center;
  z-index: 1;
`;

const TokenIcon = styled.img`
  position: absolute;
  left: 20px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  z-index: 2;
`;

const ChainIconWrapper = styled.div`
  position: absolute;
  bottom: -12px;
  right: 1px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #1E2230;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #1E2230;
  z-index: 3;
`;

const ChainIcon = styled.img`
  width: 10px;
  height: 10px;
  border-radius: 10%;
`;

const TransactionCardAndTrashContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;