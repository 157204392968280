import { PRECISION, SECONDS_IN_DAY } from "./constants";

import { BigNumber } from "ethers";
import { PaymentPlatform, PaymentPlatformType } from "./types";
import { EscrowIntentView } from "@helpers/types";
import { Token, tokenInfo } from "@helpers/types";
import { tokenUnitsToReadable } from "@helpers/units";
import { getCurrencyInfoFromHash } from "@helpers/types";


export interface ParsedIntentData {
  paymentPlatform: string;
  depositorOnchainPayeeDetails: string;
  receiveToken: string;
  amountTokenToReceive: string;
  sendCurrency: string;
  amountFiatToSend: string;
  expirationTimestamp: string;
  intentTimestamp: string;
  recipientAddress: string;
}


export const parseIntentData = (intentView: EscrowIntentView, addressToPlatform: { [key: string]: PaymentPlatformType }): ParsedIntentData => {
  const receiveTokenAddress = intentView.deposit.deposit.token;
  const receiveToken = Token.USDC;    // hardcoded for now
  const tokenDecimals = tokenInfo[receiveToken].tokenDecimals;
  const receiveTokenAmount = intentView.intent.amount;

  // Calculate amount of fiat to send
  const tokenToFiatConversionRate = intentView.intent.conversionRate;
  const amountFiatToSend = calculateFiatFromRequestedUSDC(
    receiveTokenAmount,
    tokenToFiatConversionRate,
    tokenDecimals
  );

  const intentTimestamp = intentView.intent.timestamp.toString();

  // Calculate expiration timestamp
  const expirationTimestamp = formatExpiration(intentView.intent.timestamp);

  const recipientAddress = intentView.intent.to;

  const sendCurrency = getCurrencyInfoFromHash(intentView.intent.fiatCurrency)?.currencyCode || '';

  const verifier = intentView?.deposit.verifiers.filter(
    (v: any) => v.verifier === intentView?.intent.paymentVerifier
  )[0];
  const depositorOnchainPayeeDetails = verifier?.verificationData.payeeDetails || '';

  const paymentPlatform = addressToPlatform[intentView?.intent.paymentVerifier] || PaymentPlatform.VENMO;

  const sanitizedIntent: ParsedIntentData = {
    paymentPlatform: paymentPlatform,
    depositorOnchainPayeeDetails,
    receiveToken: receiveToken,
    amountTokenToReceive: tokenUnitsToReadable(receiveTokenAmount, tokenDecimals),
    amountFiatToSend: tokenUnitsToReadable(amountFiatToSend, tokenDecimals),
    intentTimestamp,
    expirationTimestamp,
    recipientAddress,
    sendCurrency
  };

  return sanitizedIntent;
}


export const calculateFiatFromRequestedUSDC = (tokenAmount: BigNumber, tokenToFiatConversionRate: BigNumber, tokenDecimals: number): BigNumber => {
  const rawFiatAmount = tokenAmount.mul(tokenToFiatConversionRate).div(PRECISION);
  const pennyInTokenUnits = BigNumber.from(10).pow(tokenDecimals - 2);
  const remainder = rawFiatAmount.mod(pennyInTokenUnits);

  if (remainder.gt(0)) {
    return rawFiatAmount.sub(remainder).add(pennyInTokenUnits);
  } else {
    return rawFiatAmount;
  }
};

export function calculateExpiration(unixTimestamp: BigNumber, timePeriod: BigNumber): BigNumber {
  return unixTimestamp.add(timePeriod);
}

export function formatExpiration(unixTimestamp: BigNumber): string {
  const unixTimestampPlusOneDay = calculateExpiration(unixTimestamp, SECONDS_IN_DAY);

  const currentTimestamp = BigNumber.from(Math.floor(Date.now() / 1000));
  if (currentTimestamp > unixTimestampPlusOneDay) {
    return "Expired";
  } else {
    const date = new Date(Number(unixTimestampPlusOneDay) * 1000);
    const formattedTime = date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
    const formattedDate = date.toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' }).split('/').slice(0, 2).join('/');

    return `${formattedTime} on ${formattedDate}`;
  }
}

