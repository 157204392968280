import React, { useEffect, useMemo, useState, useRef } from "react";
import styled from 'styled-components';
import { ArrowLeft } from 'react-feather';
import { useWindowSize } from '@uidotdev/usehooks';

import { Button } from "@components/common/Button";
import { LabeledSwitch } from "@components/common/LabeledSwitch";
import { Overlay } from '@components/modals/Overlay';
import { LabeledTextArea } from '@components/legacy/LabeledTextArea';
import { VerificationStepRow, VerificationState, VerificationStepType } from "@components/modals/VerificationStepRow";
import { commonStrings } from "@helpers/strings";
import { LoginStatus, ProofGenerationStatus, tokenInfo } from  "@helpers/types";
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import useAccount from '@hooks/contexts/useAccount';
import useSmartContracts from "@hooks/contexts/useSmartContracts";
import useMediaQuery from "@hooks/useMediaQuery";
import { Z_INDEX } from '@theme/zIndex';
import { PaymentPlatformType, PaymentPlatform } from "@helpers/types";
import { SwapDetails } from "@components/Swap/CompleteOrder/SwapDetails";
import { ParsedQuoteData } from "@hooks/useRelayBridge";
import { AccessoryButton } from "@components/common/AccessoryButton";
import reclaimSvg from '../../assets/images/reclaim.svg'
import Spinner from '@components/common/Spinner';
import useOnRamperIntents from "@hooks/contexts/useOnRamperIntents";
import { ParsedIntentData, parseIntentData } from "@helpers/intentHelper";
import { ZKP2P_TG_SUPPORT_CHAT_LINK } from "@helpers/docUrls";
import useBackend from "@hooks/contexts/useBackend";

interface ProvePaymentProps {
  title: string;
  proof: string;
  onBackClick: () => void
  status: string;
  platform: PaymentPlatformType;
  buttonTitle: string;
  submitTransactionStatus: string;
  isSubmitMining: boolean;
  handleSubmitVerificationClick?: () => void;
  handleSubmitSwapClick?: () => void;
  setProofGenStatus?: (status: string) => void;
  onProofGenCompletion?: () => void;
  isAppclipFlow?: boolean;
  appclipRequestURL?: string;
  transactionAddress?: string | null;
  provingFailureErrorCode: number | null;
  bridgingNeeded: boolean;
  quoteData: ParsedQuoteData | null;
  bridgeTransactions: {
    txHash: string;
    chainId: number;
  }[] | null;
  displayType?: 'modal' | 'page';
  simulationErrorMessage: string | null;
}

export const ProvePayment: React.FC<ProvePaymentProps> = ({
  title,
  proof,
  onBackClick,
  status,
  platform,
  buttonTitle,
  submitTransactionStatus,
  isSubmitMining,
  transactionAddress,
  setProofGenStatus,
  handleSubmitVerificationClick = () => {},
  handleSubmitSwapClick = () => {},
  onProofGenCompletion,
  provingFailureErrorCode,
  isAppclipFlow,
  appclipRequestURL,
  bridgingNeeded,
  quoteData,
  bridgeTransactions,
  displayType = 'modal',
  simulationErrorMessage
}) => {

  ProvePayment.displayName = "ProvePaymentModal";
  /*
   * Context
   */

  const { loginStatus } = useAccount();
  const size = useWindowSize();
  const isMobile = useMediaQuery() === 'mobile';
  const { blockscanUrl, solanaBlockscanUrl, addressToPlatform } = useSmartContracts();
  const { currentIntentView } = useOnRamperIntents();
  const { depositorTgUsername } = useBackend();

  /*
   * State
   */


  const [intentData, setIntentData] = useState<ParsedIntentData | null>(null);
  const [shouldShowProofAndSignals, setShouldShowProofAndSignals] = useState<boolean>(false);

  const [ctaButtonTitle, setCtaButtonTitle] = useState<string>("");

  const [showAccessoryCta, setShowAccessoryCta] = useState<boolean>(false);
  const [showPoweredByReclaim, setShowPoweredByReclaim] = useState<boolean>(isMobile);

  /*
   * Handlers
   */

  const handleOverlayClick = () => {
    onBackClick();
  }

  /*
   * Hooks
   */

  useEffect(() => {
    if (currentIntentView) {
      const intentData = parseIntentData(currentIntentView, addressToPlatform);
      setIntentData(intentData);
    }
  }, [currentIntentView, addressToPlatform]);

  useEffect(() => {
    if (isMobile) {
      setShowPoweredByReclaim(true);
    }
  }, [isMobile]);

  useEffect(() => {
    if (setProofGenStatus) {
      switch (submitTransactionStatus) {
        case "error":
          setProofGenStatus(ProofGenerationStatus.TRANSACTION_CONFIGURED);
          break;

        case "loading":
          setProofGenStatus(ProofGenerationStatus.TRANSACTION_LOADING);
          break;

        default:
          break;
      }
    }
  }, [submitTransactionStatus, setProofGenStatus]);

  useEffect(() => {
    if (isSubmitMining && setProofGenStatus) {
      setProofGenStatus(ProofGenerationStatus.TRANSACTION_MINING);
    }
  }, [isSubmitMining, setProofGenStatus]);

  useEffect(() => {
    switch (status) {

      case ProofGenerationStatus.NOT_STARTED:
      case ProofGenerationStatus.REQUESTING_PROOF:
        if (isAppclipFlow) {
          if (isMobile) {
            setCtaButtonTitle("Generating Link");
          } else {
            setCtaButtonTitle("Generating QR code");
          }
        } else {
          setCtaButtonTitle("Requesting Notarization");
        }
        break;

      case ProofGenerationStatus.REQUESTING_PROOF_FAILED:
        if (isAppclipFlow) {
          if (isMobile) {
            setCtaButtonTitle("Failed to Generate Link");
          } else {
            setCtaButtonTitle("Failed to Generate QR");
          }
        } else {
          setCtaButtonTitle("Failed to Request Notarization");
        }
        break;

      case ProofGenerationStatus.REQUESTING_PROOF_SUCCESS:
        if (isAppclipFlow) {
          if (isMobile) {
            setCtaButtonTitle("Generate Proof");
          } else {
            setCtaButtonTitle("Scan QR code to verify payment");
          }
        } else {
          setCtaButtonTitle("Complete Order");
        }
        break;

      case ProofGenerationStatus.GENERATING_PROOF:
        setCtaButtonTitle("Verifying Payment");
        break;

      case ProofGenerationStatus.TRANSACTION_CONFIGURED:
        setCtaButtonTitle("Complete Order");
        break;

      case ProofGenerationStatus.TRANSACTION_LOADING:
        setCtaButtonTitle("Signing Transaction");
        break;

      case ProofGenerationStatus.TRANSACTION_MINING:
        setCtaButtonTitle("Mining Transaction");
        break;

      case ProofGenerationStatus.ERROR_FAILED_TO_PROVE:
        switch (provingFailureErrorCode) {
          default:
            setCtaButtonTitle("Proof Gen Failed - Try again");
            break;
        }
        break;

      case ProofGenerationStatus.TRANSACTION_SIMULATION_FAILED:
        if (simulationErrorMessage) {
          setCtaButtonTitle("Verification Failed: " + simulationErrorMessage);
        } else {
          setCtaButtonTitle("Payment verification failed");
        }
        break;

      case ProofGenerationStatus.SWAP_QUOTE_REQUESTING:
        setCtaButtonTitle('Fetching Swap Quote');
        setShowAccessoryCta(true);    // show when starting to fetch swap quote
        setShowPoweredByReclaim(false); // hide powered by reclaim when onramp is completed
        break;

      case ProofGenerationStatus.SWAP_QUOTE_FAILED:
        setCtaButtonTitle('Failed to fetch swap quote');
        break;

      case ProofGenerationStatus.SWAP_QUOTE_SUCCESS:
        setCtaButtonTitle('Swap USDC to ' + tokenInfo[quoteData?.token ?? 'USDC'].ticker);
        break;

      case ProofGenerationStatus.SWAP_TRANSACTION_SIGNING:
        setCtaButtonTitle('Signing Swap Transaction');
        setShowAccessoryCta(false);    // hide when starting to user goes ahead and signs swap transaction
        break;

      case ProofGenerationStatus.SWAP_TRANSACTION_MINING:
        setCtaButtonTitle('Mining Swap Transaction');
        break;

      case ProofGenerationStatus.SWAP_TRANSACTION_FAILED:
        setCtaButtonTitle('Swap Failed. Fetching new quote');
        setShowAccessoryCta(true);    // show when swap transaction fails; give user to exit swap and keep USDC
        break;

      case ProofGenerationStatus.ERROR_FAILED_TO_PROVE:
        setCtaButtonTitle('Proof Gen Failed - Try again');
        break;

      case ProofGenerationStatus.DONE:
        setCtaButtonTitle('Go to Swap');
        break;
        
      default:
        setCtaButtonTitle(buttonTitle);
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, buttonTitle]);

  /*
   * Helpers
   */

  const ctaDisabled = useMemo(() => {
    switch (status) {
      case ProofGenerationStatus.REQUESTING_PROOF:
      case ProofGenerationStatus.REQUESTING_PROOF_FAILED:
      case ProofGenerationStatus.TRANSACTION_SIMULATION_FAILED:
      case ProofGenerationStatus.TRANSACTION_LOADING:
      case ProofGenerationStatus.TRANSACTION_MINING:
      case ProofGenerationStatus.TRANSACTION_FAILED:
      case ProofGenerationStatus.ERROR_FAILED_TO_PROVE:
      case ProofGenerationStatus.SWAP_QUOTE_REQUESTING:
      case ProofGenerationStatus.SWAP_QUOTE_FAILED:
      case ProofGenerationStatus.SWAP_TRANSACTION_SIGNING:
      case ProofGenerationStatus.SWAP_TRANSACTION_MINING:
      case ProofGenerationStatus.GENERATING_PROOF:
      case ProofGenerationStatus.SWAP_TRANSACTION_FAILED:
          return true;
          
      case ProofGenerationStatus.TRANSACTION_CONFIGURED:
      case ProofGenerationStatus.SWAP_QUOTE_SUCCESS:
      case ProofGenerationStatus.DONE:
        return false;

      case ProofGenerationStatus.REQUESTING_PROOF_SUCCESS:
        if (isAppclipFlow && isMobile) {
          return false;
        }
        return true;

      default:
        return true;
    }
  }, [status]);

  const ctaLoading = useMemo(() => {
    switch (status) {
      case ProofGenerationStatus.REQUESTING_PROOF:
      case ProofGenerationStatus.TRANSACTION_LOADING:
      case ProofGenerationStatus.TRANSACTION_MINING:
      case ProofGenerationStatus.SWAP_QUOTE_REQUESTING:
      case ProofGenerationStatus.SWAP_TRANSACTION_SIGNING:
      case ProofGenerationStatus.SWAP_TRANSACTION_MINING:
        return true;

      default:
        return false;
    }
  }, [status]);

  const getButtonHandler = () => {
    if (status === ProofGenerationStatus.DONE) {
      onProofGenCompletion?.();
    } else if (status === ProofGenerationStatus.REQUESTING_PROOF_SUCCESS) {
      if (isMobile && appclipRequestURL) {
        window.open(appclipRequestURL, '_blank');
      }
    } else if (
      status === ProofGenerationStatus.SWAP_QUOTE_SUCCESS ||
      status === ProofGenerationStatus.SWAP_TRANSACTION_FAILED
    ) {
      handleSubmitSwapClick();
    } else {
      handleSubmitVerificationClick();
    }
  };

  const getTelegramSupportLink = () => {
    if (depositorTgUsername) {
      const cleanedUsername = depositorTgUsername.startsWith('@')
        ? depositorTgUsername.slice(1)
        : depositorTgUsername;

      return `https://t.me/${cleanedUsername}`;
    }
    return ZKP2P_TG_SUPPORT_CHAT_LINK;
  }

  const handleSkipSwapClick = () => {
    onProofGenCompletion?.();
  }

  /*
   * Component
   */

  const renderVerificationSteps = () => {
    let requestStepState = VerificationState.DEFAULT;
    let proveStepState = VerificationState.DEFAULT;
    let submitStepState = VerificationState.DEFAULT;
    let swapStepState = VerificationState.DEFAULT;

    switch (status) {
      case "not-started":
      case "requesting-proof":
        requestStepState = VerificationState.LOADING;
        break;

      case "requesting-proof-success":
        requestStepState = VerificationState.COMPLETE;
        break;

      case "generating-proof":
        requestStepState = VerificationState.COMPLETE;
        proveStepState = VerificationState.LOADING;
        break;

      case "error-failed-to-prove":
        requestStepState = VerificationState.COMPLETE;
        proveStepState = VerificationState.DEFAULT;
        break;

      case "transaction-configured":
        requestStepState = VerificationState.COMPLETE;
        proveStepState = VerificationState.COMPLETE;
        break;

      case "transaction-loading":
      case "transaction-mining":
        requestStepState = VerificationState.COMPLETE;
        proveStepState = VerificationState.COMPLETE;
        submitStepState = VerificationState.LOADING;
        break;

      case "swap-quote-requesting":
      case "swap-quote-loading":
      case "swap-quote-success":
        requestStepState = VerificationState.COMPLETE;
        proveStepState = VerificationState.COMPLETE;
        submitStepState = VerificationState.COMPLETE;
        swapStepState = VerificationState.DEFAULT;
        break;

      case "swap-transaction-signing":
      case "swap-transaction-mining":
        requestStepState = VerificationState.COMPLETE;
        proveStepState = VerificationState.COMPLETE;
        submitStepState = VerificationState.COMPLETE;
        swapStepState = VerificationState.LOADING;
        break;

      case "swap-quote-failed":
      case "swap-transaction-failed":
        requestStepState = VerificationState.COMPLETE;
        proveStepState = VerificationState.COMPLETE;
        submitStepState = VerificationState.COMPLETE;
        swapStepState = VerificationState.DEFAULT;
        break;

      case "error-failed-to-prove":
        requestStepState = VerificationState.COMPLETE;
        proveStepState = VerificationState.DEFAULT;
        submitStepState = VerificationState.DEFAULT;
        break;

      case "transaction-simulation-failed":
        requestStepState = VerificationState.COMPLETE;
        proveStepState = VerificationState.COMPLETE;
        submitStepState = VerificationState.DEFAULT;
        break;

      case "done":
        requestStepState = VerificationState.COMPLETE;
        proveStepState = VerificationState.COMPLETE;
        submitStepState = VerificationState.COMPLETE;
        swapStepState = VerificationState.COMPLETE;
        break;
    }

    const verificationStepRows = [];

    let showRequestStep = true;
    if (isAppclipFlow && !isMobile) {
      showRequestStep = false;
    }

    if (showRequestStep) {
      verificationStepRows.push(
        <VerificationStepRow
          key={VerificationStepType.REQUEST}
          type={VerificationStepType.REQUEST}
          progress={requestStepState}
          isAppclipFlow={isAppclipFlow}
        />
      );
    }

    verificationStepRows.push(
      <VerificationStepRow
        key={VerificationStepType.PROVE}
        type={VerificationStepType.PROVE}
        progress={proveStepState}
        isAppclipFlow={isAppclipFlow}
      />
    );

    verificationStepRows.push(
      <VerificationStepRow
        key={VerificationStepType.SUBMIT}
        type={VerificationStepType.SUBMIT}
        progress={submitStepState}
      />
    );

    if (bridgingNeeded) {
      verificationStepRows.push(
        <VerificationStepRow
          key={VerificationStepType.SWAP}
          type={VerificationStepType.SWAP}
          progress={swapStepState}
        />
      );
    }

    return verificationStepRows;
  };

  const content = (
    <>
      <VerificationStepsContainer>
        {renderVerificationSteps()}
      </VerificationStepsContainer>

      { bridgingNeeded && quoteData && (
        <SwapDetails
          isLoading={false}
          quoteData={quoteData}
          countdown={10}
        />
      )}

      { shouldShowProofAndSignals && (
        <ProofAndSignalsContainer>
          <LabeledTextArea
            label="Proof Output"
            value={proof}
            disabled={true}
            height={"24vh"} 
          />
        </ProofAndSignalsContainer>
        )
      }

      {(transactionAddress || (bridgeTransactions && bridgeTransactions.length > 0)) && (
        <LinkContainers>
          { transactionAddress && transactionAddress?.length > 0 && (
            <TransactionLinkContainer>
              <Link
                href={`${blockscanUrl}/tx/${transactionAddress}`}
                target="_blank"
                rel="noopener noreferrer">
                  <ThemedText.LabelSmall textAlign="left" paddingBottom={"0.75rem"}>
                    {intentData?.amountTokenToReceive ? 
                      `Received ${Number(intentData.amountTokenToReceive).toFixed(2)} USDC ↗` :
                      `Received USDC ↗`
                    }
                  </ThemedText.LabelSmall>
              </Link>
            </TransactionLinkContainer>
          )}

          { bridgeTransactions && bridgeTransactions?.length > 0 && (
            <BridgeTransactionLinksContainer>
              {bridgeTransactions.map((tx: { txHash: string; chainId: number }, index: number) => (
                <Link
                  key={tx.txHash}
                  href={`https://relay.link/transaction/${tx.txHash}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <ThemedText.LabelSmall textAlign="left">
                    Swap USDC to {tokenInfo[quoteData?.token ?? 'USDC'].ticker} ↗
                  </ThemedText.LabelSmall>
                </Link>
              ))}
            </BridgeTransactionLinksContainer>
          )}
        </LinkContainers>
      )}

      { status === ProofGenerationStatus.TRANSACTION_SIMULATION_FAILED && (
        <TransactionLinkContainer>
          <Link
            href={getTelegramSupportLink()}
            target="_blank"
            rel="noopener noreferrer">
              <ThemedText.LabelSmall textAlign="left" paddingBottom={"0.75rem"}>
                {`Need help? Contact ${depositorTgUsername ? 'seller' : 'support'} ↗`}
              </ThemedText.LabelSmall>
          </Link>
        </TransactionLinkContainer>
      )}

      <Button
        disabled={ctaDisabled}
        onClick={getButtonHandler}
        fullWidth={true}
      >
        <ButtonContentWrapper>
          {ctaLoading && <StyledSpinner size={20} />}
          <span>{ctaButtonTitle}</span>
        </ButtonContentWrapper>
      </Button>

      {showPoweredByReclaim && (
        <PoweredByContainer>
          <PoweredByText>Powered by</PoweredByText>
          <ReclaimLogo src={reclaimSvg} alt="Reclaim Protocol" />
        </PoweredByContainer>
      )}

      { showAccessoryCta && (
        <AccessoryButton
          onClick={handleSkipSwapClick}
          title={"Skip Swap. Go Back"}
          fullWidth={true}
          textAlign="center"
          borderRadius={24}
          />
      )}
    </>
  );

  if (displayType === 'page') {
    return (
      <PageContainer>
        {content}
      </PageContainer>
    );
  }

  return (
    <ModalAndOverlayContainer>
      <Overlay />
      <ModalContainer>
        <TitleRowContainer>
          <button
            onClick={handleOverlayClick}
            disabled={status === ProofGenerationStatus.GENERATING_PROOF}
            style={{
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              opacity: status === ProofGenerationStatus.GENERATING_PROOF ? 0.5 : 1,
            }}
            >

            <StyledArrowLeft/>
          </button>

          <Title>
            <ThemedText.HeadlineSmall style={{ flex: '0', textAlign: 'right' }}>
              {!isMobile ? title : 'Verify'}
            </ThemedText.HeadlineSmall>
          </Title>

          {!isMobile ? (
            <LabeledSwitch
              switchChecked={shouldShowProofAndSignals}
              checkedLabel={"Hide"}
              uncheckedLabel={"Show"}
              helperText={commonStrings.get('PROOF_TOOLTIP')}
              onSwitchChange={(checked: boolean) => setShouldShowProofAndSignals(checked)}
            />
          ) : (
            <div></div> // Leave empty div in so title remains centered
          )}
        </TitleRowContainer>

        {content}
      </ModalContainer>
    </ModalAndOverlayContainer>
  );
};

const ModalAndOverlayContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  align-items: flex-start;
  top: 0;
  left: 0;
  z-index: ${Z_INDEX.overlay};
`;

const ModalContainer = styled.div`
  width: 80vw;
  max-width: 412px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 1.25rem;
  background-color: ${colors.container};
  justify-content: space-between;
  align-items: center;
  z-index: 20;
  gap: 1.3rem;
  
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TitleRowContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 0.3fr 1.1fr 0.85fr;
  align-items: center;
  justify-content: space-between;
`;

const StyledArrowLeft = styled(ArrowLeft)`
  color: #FFF;
`;

const Title = styled.div`
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
`;

const VerificationStepsContainer = styled.div`
  width: 100%;
`;

const ProofAndSignalsContainer = styled.div`
  width: 100%;
  background: #eeeee;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const Link = styled.a`
  white-space: pre;
  display: inline-block;
  color: #1F95E2;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ConfettiContainer = styled.div`
  z-index: 20;
`;

const LinkContainers = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const TransactionLinkContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: row;
`;

const BridgeTransactionLinksContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
`;

const PageContainer = styled.div`
  width: 100%;
  max-width: 412px;
  display: flex;
  flex-direction: column;
  padding: 0rem 0rem 0rem 0rem;
  gap: 1.3rem;
  margin: 0 auto;
`;

const PoweredByContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  gap: 5px;
`;

const PoweredByText = styled.div`
  font-size: 12px;
  text-align: top;
  padding-top: 2px;
  line-height: 1.5;
  color: #FFF;
`;

const ReclaimLogo = styled.img`
  height: 20px;
  vertical-align: middle;
`;

const ButtonContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledSpinner = styled(Spinner)`
  margin-left: 8px;
`;
