import { useState } from 'react';
import { useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import useSmartContracts from '@hooks/contexts/useSmartContracts';

export default function useUpdateDepositConversionRate(
  onSuccess?: (data: any) => void,
  onError?: (error: Error) => void
) {
  /*
   * Context
   */
  const { escrowAddress, escrowAbi } = useSmartContracts();

  /*
   * State
   */
  const [depositId, setDepositId] = useState<string | null>(null);
  const [verifier, setVerifier] = useState<string | null>(null);
  const [fiatCurrency, setFiatCurrency] = useState<string | null>(null);
  const [newConversionRate, setNewConversionRate] = useState<string | null>(null);
  const [shouldConfigureUpdateRateWrite, setShouldConfigureUpdateRateWrite] = useState<boolean>(false);

  /*
   * Contract Writes
   */
  const { config: writeUpdateRateConfig } = usePrepareContractWrite({
    address: escrowAddress,
    abi: escrowAbi,
    functionName: 'updateDepositConversionRate',
    args: [
      depositId,
      verifier,
      fiatCurrency,
      newConversionRate,
    ],
    onError(error: any) {
      console.log('writeUpdateRateConfig failed: ', error.message);
      setShouldConfigureUpdateRateWrite(false);
      onError?.(error);
    },
    enabled: shouldConfigureUpdateRateWrite,
    request: {
      maxPriorityFeePerGas: (existing: bigint) => existing * BigInt(2),
    },
  });

  const {
    data: submitUpdateRateResult,
    status: signUpdateRateTransactionStatus,
    writeAsync: writeUpdateRateAsync,
  } = useContractWrite(writeUpdateRateConfig);

  const {
    status: mineUpdateRateTransactionStatus,
  } = useWaitForTransaction({
    hash: submitUpdateRateResult ? submitUpdateRateResult.hash : undefined,
    onSuccess(data: any) {
      console.log('writeUpdateRateAsync successful: ', data);
      setShouldConfigureUpdateRateWrite(false);
      onSuccess?.(data);
    },
  });

  return {
    writeUpdateRateAsync,
    depositId,
    setDepositId,
    verifier,
    setVerifier,
    fiatCurrency,
    setFiatCurrency,
    newConversionRate,
    setNewConversionRate,
    shouldConfigureUpdateRateWrite,
    setShouldConfigureUpdateRateWrite,
    signUpdateRateTransactionStatus,
    mineUpdateRateTransactionStatus,
    transactionHash: submitUpdateRateResult?.hash,
  };
}
